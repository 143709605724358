<template>
  <div>
    <Carousel3d
      :controls-visible="true"
      display="3"
      @after-slide-change="onAfterSlideChange"
      :controlsNextHtml="`<img  src='${getIcon(
        `avtarNavIcon.svg`
      )}' class='avtarNav'/>`"
      :controlsPrevHtml="`<img src='${getIcon(
        `avtarNavIcon.svg`
      )}' class='rotate180 avtarNav'/>`"
    >
      <slide v-for="(avatar, i) in $store.state.avatars" :index="i" :key="i">
        <img
          :src="avatar.avatar_url"
          style="
            display: block !important;
            margin-left: auto !important;
            margin-right: auto !important;
          "
          class="avtarBgShadow"
          alt
        />
      </slide>
    </Carousel3d>
  </div>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";
import {
  dataLayerAnchorTag,
  dataLayerButtonTag,
  dataLayerSelectTag,
  dataLayerInputTag,
} from "data-layer-test-oo-gtm";

export default {
  directives: {
    dataanchor: dataLayerAnchorTag,
    databutton: dataLayerButtonTag,
    "data-select": dataLayerSelectTag,
    "data-input": dataLayerInputTag,
  },
  components: { Carousel3d, Slide },
  data() {
    return {
      avatars: [
        {
          img:
            "https://d2u1pwgqhchnta.cloudfront.net/alienzoo01/01/fe-as-01.png",
          name: "aa",
        },
        {
          img:
            "https://d2u1pwgqhchnta.cloudfront.net/alienzoo01/01/fe-as-01.png",
          name: "bb",
        },
        {
          img:
            "https://d2u1pwgqhchnta.cloudfront.net/alienzoo01/01/fe-as-01.png",
          name: "cc",
        },
        {
          img:
            "https://d2u1pwgqhchnta.cloudfront.net/alienzoo01/01/fe-as-01.png",
          name: "aa",
        },
        {
          img:
            "https://d2u1pwgqhchnta.cloudfront.net/alienzoo01/01/fe-as-01.png",
          name: "bb",
        },
        {
          img:
            "https://d2u1pwgqhchnta.cloudfront.net/alienzoo01/01/fe-as-01.png",
          name: "aa",
        },
        {
          img:
            "https://d2u1pwgqhchnta.cloudfront.net/alienzoo01/01/fe-as-01.png",
          name: "bb",
        },
      ],
    };
  },
  methods: {
    onAfterSlideChange(index) {
      this.$emit("currentAvatarSelected", this.$store.state.avatars[index]);
    },
    getImage(img) {
      return require("@/assets/avatars/" + img);
    },
    getIcon(img) {
      return require("@/assets/icons/" + img);
    },
  },
};
</script>

<style lang="scss">
.carousel-3d-slide {
  border: none !important;
  background: none !important;
}
.carousel-3d-slide.left-1,
.carousel-3d-slide.right-1 {
  opacity: 0.5 !important;
}
.carousel-3d-slide img {
  width: 45% !important;
}
.carousel-3d-container {
  height: 400px !important;
  margin: 0px !important;
}
.carousel-3d-controls {
  .next {
    right: 28% !important;
    @media screen and (max-width: 767px) {
      right: 15% !important;
    }
  }
  .prev {
    left: 30% !important;
    @media screen and (max-width:767px) {
      left: 15% !important;
    }
  }
}
.carousel-3d-slide.current {
  height: 370px !important;

  img {
    // Old image shadow
    position: relative;
    -webkit-filter: drop-shadow(0px 0px 2px #ffc627);
    filter: drop-shadow(0px 0px 2px #ffc627);

    //for background
  }
  &::after {
    content: "";
    background-image: url("../assets/avatars/activeAvatar.svg");
    position: absolute;
    height: 150px;
    width: 100%;
    top: 56%;
    left: 20.4%;
  }
  &::before {
    content: "";
    background-image: url("../assets/avatars/activeAvatarBase.svg");
    position: absolute;
    height: 100%;
    width: 100%;
    top: 81%;
    left: 13%;
  }
}
.carousel-3d-slide {
  height: 370px !important;
}
.rotate180 {
  transform: rotateY(180deg);
}
.avtarNav {
  -webkit-filter: drop-shadow(1px 1px 2px #ffc627);
  filter: drop-shadow(1px 1px 2px #ffc627);
}
</style>
