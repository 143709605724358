<template>
  <div class="create-your-account text-left">
    <div class="component mx-auto pt-5">
      <div class="row">
        <div class="col-12 col-lg-5 left-col"></div>
        <div class="col-12 col-lg-7 right-col">
          <form action="" style="width: 300px; margin: auto">
            <h2 class="pb-2 font-28 font-md-32 font-weight-900">
              Create your account
            </h2>
            <p class="pb-3 font-17">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod
            </p>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="font-14" for="username">First name</label>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="font-14" for="password">Last name</label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    class="form-control"
                  />
                </div>
              </div>
            </div>

            <div class="form-group">
              <label class="font-14" for="Email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                class="form-control"
              />
            </div>

            <div class="form-group">
              <label class="font-14" for="display-name">Display name</label>
              <input
                type="text"
                name="displayName"
                id="display-name"
                class="form-control"
              />
            </div>
            <div>
              <CustomButton
                class="mx-auto d-block cus-btn"
                buttonText="Finish account"
                @click.native="onClickFinish()"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustomButton from "./Button.vue";
export default {
  components: {
    CustomButton,
  },
  methods: {
    onClickFinish() {
      this.$store.state.component = "landing";
      this.$store.state.view = "alien";
    },
  },
};
</script>
<style lang="scss" scoped>
.create-your-account {
  height: 100vh;
  background-image: url("../assets/images/createYourAccount.png");
  background-repeat: no-repeat;
  background-size: cover;

  h2 {
    font-size: 32px;
    padding-top: 40px;
  }
  .left-col {
    background-color: #5d5d5d;
  }

  input {
    background-color: #151515;
  }

  .right-col {
    background-color: #151515;
  }
  .cus-btn {
    margin-bottom: 80px;
    margin-top: 40px;
  }

  .component {
    max-width: 687px;
    max-height: 557px;
  }
  p {
    width: 296px;
  }
}
</style>
