<template>
  <div
    class="modal fade p-0"
    id="tourModal"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-body p-0">
          <carousel :items="1" :nav="false" class="d-flex">
            <template slot="prev">
              <img
                class="prev rotate-180"
                :src="getIcon('chevron-circle-left-solid.svg')"
                alt="left chevron"
              />
            </template>
            <div
              v-for="(card, c) in data"
              class="row img-body text-left"
              :key="c"
            >
              <img :src="getImage(card.img)" :alt="'tour step' + c" />
              <p class="pt-4" v-html="card.para"></p>
            </div>
            <template slot="next">
              <img
                class="next"
                :src="getIcon('chevron-circle-right-solid.svg')"
                alt="chevron circle"
              />
            </template>
          </carousel>
          <span class="ml-auto">
            <a
              v-dataanchor
              tagEvent="link"
              tagAction="click"
              tagType="internal link"
              tagSection="how to use the portal?"
              tagText="skip tour"
              href="#"
              data-dismiss="modal"
              class="skip-tour"
            >
              Skip tour
            </a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import {
  dataLayerAnchorTag,
  dataLayerButtonTag,
  dataLayerSelectTag,
  dataLayerInputTag,
} from "data-layer-test-oo-gtm";
export default {
  directives: {
    dataanchor: dataLayerAnchorTag,
    databutton: dataLayerButtonTag,
    "data-select": dataLayerSelectTag,
    "data-input": dataLayerInputTag,
  },
  components: { carousel },
  data() {
    return {
      data: [
        {
          img: "HowToUseStep1.png",
          para: "<p  class='mb-0'>Click on the <strong>Menu</strong> to navigate to your <strong>Dashboard</strong>, On the Dreamscape Learn AR App you can use the <strong>Menu</strong> to access your <strong>Journal, Field Guide,</strong> and <strong>Scene viewer</strong>.</p>",
        },
        {
          img: "HowToUseStep2.png",
          para: "<p  class='mb-0'>Use the dial to track your program progress. When your progress bar shows <strong>100%</strong> and status shows <strong>Completed</strong>, you are done with your program. You can always see the next act you need to complete in the <strong>Next Module Section</strong>, next to your progress bar.</p>",
        },
        {
          img: "HowToUseStep3.png",
          para: "<p  class='mb-0'>If you meet the prerequisites for a module, you will see a <strong>Start/Reserve button</strong> that will allow you to reserve seating in a [Dreamscape Learn Pod] or a open the same experience in the [Dreamscape Learn at home experience]</p>",
        },
        {
          img: "HowToUseStep4.png",
          para: "<p  class='mb-0'>If you want to quickly schedule time in the [Dreamscape Learn Pods] just enter the date you would like to visit into the <strong>Schedule a Visit</strong> section of your <strong>Dashboard</strong>.</p>",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    getImage(img) {
      return require("@/assets/howToUse/" + img);
    },
    getIcon(img) {
      return require("@/assets/icons/" + img);
    },
  },
};
</script>

<style scoped>
.modal-body {
  background: black;
}
</style>
<style>
.owl-dots {
  padding: 25px 48px !important;
}
.skip-tour {
  position: absolute;
  bottom: 25px;
  right: 48px;
  z-index: 3;
}
.owl-theme .owl-dots {
  text-align: start !important;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #ffc627 !important;
}
.prev {
  position: absolute;
  left: -12%;
  top: 50%;
}
.next {
  position: absolute;
  right: -12%;
  top: 50%;
}
.img-body {
  padding: 60px 70px 0px 70px;
}
@media (max-width: 767px) {
  .img-body {
    padding: 20px 33px 0 33px;
  }
  .prev {
    top: 88%;
    z-index: 2;
    left: 3%;
    border-radius: 20px;
  }
  .next {
    top: 88%;
    bottom: 5%;
    z-index: 2;
    right: 45%;
    border-radius: 20px;
  }
}
</style>