var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[(this.$store.state.role == '' && !_vm.$store.state.profile.first_name)?_c('login'):_vm._e(),(this.$store.state.role == 'instructor')?_c('InviteUserModals'):_vm._e(),(
      this.$store.state.role == 'guest' &&
      this.$store.state.component == 'create-your-account'
    )?_c('CreateYourAccount'):_vm._e(),(_vm.$store.state.component == 'landing' && _vm.$store.state.view == 'alien')?_c('Landing'):_vm._e(),(_vm.$store.state.component == 'waiver')?_c('Waiver'):_vm._e(),(_vm.$store.state.component == 'consentForm')?_c('ConsentForm'):_vm._e(),(
      _vm.$store.state.component == 'landing' && _vm.$store.state.view == 'avatar'
    )?_c('ChooseYourAvatarLanding'):_vm._e(),(_vm.$store.state.component == 'landing' && _vm.$store.state.view == 'pods')?_c('ThePodsLanding'):_vm._e(),(
      _vm.$store.state.component == 'landing' && _vm.$store.state.view == 'online'
    )?_c('OnlineExperienceLanding'):_vm._e(),(this.$store.state.role == 'admin')?_c('Calendar'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }