<template>
  <section id="navlist" style="align-self: center" class="d-flex">
    <template v-if="type == 'menu'">
      <li
        v-for="(data, i) in navList"
        :key="i"
        @click="hide(data), onClickMenuOption(data)"
      >
        <router-link
          v-dataanchor
          tagEvent="link"
          tagAction="click"
          tagType="internal link"
          tagSection="sidebar"
          tabText="dashboard"
          v-if="data.type === 'route'"
          :to="'/' + data.link"
          class="scroll-link d-flex flex-column align-items-center text-center"
        >
          <img
            class="mb-8 icon-bg"
            :src="getIcon(data.icon, data.name)"
            :alt="data.link"
          />
          <span :class="itemStyling(data.name)">{{ data.name }}</span>
        </router-link>
        <a
          v-else-if="data.type === 'modal'"
          class="scroll-link d-flex flex-column align-items-center text-center"
          href="#modal"
          data-toggle="modal"
          :data-target="'#' + data.link"
          @click="triggerModal(data)"
        >
          <img class="mb-8" :src="getIcon(data.icon)" :alt="data.link" />
          <span class="text-white font-14">{{ data.name }}</span>
        </a>
        <a
          v-else
          class="scroll-link d-flex flex-column align-items-center text-center"
          :href="data.link"
        >
          <img class="mb-8" :src="getIcon(data.icon)" :alt="data.link" />
          <span
            :class="
              data.name == this.view
                ? ' font-14 font-weight-bold dsl-yellow-btn'
                : ' font-14 text-white'
            "
            >{{ data.name }}</span
          >
        </a>
      </li>
    </template>
    <div v-else-if="type == 'header'">
      <div class="d-md-flex kallisto-bold align-items-center d-none">
        <span
          v-for="(data, i) in navList"
          :key="i"
          @click="hide(data), onClickMenuOption(data)"
        >
          <router-link
            v-dataanchor
            tagEvent="link"
            tagAction="click"
            tagType="internal link"
            tagSection="sidebar"
            tabText="dashboard"
            v-if="data.type === 'route'"
            :to="'/' + data.link"
            class="scroll-link font-14 kallisto-bold px-1 d-flex flex-column align-items-center text-center"
          >
            <span :class="itemStyling(data.name)">{{ data.name }}</span>
          </router-link>
          <a
            v-else-if="data.type === 'modal'"
            class="scroll-link font-14 kallisto-bold px-1 d-flex flex-column align-items-center text-center router-link-active"
            href="#modal"
            data-toggle="modal"
            :data-target="'#' + data.link"
            @click="triggerModal(data)"
          >
            <span class="text-white">{{ data.name }}</span>
          </a>
          <a
            v-else
            class="scroll-link font-14 kallisto-bold px-1 d-flex flex-column align-items-center text-center"
            :href="data.link"
          >
            <span
              :class="
                data.name == this.view
                  ? 'font-weight-bold dsl-yellow-btn'
                  : 'text-white'
              "
              >{{ data.name }}</span
            >
          </a>
        </span>
      </div>
      <div class="d-block d-md-none px-3">
        <span class="" data-toggle="modal" data-target="#mobileMenuModal">
          <img :src="getIcon('bars-horizontal.svg')" alt="bars-horizontal" />
        </span>

        <div
          class="modal fade p-0"
          id="mobileMenuModal"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-slideout modal-md"
            role="document"
          >
            <div class="modal-content">
              <div
                class="b-modal-header h-100 d-flex flex-column justify-content-between"
              >
                <div
                  class="d-flex justify-content-between p-3"
                  style="border-bottom: 1px solid #4c3c11"
                >
                  <img
                    v-if="this.$store.state.role == 'admin'"
                    src="../assets/AdminLogo.png"
                    alt="dsl logo"
                    class="pr-3"
                    width="163"
                  /><img
                    v-else-if="this.$store.state.role == 'instructor'"
                    src="../assets/InstructoLogo.png"
                    alt="dsl logo"
                    class="pr-3"
                    width="163"
                  />
                  <img
                    v-else-if="this.$store.state.role == 'pod operator'"
                    src="../assets/PodLogo.png"
                    alt="dsl logo"
                    class="pr-3"
                    width="163"
                  />
                  <div
                    v-else
                    class="d-flex font-14 font-weight-bolder kallisto-bold text-left"
                  >
                    Dreamscape Learn Portal
                  </div>
                  <img
                    :src="getIcon('x-button.svg')"
                    data-dismiss="modal"
                    alt="cross icon"
                  />
                </div>
                <div class="p-3 kallisto-bold">
                  <li
                    class="d-flex py-12"
                    :class="i == 0 ? '' : 'bt-g'"
                    v-for="(data, i) in navList"
                    :key="i"
                    @click="hide(data), onClickMenuOption(data)"
                  >
                    <router-link
                      v-dataanchor
                      tagEvent="link"
                      tagAction="click"
                      tagType="internal link"
                      tagSection="sidebar"
                      tabText="dashboard"
                      v-if="data.type === 'route'"
                      :to="'/' + data.link"
                      class="scroll-link d-flex align-items-center text-center"
                    >
                      <img
                        class="icon-bg"
                        width="20"
                        height="20"
                        :src="getIcon(data.icon, data.name)"
                        :alt="data.link"
                      />
                      <span
                        :class="itemStyling(data.name)"
                        style="
                          background: transparent;
                          font-size: 16px !important;
                        "
                        >{{ data.name }}</span
                      >
                    </router-link>
                    <a
                      v-else-if="
                        data.type === 'modal' && data.link != 'pod-check-in'
                      "
                      class="scroll-link d-flex align-items-center text-center"
                      href="#modal"
                      data-toggle="modal"
                      :data-target="'#' + data.link"
                      @click="triggerModal(data)"
                    >
                      <img
                        class="mb-8"
                        width="20"
                        height="20"
                        :src="getIcon(data.icon)"
                        :alt="data.link"
                      />
                      <span class="text-white font-16">{{ data.name }}</span>
                    </a>
                    <a
                      v-else-if="data.link != 'pod-check-in'"
                      class="scroll-link d-flex align-items-center text-center"
                      :href="data.link"
                    >
                      <img
                        class="mb-8"
                        width="20"
                        height="20"
                        :src="getIcon(data.icon)"
                        :alt="data.link"
                      />
                      <span
                        :class="
                          data.name == this.view
                            ? ' font-16 font-weight-bold dsl-yellow-btn'
                            : ' font-16 text-white'
                        "
                        style="background: transparent"
                        >{{ data.name }}</span
                      >
                    </a>
                  </li>
                </div>
                <div
                  class="d-flex justify-content-between px-3 py-2 m-3"
                  style="background-color: #252525; border-radius: 8px"
                >
                  <div class="d-flex align-items-center">
                    <div>
                      <span
                        v-if="$store.state.role == 'admin'"
                        class="admin-icn px-2 py-1"
                        style="background: #735c1d"
                        >A</span
                      >
                      <span
                        v-else-if="$store.state.role == 'instructor'"
                        class="instructor-icn px-2 py-1"
                        style="background: #a8252f"
                        >I</span
                      >
                      <span
                        v-else-if="$store.state.role == 'pod operator'"
                        class="pod-icn px-2 py-2"
                        style="background: #0e4f73"
                        >PO</span
                      >
                    </div>
                    <div class="px-2 text-left">
                      <p class="mb-0 font-weight-bold">
                        {{ $store.state.profile.first_name }}
                      </p>
                      <p class="mb-0">{{ $store.state.role }}</p>
                    </div>
                  </div>
                  <button
                    @click="logoutPopup()"
                    class="btn p-0 font-weight-bold"
                  >
                    <img
                      src="../assets/icons/logout-svg.svg"
                      alt="logout icon"
                      height="20"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import $ from "jquery";
import { Auth } from "aws-amplify";
import {
  dataLayerAnchorTag,
  dataLayerButtonTag,
  dataLayerSelectTag,
  dataLayerInputTag,
} from "data-layer-test-oo-gtm";

export default {
  props: {
    navList: Array,
    type: String,
  },
  data() {
    return {
      view: this.navList[0].name,
    };
  },
  directives: {
    dataanchor: dataLayerAnchorTag,
    databutton: dataLayerButtonTag,
    "data-select": dataLayerSelectTag,
    "data-input": dataLayerInputTag,
  },
  methods: {
    logoutPopup() {
      $("#mobileMenuModal").modal("hide");
      $("#logout-confirm").modal();
    },
    async logout() {
      $("#logout-confirm").modal("hide");
      try {
        await Auth.signOut();
      } catch (error) {
        console.log("error signing out: ", error);
      }
      this.$router.push({ path: "/portal" });
      this.$store.state.role = "";
    },
    getImage(img) {
      return require("../assets/images/" + img + ".svg");
    },
    triggerModal(data) {
      if (data.link == "pod-check-in") {
        this.$store.commit("POD_CHECKIN", true);
      }
    },
    getIcon(img, view) {
      if (this.$store.state.viewFromMenu == view) {
        return require("@/assets/icons/" +
          img.substr(0, img.indexOf(".svg")) +
          "-selected.svg");
      } else {
        return require("@/assets/icons/" + img);
      }
    },
    hide(data) {
      if (data.type === "url") {
        window.location = data.link;
      }
      this.$emit("hide");
    },
    onClickMenuOption(navListView) {
      if (navListView.type != "modal") {
        this.$store.state.viewFromMenu = navListView.name;
        this.view = navListView.name;
      }
      if (navListView.name == "Scheduler") {
        this.$store.state.calendarSelectedPod = 1;
      } else if (navListView.name == "Pod Schedule") {
        this.$store.state.appointmentsRefresh = false;
        // call appointments api
        let myData = {
          selected_site_id: this.$store.state.selected_site_id,
          podSessionDate: this.$store.state.podSessionDate,
        };
        this.$store.dispatch("setSchedulerSessions", myData).then(() => {
          this.$store.state.appointmentsRefresh = true;
        });
      }
      $("#mobileMenuModal").modal("hide");
    },
    itemStyling(view) {
      if (this.$store.state.viewFromMenu == view) {
        return "font-14 font-weight-bold dsl-yellow-btn";
      } else {
        return "font-14 text-white";
      }
    },
  },
};
</script>

<style lang="scss">
.text-white {
  padding: 6px 12px;
  color: #d0d0d0 !important;
}
#navlist {
  // modal details below
  .modal-content {
    border-right: 1px solid #ffc627;
  }
  .modal-dialog-slideout {
    min-height: 100%;
    margin: 0 0 0 auto;
  }
  .modal.fade .modal-dialog.modal-dialog-slideout {
    -webkit-transform: translate(0%, 0%, 0%, 100%) scale(1);
    transform: translate(0%, 0%, 0%, 100%) scale(1);
  }
  .modal.fade.show .modal-dialog.modal-dialog-slideout {
    -webkit-transform: translate(0%, 100%, 0%, 0%) scale(1);
    transform: translate(0%, 100%, 0%, 0%) scale(1);
  }
  .modal.fade.show .modal-dialog.modal-dialog-slideout {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    display: flex;
    align-items: stretch;
    -webkit-box-align: stretch;
    height: 100%;
  }
  .modal-md {
    width: 75%;
  }
}
.modal-backdrop {
  z-index: 990 !important;
}
.btn-signout {
  background-color: #ffc627 !important;
  border-radius: 30px !important;
}
.bt-g {
  border-top: 1px solid #323030;
}
.pod-icn,
.instructor-icn,
.admin-icn {
  border-radius: 50%;
}
.py-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
</style>
