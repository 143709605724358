<template>
  <div
    class="modal fade"
    id="invite-participants"
    data-backdrop="exampleModalLabel"
    data-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="component container mx-auto pt-5">
            <div class="row">
              <div class="col-12 col-lg-6 left-col"></div>
              <div class="col-12 col-lg-6 right-col">
                <form action="" style="width: 300px; margin: auto">
                  <h3 class="pb-29 m-0 font-24">Invite user</h3>
                  <h2 class="pb-24 m-0 font-32 font-weight-bold">
                    Enter User Information
                  </h2>
                  <div class="row">
                    <div class="mx-3 mb-2">
                      <div class="form-group m-0 pb-16">
                        <label class="font-14">Email</label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          class="form-control pb-16"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group m-0 pb-16">
                    <label class="font-14">Role</label>
                    <input
                      type="text"
                      name="role"
                      id="role"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group m-0 pb-16">
                    <label class="font-14">Notes</label>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                    ></textarea>
                  </div>
                  <div>
                    <CustomButton
                      class="mx-auto d-block cus-btn mt-40 mb-48"
                      buttonText="Next"
                      @click.native="onClickFinish()"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustomButton from "../Button.vue";
export default {
  components: {
    CustomButton,
  },
  data() {
    return {
      step: 1,
      firstName: "Santa",
      lastName: "Claus",
      role: "student",
      notes: "Notes go here",
      email: "santa.claus@gmail.com",
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.user-checkin {
  height: 100vh;
  background-image: url("../../assets/images/createYourAccount.png");
  background-repeat: no-repeat;
  background-size: cover;

  h3 {
    padding-top: 40px;
  }
  .left-col {
    background-image: url("../../assets/images/westfield.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  input {
    background-color: #151515;
  }
  textarea {
    background-color: #151515;
    resize: none;
  }

  .right-col {
    background-color: #151515;
  }

  .cus-btn {
    float: left;
  }

  .component {
    max-width: 732px;
  }
}
</style>
