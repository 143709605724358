<template>
  <div
    class="modal fade"
    id="ErrorSigningDoc"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header border-bottom-0 p-0">
          <h5
            class="
              modal-title
              font32
              text-capitalize
              pl-5
              pt-5
              font-weight-bold
            "
            id
          >
            Waiver confirmation
          </h5>
        </div>
        <hr />
        <div class="modal-body p-5">
          <p class="font27 pb-4">
            Participants must sign the waiver to proceed. Click here to go back
            to the previous screen.
          </p>
          <hr class="mt-5" />
          <button
            @click="CancelWaiver()"
            class="border-layout btn-cancel p-3 mx-4"
          >
            Cancel
          </button>
          <CustomButton
            @click.native="onButtonClick(true)"
            :buttonText="'Sign waiver'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "../components/Button.vue";
import { Auth, API } from "aws-amplify";
import $ from "jquery";
export default {
  methods: {
    async onButtonClick(combined) {
      this.$store.state.loader = true;
      let urlData = {
        body: {
          return_url: window.location.origin + window.location.pathname,
          combined: combined,
        },
      };
      await API.post("proxy", `/${this.$store.state.selected_site_id}/waiver/prepare`, urlData).then(async (res) => {
        let envelopeId = res.envelopeId;
        this.$store.commit("setEnvelopeId", res.envelopeId);
        window.location.href = res.redirectUrl;
      });
    },
    CancelWaiver() {
      this.$router.push({ path: "/portal" });
      $("#ErrorSigningDoc").modal("hide");
    },
  },
  components: {
    CustomButton,
  },
};
</script>

<style scoped>
hr {
  border-top: 1px solid #FFC627;
}
.btn-cancel {
  border-radius: 30px;
  min-width: 120px;
}
</style>