<template>
  <div>
    <div
      class="modal"
      id="choose-your-experience"
      data-backdrop="exampleModalLabel"
      data-keyboard="false"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <section id="infoa" class="py-3">
              <a
                v-dataanchor
                tagEvent="modal"
                tagAction="close"
                tagType="click"
                tagSection="choose your experience"
                tagText="close cross"
                type="button"
                class="close"
                data-dismiss="modal"
                href="javascript:void(0)"
                @click="remoteExpErr = null"
              >
                <img src="../assets/icons/times-solid.svg" alt="times solid" />
              </a>
              <div class="container text-white">
                <div class="row justify-content-center">
                  <h2 class="text-white title font-24 font-md-38">
                    Choose your experience
                  </h2>
                  <p class="text-white description">
                    Dreamscape Learn can be completed as a multi-sensory
                    experience on the ASU Tempe campus in the Dreamscape Studio
                    pods. Alternatively, the experience can be explored anytime,
                    anywhere using your computer or PC-powered VR Headset.
                  </p>
                </div>
                <div :class="enableTwoD() ? 'row' : ''">
                  <a
                    v-dataanchor
                    tagEvent="modal"
                    tagAction="open"
                    tagType="click"
                    tagSection="choose your experience"
                    tagText="pod experience"
                    href="javascript:void(0)"
                    class="col-12 col-lg-6 align-self-center"
                  >
                    <img
                      class="pod-image"
                      src="../assets/images/pod-experience.png"
                      alt="pod image"
                      data-toggle="modal"
                      data-target="#podSession"
                      data-dismiss="modal"
                      style="cursor: pointer"
                    />
                    <h3
                      data-toggle="modal"
                      data-target="#podSession"
                      data-dismiss="modal"
                      class="text-center exp font-18 font-md-27"
                    >
                      Pod Experience
                    </h3>
                  </a>
                  <a
                    v-if="
                      this.$store.state.scheduler_pods &&
                      this.$store.state.scheduler_pods[0] &&
                      this.$store.state.scheduler_pods[0]['2d'] &&
                      enableTwoD()
                    "
                    v-dataanchor
                    tagEvent="link"
                    tagAction="click"
                    tagType="internal link"
                    tagSection="choose your experience"
                    tagText="anytime, anywhere"
                    href="javascript:void(0)"
                    class="col-12 col-lg-6 justify-content-center"
                  >
                    <img
                      class="pod-image"
                      src="../assets/images/anywhere-anytime.png"
                      alt="pod image"
                      style="cursor: pointer"
                      @click="remoteInit()"
                    />
                    <h3
                      @click="remoteInit()"
                      class="text-center exp font-18 font-md-27"
                    >
                      Anytime, anywhere
                    </h3>
                  </a>
                </div>
                <p
                  v-if="remoteExpErr && enableTwoD()"
                  class="text-danger d-inline-block mt-3 mb-0"
                >
                  {{ remoteExpErr }}
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API } from "aws-amplify";
import {
  dataLayerAnchorTag,
  dataLayerButtonTag,
  dataLayerSelectTag,
  dataLayerInputTag,
} from "data-layer-test-oo-gtm";

export default {
  directives: {
    dataanchor: dataLayerAnchorTag,
    databutton: dataLayerButtonTag,
    "data-select": dataLayerSelectTag,
    "data-input": dataLayerInputTag,
  },
  components: {},
  data() {
    return {
      remoteExpErr: null,
    };
  },
  methods: {
    enableTwoD() {
      if (
        this.$store.state.scheduler_pods &&
        this.$store.state.scheduler_pods[0] &&
        this.$store.state.scheduler_pods[0]["2d"]
      ) {
        let twoDtitle = this.$store.state.scheduler_pods[0]["2d"].find(
          (status) => status.title_id === this.$store.state.selected_title_id
        );
        if (twoDtitle != undefined) {
          return true;
        } else return false;
      }
      return false;
    },
    async remoteInit() {
      var myInit = {
        body: {
          title_id: this.$store.state.selected_title_id,
          site_id: this.$store.state.selected_site_id,
        },
      };
      await API.post("asulink", `/tickets/experience/remote/init`, myInit)
        .then((response) => {
          window.open(response.remote_session_uri);
        })
        .catch((e) => {
          console.log(e);
          this.remoteExpErr = e.response.data.message;
        });
    },
  },
};
</script>

<style scoped lang="scss">
p.text-danger:first-letter {
  text-transform: uppercase;
}
button {
  border: none !important;
  position: absolute;
  left: 745px;
  top: -20px;
}
.modal-dialog {
  padding-top: 60px;
  max-width: 732px;
  position: relative;
}
.modal-header {
  border-bottom: none;
}

.modal-content {
  background-color: #151515;
}
#infoa {
  max-width: 732px;
  background: #151515 0% 0% no-repeat padding-box;
}

.description {
  max-width: 443px;
  font-size: 14px;
}

.cyp-para {
  padding: 0px 200px;
  font-size: 14px;
}

.placeholder {
  width: 236px;
  height: 283px;
}

.title {
  font-size: 38px;
}

.exp {
  font-size: 33px;
}

.pod-sched-header {
  font-size: 29px;
}

.pod-image {
  max-height: 270px;
}

@media only screen and (max-width: 768px) {
  button {
    position: static;
  }

  .pod-image {
    max-height: 210px;
    max-width: 176px;
  }
}
</style>
<style lang="scss">
body.modal-open {
  .blurBackdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(13px);
    background-color: #00000000;
    z-index: 999;
  }
}
</style>
