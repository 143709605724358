<template>
  <button type="button" class="">
    <span class="font-weight-bold">{{ buttonText }}</span>
  </button>
</template>
<script>
export default {
  props: ["buttonText"],
};
</script>

<style lang="scss" scoped>
button {
  border: 1px solid #FFC627 !important;
  color: #FFC627 !important;
  font-size: 16px !important;
  border-radius: 30px !important;
  background-color: #151515;
  cursor: pointer !important;
  width: fit-content;
  padding: 5% 15%;

  span {
    padding: 0;
    letter-spacing: -0.08px;
    display: inline-block;
  }
}
</style>
