var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 p-0"},[(_vm.participants.length === 0)?_c('div',{staticClass:"d-flex d-md-none px-3 py-2 font-weight-bold font-14",staticStyle:{"border-bottom":"1px solid #4e4222 !important","border-radius":"0% !important"}},[_vm._v(" No participants ")]):_vm._e(),(_vm.participants.length === 0)?_c('div',{staticClass:"d-none d-md-flex px-4 ml-3 py-2 font-weight-bold font-14"},[_vm._v(" No participants ")]):_vm._e(),_vm._l((_vm.participants),function(participant,p){return _c('div',{key:p},[(participant.ticket_id)?_c('tr',{staticClass:"d-none d-md-flex pl-3"},[_c('td',{staticClass:"col-3 py-2 px-4"},[_vm._v(" "+_vm._s(participant.first_name)+" ")]),_c('td',{staticClass:"col-lg-3 col-5 py-2 px-0"},[_vm._v(" "+_vm._s(participant.transaction_email)+" ")]),_c('td',{staticClass:"col-2 py-2 px-0"},[_vm._v(_vm._s(participant.role))]),_c('td',{staticClass:"col-2 py-2 px-0"},[(participant.checked_in)?_c('span',[_c('img',{staticClass:"mr-1",attrs:{"src":_vm.getIcon('green-dot.svg'),"alt":"green-icon"}}),_vm._v(" Checked-in ")]):(
            participant.metadata && participant.metadata.scanned.length > 0
          )?_c('span',[_c('img',{staticClass:"mr-1",attrs:{"src":_vm.getIcon('green-dot.svg'),"alt":"green-icon"}}),_vm._v(" Checked-in ")]):(_vm.missedStatus)?_c('span',[_c('img',{staticClass:"mr-1",attrs:{"src":_vm.getIcon('red-dot.svg'),"alt":"red-icon"}}),_vm._v(" Missed ")]):_c('span',[_c('img',{staticClass:"mr-1",attrs:{"src":_vm.getIcon('yellow-dot.svg'),"alt":"yellow icon"}}),_vm._v(" Reserved ")])]),(
          _vm.$store.state.role == 'admin' || _vm.$store.state.role == 'pod operator'
        )?_c('div',[_c('cancelRes',{attrs:{"participant":participant,"session":_vm.session},on:{"CancelReservation":_vm.CancelReservation}})],1):_vm._e()]):_vm._e(),(participant.ticket_id)?_c('div',{staticClass:"d-flex d-md-none py-2 pl-3 pr-1",staticStyle:{"border-bottom":"1px solid #4e4222 !important","border-radius":"0% !important"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center w-100"},[_c('div',{staticClass:"d-flex"},[(participant.checked_in)?_c('span',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('img',{staticClass:"mr-2",attrs:{"src":_vm.getIcon('green-dot.svg'),"alt":"green-icon"}})]):_c('span',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('img',{staticClass:"mr-2",attrs:{"src":_vm.getIcon('yellow-dot.svg'),"alt":"yellow icon"}})]),_c('div',[_c('p',{staticClass:"m-0 font-weight-bold font-16"},[_vm._v(" "+_vm._s(participant.first_name)+" "+_vm._s(participant.last_name)+" ")]),_c('p',{staticClass:"m-0 font-weight-normal font-12"},[_vm._v(" "+_vm._s(participant.role ? participant.role + " |" : "")+" "+_vm._s(participant.checked_in ? "Checked in" : "Reserved")+" ")])])]),(
            _vm.$store.state.role == 'admin' ||
            _vm.$store.state.role == 'pod operator'
          )?_c('div',{staticClass:"mr-3"},[_c('cancelRes',{attrs:{"participant":participant,"session":_vm.session},on:{"CancelReservation":_vm.CancelReservation}})],1):_vm._e()])]):_vm._e()])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }