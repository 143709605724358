<template>
  <div>
    <!-- <full-calendar
      :events="events"
      @dayClick="dayClick($event)"
    ></full-calendar> -->
  </div>
</template>

<script>
import FullCalendar from "vue-fullcalendar";

import $ from "jquery";
export default {
  components: {
    // FullCalendar,
  },
  data() {
    return {
      events: [
        {
          title: "event1",
          start: "2010-01-01",
        },
        {
          title: "event2",
          start: "2010-01-05",
          end: "2010-01-07",
        },
        {
          title: "event3",
          start: "2010-01-09T12:30:00",
          allDay: false,
        },
      ],
    };
  },
  methods: {
    dayClick(event) {
      this.$store.state.podSessionDate = this.convertToDate(event);
      this.$store.state.showCalModal = false;
      this.$store.state.loader = true;
      let myData = {
        selected_site_id: this.$store.state.selected_site_id,
        podSessionDate: this.$store.state.podSessionDate,
      };
      this.$store
        .dispatch("setSchedulerSessions", myData)
        .then(() => {
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.$store.state.loader = false;
        });
      setTimeout(() => {
        this.$store.state.showCalModal = true;
      }, 100);
      $("#shortCalendarappointment").modal("hide");
    },
    convertToDate(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    },
  },
};
</script>

<style lang="scss">
.comp-full-calendar {
  padding: 0;
  background: black;
  color: white;
}
.event-box,
.header-left,
header-right {
  display: none;
}
.full-calendar-body .dates .dates-events .events-week .events-day {
  min-height: 12px !important;
}
.events-day.today {
  background: rgba(128, 128, 0, 0.315);
}
.full-calendar-body .dates .dates-events .events-week .events-day .day-number {
  opacity: 1;
}
.full-calendar-body .dates .week-row .day-cell {
  min-height: 1px;
}
.comp-full-calendar ul,
.comp-full-calendar p {
  font-size: 5px;
}
.full-calendar-body .dates .dates-events {
  position: relative;
}
.full-calendar-header .header-center .title {
  margin: 0;
  font-size: 10px !important;
}
.dates-bg,
.weeks {
  display: none !important;
}
</style>