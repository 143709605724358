<template>
  <div
    class="modal fade"
    id="error-checkin"
    data-backdrop="exampleModalLabel"
    data-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body p-0">
          <button type="button" class="close" data-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="check-in container text-white m-0">
            <h2 class="font-weight-bold">Error</h2>
            <p class="py-5" v-if="$store.state.error.message">
              {{ $store.state.error.message }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>