var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"modal fade",attrs:{"id":"cancel-appointment","data-backdrop":"exampleModalLabel","data-keyboard":"false","tabindex":"-1","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered modal-lg"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-body p-0"},[_vm._m(0),_c('div',{staticClass:"check-in container text-light p-0 m-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 left-row"},[_c('img',{attrs:{"src":_vm.getImage('westfield.png'),"alt":"westfield"}}),_c('div',{staticClass:"back-confirm-step"},[_c('button',{staticClass:"btn btn-black text-light rounded-pill px-4 py-2",staticStyle:{"background-color":"black !important"},attrs:{"data-dismiss":"modal"}},[_c('img',{staticClass:"pr-3",attrs:{"src":_vm.getIcon('arrow-left-solid-white.svg'),"alt":"chevron-left-solid"}}),_vm._v(" Back ")])])]),_c('div',{staticClass:"col-12 col-md-6 right-row p-5 text-left"},[_c('div',{staticClass:"d-flex h-100 flex-column justify-content-between"},[_vm._m(1),_c('main',[_c('p',{staticStyle:{"font-weight":"400"}},[_vm._v(" Are you sure you would like to cancel the following reservation? ")]),_c('hr',{staticClass:"my-lg-5"}),_c('div',{staticClass:"border p-4 rounded"},[(
                          _vm.$store.state.selectedOrder.session &&
                          _vm.$store.state.selectedOrder.session.title_shortname
                        )?_c('h2',[_vm._v(" "+_vm._s(_vm.$store.state.selectedOrder.session.title_shortname)+" ")]):_vm._e(),(_vm.$store.state.selectedOrder.session)?_c('p',[_vm._v(" Time: "+_vm._s(_vm.getTrimTime( _vm.$store.state.selectedOrder.session.start_timestamp ))+" ")]):_vm._e(),(_vm.$store.state.selectedOrder.session)?_c('p',[_vm._v(" Location: "),_c('a',{staticClass:"text-brand-gold",attrs:{"href":"https://goo.gl/maps/WeifUm8sjyV24X9c6","target":"_blank"}},[_vm._v(_vm._s(_vm.$store.state.selectedOrder.ticket_transaction .ticket_site_id))])]):_vm._e()])]),_c('footer',[_c('button',{staticClass:"btn btn-black font-weight-bold text-brand-gold px-4 py-2 rounded-pill border-brand-gold",staticStyle:{"background-color":"black"},on:{"click":function($event){return _vm.cancelReservation(
                          _vm.$store.state.selectedOrder.ticket_transaction
                            .ticket_id
                        )}}},[_vm._v(" Cancel reservation "),_c('img',{staticClass:"rotate-180 pr-3",attrs:{"src":require("../assets/icons/arrow-left-solid.svg"),"alt":"left arrow","srcset":""}})])])])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_c('img',{attrs:{"src":require("../assets/images/close-icon--white.svg"),"alt":"close icon"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('header',[_c('h3',{staticClass:"font-weight-bold"},[_vm._v("Cancelation")])])
}]

export { render, staticRenderFns }