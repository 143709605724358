export default {
    methods: {
        getExpierence(title_id) {
            let expierence = this.$store.state.sessions.find(
                (status) => status.title_id === title_id
            );
            if (expierence != undefined) {
                return expierence
            }
            return false;

        },
        getTitleData(title_id) {
            let titleData = this.$store.state.titles.find(
                (status) => status.title_id === title_id
            );
            if (titleData != undefined) {
                return titleData
            }
            return false;
        }
    }
}