<template>
  <div
    class="modal fade"
    id="confirm-my-visit"
    data-backdrop="exampleModalLabel"
    data-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="p-0 modal-body">
          <loading :active.sync="loader" :is-full-page="false"></loading>
          <div class="col-12 row">
            <div class="p-0 col-lg-6 d-none d-md-block">
              <img
                class="d-none d-lg-block"
                :src="getImage('westfield.png')"
                alt="westfield"
              />
              <div v-if="step1" class="back-confirm-step">
                <button
                  v-dataanchor
                  tagEvent="modal"
                  tagAction="close"
                  tagType="click"
                  tagSection="confirmation"
                  tagText="back"
                  data-dismiss="modal"
                  class="back-btn"
                >
                  <img
                    class="pr-1 pointer"
                    :src="getIcon('arrow-left-solid-white.svg')"
                    alt="chevron-left-solid"
                  />
                  Back
                </button>
              </div>
              <div
                class="p-4 ticket_qr d-none d-lg-block"
                v-if="
                  !step1 && $store.state.order_transaction.ticket_transaction
                "
              >
                <qrcode-vue
                  :value="
                    $store.state.order_transaction.ticket_transaction
                      .ticket_qr_code
                  "
                  :size="size"
                  level="H"
                  :background="'#000000'"
                  :foreground="'#ffffff'"
                ></qrcode-vue>
                <h3 class="mt-4">Order ID</h3>
                <p class="mt-4">
                  A copy of your QR code and order ID were also sent to your
                  e-mail
                </p>
              </div>
            </div>
            <div class="p-4 col-lg-6">
              <template v-if="step1 && $store.state.reserved_spot.datetime">
                <div class="text-left text-center text-lg-left">
                  <div>
                    <p class="mb-4 font-weight-bold font-24">Confirmation</p>
                    <h2 class="font-weight-bold font-32">
                      {{ $store.state.selected_title }}
                    </h2>
                    <p class="font-24">
                      {{ getTrimTime($store.state.reserved_spot.datetime) }} |
                      {{ getTrimDate($store.state.reserved_spot.datetime) }}
                    </p>
                    <p class="font-weight-bold font-19">
                      Location:
                      <a
                        v-dataanchor
                        tagEvent="link"
                        tagAction="click"
                        tagType="internal link"
                        :tagSection="this.$store.state.selected_title"
                        tagText="creative commons"
                        href="https://goo.gl/maps/WeifUm8sjyV24X9c6"
                        target="_blank"
                        >Creative commons</a
                      >
                    </p>
                  </div>
                  <hr style="border-top: 1px solid rgb(255 255 255)" />
                  <div>
                    <p class="font-16">
                      Display Name:
                      <input
                        type="text"
                        class="font-weight-bold"
                        id="customInputName"
                        :value="$store.state.profile.first_name"
                      /><img
                        :src="getIcon('Icon-pencil-solid.svg')"
                        alt="pencil"
                        class="ml-3"
                      />
                    </p>
                    <p class="pl-3 text-left font-19">Avatar:</p>
                    <div
                      class="d-flex justify-content-center justify-content-lg-start"
                    >
                      <div
                        v-if="$store.state.refresh"
                        class="mb-4 col-md-8"
                        id="stepsAvatar"
                      >
                        <carousel
                          :per-page="1"
                          :mouse-drag="true"
                          :paginationEnabled="false"
                          :navigationEnabled="true"
                          :navigateTo="$store.state.choosedAvatarIndex"
                          @page-change="SelectedAvatar"
                        >
                          <slide
                            v-for="(avatar, c) in $store.state.avatars"
                            :key="c"
                          >
                            <div class="mx-auto mt-0 mb-4 placeholder-img">
                              <img
                                :src="avatar.avatar_url"
                                style="
                                  display: block !important;
                                  margin-left: auto !important;
                                  margin-right: auto !important;
                                "
                              />
                            </div>
                          </slide>
                        </carousel>
                      </div>
                      <div v-else class="mb-4 placeholder-img">
                        <img
                          :src="$store.state.avatars[0].avatar_url"
                          style="
                            display: block !important;
                            margin-left: auto !important;
                            margin-right: auto !important;
                          "
                          class=""
                        />
                      </div>
                    </div>
                    <button
                      class="next-button"
                      v-dataanchor
                      tagEvent="link"
                      tagAction="click"
                      tagType="internal link"
                      :tagSection="this.$store.state.selected_title"
                      tagText="confirm my visit"
                      @click="(step1 = !step1), placeOrder()"
                    >
                      <span
                        v-dataanchor
                        tagEvent="link"
                        tagAction="click"
                        tagType="internal link"
                        :tagSection="this.$store.state.selected_title"
                        tagText="confirm my visit"
                        >Confirm my visit</span
                      >
                      <img
                        v-dataanchor
                        tagEvent="link"
                        tagAction="click"
                        tagType="internal link"
                        :tagSection="this.$store.state.selected_title"
                        tagText="confirm my visit"
                        class="rotate-180"
                        :src="getIcon('arrow-left-solid.svg')"
                        alt="arrow"
                      />
                    </button>
                  </div>
                </div>
              </template>
              <template v-if="!step1 && $store.state.reserved_spot.datetime">
                <div class="text-left">
                  <div>
                    <p class="mb-4 font-weight-bold font-24">
                      Complete
                      <img :src="getIcon('check-solid.svg')" alt="checked" />
                    </p>
                    <h2 class="font-weight-bold font-32">
                      {{ $store.state.selected_title }}
                    </h2>
                    <p class="font-24">
                      {{ getTrimTime($store.state.reserved_spot.datetime) }} |
                      {{ getTrimDate($store.state.reserved_spot.datetime) }}
                    </p>
                    <p class="font-weight-bold font-19">
                      Location:
                      <a
                        v-dataanchor
                        tagEvent="link"
                        tagAction="click"
                        tagType="internal link"
                        :tagSection="this.$store.state.selected_title"
                        tagText="creative commons"
                        href="https://goo.gl/maps/WeifUm8sjyV24X9c6"
                        target="_blank"
                        >Creative commons</a
                      >
                    </p>
                  </div>
                  <hr style="border-top: 1px solid rgb(255 255 255)" />
                  <div class="font-19">
                    <p>
                      Display Name:
                      <span>{{ $store.state.profile.first_name }}</span>
                    </p>
                    <p>Avatar:</p>
                    <div class="mb-4 placeholder-img">
                      <img
                        class="mb-4"
                        :src="$store.state.selected_avatar_url"
                      />
                    </div>
                    <button
                      class="next-button"
                      v-dataanchor
                      tagEvent="link"
                      tagAction="click"
                      tagType="internal link"
                      :tagSection="this.$store.state.selected_title"
                      tagText="finish"
                      @click="revertStep()"
                    >
                      <span
                        v-dataanchor
                        tagEvent="link"
                        tagAction="click"
                        tagType="internal link"
                        :tagSection="this.$store.state.selected_title"
                        tagText="finish"
                        >Finish</span
                      >
                      <img
                        class="rotate-180"
                        :src="getIcon('arrow-left-solid.svg')"
                        alt="arrow"
                      />
                    </button>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { Carousel, Slide } from "vue-carousel";
import { API } from "aws-amplify";
import Loading from "vue-loading-overlay";
import $ from "jquery";
import QrcodeVue from "qrcode.vue";
import {
  dataLayerAnchorTag,
  dataLayerButtonTag,
  dataLayerSelectTag,
  dataLayerInputTag,
} from "data-layer-test-oo-gtm";

export default {
  directives: {
    dataanchor: dataLayerAnchorTag,
    databutton: dataLayerButtonTag,
    "data-select": dataLayerSelectTag,
    "data-input": dataLayerInputTag,
  },
  data() {
    return {
      step1: true,
      hardcoded_ticekt_id: "b16eb8b2-bc1a-432f-93c0-7dde27127196",
      size: 150,
      loader: false,
      currentAvatar: null,
    };
  },
  components: {
    QrcodeVue,
    Carousel,
    Slide,
    Loading,
  },
  mounted() {
    this.step1 = !this.step1;
    this.step1 = !this.step1;
  },

  methods: {
    customName() {
      var inputWidth = document.getElementById("customInputName");
      inputWidth.style.width =
        (this.$store.state.profile.first_name.length + 1) * 8 + "px";
    },
    SelectedAvatar(index) {
      this.currentAvatar = this.$store.state.avatars[index];
      this.$store.state.selected_avatar_id = this.currentAvatar.avatar_id;
      this.$store.state.selected_avatar_url = this.currentAvatar.avatar_url;
      this.customName();
    },
    CheckSelectedAvatar() {
      let selectedAvatar = this.$store.state.selected_avatar_id;
      let getObject = this.$store.state.avatars.find((avatarObj, index) => {
        if (avatarObj != undefined && avatarObj.avatar_id === selectedAvatar) {
          return index, false;
        }
      });
      if (getObject == undefined) {
        return 1, false;
      }
    },
    saveQrCode() {
      var canvas = document.getElementById("canvasQR");
      var img = canvas[0].toDataURL("image/png");
      document.write('<img src="' + img + '"/>');
    },
    getTrimTime(timestamp) {
      return moment(timestamp).format("hh:mm A");
    },
    getTrimDate(timestamp) {
      return moment(timestamp).format("ddd, MMM D, YYYY");
    },
    getImage(img) {
      return require("@/assets/avatars/" + img);
    },
    revertStep() {
      $("#confirm-my-visit").modal("hide");
      this.$nextTick(() => {
        this.step1 = !this.step1;
      });
    },
    getIcon(img) {
      return require("@/assets/icons/" + img);
    },
    async placeOrder() {
      // post method with order details
      let order_details = {
        body: {
          ada: this.$store.state.adaSelection,
          title_id: this.$store.state.selected_title_id,
          experience_start: this.$store.state.reserved_spot.datetime,
          site_id: this.$store.state.selected_site_id,
        },
      };
      this.loader = true;
      let vm = this;
      await this.$store
        .dispatch("setStudentOrder", order_details)
        .then(async () => {
          //datalayer event goes here
          window.dataLayer.push({
            event: "link",
            action: "click",
            name: "onclick",
            type: "on confirmation",
            region: "main content",
            section: "confirmation",
            text: "reservation confirmation",
            user_email: vm.$store.state.profile.email,
            act_name: vm.$store.state.selected_title.toLowerCase(),
          });
          this.$store.dispatch("getOutstandingOrders");
          this.loader = false;
          let tempdata = {
            body: {
              avatar_id: this.$store.state.selected_avatar_id,
            },
          };
          API.post(
            "asulink",
            `/account/avatar/${this.$store.state.selected_title_id}`,
            tempdata
          );
        })
        .catch((e) => {
          this.loader = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  overflow: auto !important;
}
#stepsAvatar {
  .owl-stage-outer,
  .owl-stage {
    height: 120px !important;
  }
  .owl-dots {
    display: none !important;
  }
}
input {
  background: #151515;
  color: white;
  border: none;
}
#customInputName {
  max-width: 134px;
}

#stepsAvatar {
  .carousel-3d-container {
    height: 120px !important;
  }
  .carousel-3d-slide.current {
    width: 200px !important;
    left: 25% !important;
  }
  .right-1,
  .left-1 {
    opacity: 0.2 !important;
  }
}
.modal-dialog {
  padding-top: 60px;
}
.modal-header {
  border-bottom: none;
}

.modal-content {
  background-color: #151515;
}
</style>

<style>
.VueCarousel-navigation-button {
  color: #ffffff !important;
}
.ticket_qr {
  position: absolute;
  top: 10%;
  background: black;
  width: 70%;
  left: 15%;
}

.back-confirm-step {
  position: absolute;
  top: 24px;
  left: 26px;
}

.back-btn {
  background: black;
  padding: 5px 10px;
  border-radius: 19px;
}
</style>
<style lang="scss">
#stepsAvatar {
  max-height: 250px;
  overflow: hidden;
  margin-top: -65px;
  img.scale {
    height: auto !important;
    display: block; /* corrects small inline gap at bottom of containing div */
    width: 100%;
  }
  .VueCarousel-navigation-button {
    top: 50%;
  }
  .VueCarousel-navigation-prev {
    left: 13px;
  }
  .VueCarousel-navigation-next {
    right: 3px;
  }
}
.placeholder-img {
  max-height: 250px;
  overflow: hidden;
  margin-top: -65px;
  width: 190px;
  img {
    height: auto;
    width: 100%;
  }
}
</style>