<template>
  <div class="home">
    <login
      v-if="this.$store.state.role == '' && !$store.state.profile.first_name"
    />
    <InviteUserModals v-if="this.$store.state.role == 'instructor'" />
    <CreateYourAccount
      v-if="
        this.$store.state.role == 'guest' &&
        this.$store.state.component == 'create-your-account'
      "
    />
    <Landing
      v-if="$store.state.component == 'landing' && $store.state.view == 'alien'"
    />
    <Waiver v-if="$store.state.component == 'waiver'" />
    <ConsentForm v-if="$store.state.component == 'consentForm'" />
    <ChooseYourAvatarLanding
      v-if="
        $store.state.component == 'landing' && $store.state.view == 'avatar'
      "
    />
    <ThePodsLanding
      v-if="$store.state.component == 'landing' && $store.state.view == 'pods'"
    />
    <OnlineExperienceLanding
      v-if="
        $store.state.component == 'landing' && $store.state.view == 'online'
      "
    />
    <!-- NEVER COMMENT CALENDAR OUT -->
    <Calendar v-if="this.$store.state.role == 'admin'" />
  </div>
</template>

<script>
// @ is an alias to /src
import Landing from "@/components/Landing.vue";
import login from "@/components/login.vue";
import Waiver from "./waiverLanding";
import ConsentForm from "@/components/ConsentForm.vue";
import ChooseYourAvatarLanding from "../components/ChooseYourAvatarLanding";
import ThePodsLanding from "../components/ThePodsLanding";
import OnlineExperienceLanding from "../components/OnlineExperienceLanding.vue";
import CreateYourAccount from "../components/CreateYourAccount.vue";
import Calendar from "../components/instructor/Calendar";
import InviteUserModals from "../components/instructor/InviteUserModals.vue";
import router from "../router/index";

export default {
  name: "Home",
  components: {
    login,
    Landing,
    Waiver,
    ConsentForm,
    ChooseYourAvatarLanding,
    ThePodsLanding,
    OnlineExperienceLanding,
    CreateYourAccount,
    InviteUserModals,
    Calendar,
  },
  mounted() {
    if (
      this.$store.state.role == "instructor" ||
      this.$store.state.role == "pod operator"
    ) {
      router.push({ name: "Dashboard" });
    }
  },
};
</script>
