<template>
  <div>
    <div
      class="modal fade"
      id="cancel-appointment"
      data-backdrop="exampleModalLabel"
      data-keyboard="false"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body p-0">
            <button type="button" class="close" data-dismiss="modal">
              <span aria-hidden="true"
                ><img
                  src="../assets/images/close-icon--white.svg"
                  alt="close icon"
              /></span>
            </button>
            <div class="check-in container text-light p-0 m-0">
              <div class="row">
                <div class="col-12 col-md-6 left-row">
                  <img :src="getImage('westfield.png')" alt="westfield" />
                  <div class="back-confirm-step">
                    <button
                      data-dismiss="modal"
                      style="background-color: black !important"
                      class="btn btn-black text-light rounded-pill px-4 py-2"
                    >
                      <img
                        :src="getIcon('arrow-left-solid-white.svg')"
                        alt="chevron-left-solid"
                        class="pr-3"
                      />
                      Back
                    </button>
                  </div>
                </div>
                <div class="col-12 col-md-6 right-row p-5 text-left">
                  <div class="d-flex h-100 flex-column justify-content-between">
                    <header>
                      <h3 class="font-weight-bold">Cancelation</h3>
                    </header>
                    <main>
                      <p style="font-weight: 400" >
                        Are you sure you would like to cancel the following
                        reservation?
                      </p>
                      <hr class="my-lg-5" />
                      <div class="border p-4 rounded">
                        <h2
                          v-if="
                            $store.state.selectedOrder.session &&
                            $store.state.selectedOrder.session.title_shortname
                          "
                        >
                          {{
                            $store.state.selectedOrder.session.title_shortname
                          }}
                        </h2>
                        <p v-if="$store.state.selectedOrder.session">
                          Time:
                          {{
                            getTrimTime(
                              $store.state.selectedOrder.session.start_timestamp
                            )
                          }}
                        </p>
                        <p v-if="$store.state.selectedOrder.session">
                          Location:
                          <a
                            href="https://goo.gl/maps/WeifUm8sjyV24X9c6"
                            target="_blank"
                            class="text-brand-gold"
                            >{{
                              $store.state.selectedOrder.ticket_transaction
                                .ticket_site_id
                            }}</a
                          >
                        </p>
                      </div>
                    </main>
                    <footer>
                      <button
                        style="background-color: black"
                        @click="
                          cancelReservation(
                            $store.state.selectedOrder.ticket_transaction
                              .ticket_id
                          )
                        "
                        class="
                          btn btn-black
                          font-weight-bold
                          text-brand-gold
                          px-4
                          py-2
                          rounded-pill
                          border-brand-gold
                        "
                      >
                        Cancel reservation
                        <img
                          src="../assets/icons/arrow-left-solid.svg"
                          class="rotate-180 pr-3"
                          alt="left arrow"
                          srcset=""
                        />
                      </button>
                    </footer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import $ from "jquery";
import { API } from "aws-amplify";
export default {
  props: {
    compData: Object,
  },
  methods: {
    getImage(img) {
      return require("@/assets/avatars/" + img);
    },
    async cancelReservation(id) {
      await API.del("proxy", `/${this.$store.state.selectedSiteForScheduler.site_id}/dsl/tickets/order/${id}`)
        .then(() => {
          this.$store.dispatch("getOutstandingOrders");
        })
        .catch((err) => {
          window.console.log("Error occured", err);
        });
      $("#cancel-appointment").modal("hide");
    },
    getIcon(img) {
      return require("@/assets/icons/" + img);
    },
    getTrimTime(timestamp) {
      return moment(timestamp).format("hh:mm A");
    },
  },
};
</script>

<style scoped>
.back-confirm-step {
  position: absolute;
  top: 3%;
  left: 12%;
}
.back-btn {
  background: black;
  padding: 5px 10px;
  border-radius: 19px;
}
.close {
  margin-top: -1.6rem;
  background-color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 0;
}
</style>