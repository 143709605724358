<template>
  <div>
    <div class="landing">
      <div
        class="
          text-white
          details
          h-100
          pl-0
          d-flex
          justify-content-center
          w-100
        "
      >
        <div class="container d-flex flex-wrap h-100">
          <div class="col-12 col-md-6 my-auto pl-lg-0">
            <h1 class="text-left mb-0 font-weight-900 font-48">
              Anytime, anywhere experiences
            </h1>
            <p class="text-left mb-0 font-16 info-online">
              Experience Dreamscape Learn online, either from your computer or
              using a PC-powered VR Headset. Interact immersively using your
              mouse and keyboard, the recommended joystick or with the hand
              controllers included with your headset.
            </p>
            <button
              type="button"
              class="next-button d-block"
              @click="onButtonClick()"
            >
              <span
                v-dataanchor
                tagEvent="link"
                tagAction="click"
                tagType="internal link"
                tagSection="online experiences"
                tagText="next"
                class="font-weight-bold pr-12"
                >Next</span
              >
              <img
                class=""
                src="../assets/icons/arrow-left-solid.svg"
                alt="arrow left"
              />
            </button>
          </div>
        </div>
      </div>
      <div class="row hero-image-online">
        <img
          class="background-online img-fluid"
          src="../assets/images/anytime-anywhere-landing.png"
          alt=""
        />
      </div>
    </div>
    <div class="mobile-online">
      <div>
        <div class="text-white details h-100">
          <div class="row h-100">
            <div class="col-12">
              <div class="imageGradient">
                <img
                  class="img-fluid"
                  src="../assets/images/anytime-anywhere-landing.png"
                  alt=""
                />
              </div>
            </div>
            <div class="col-12 px-5">
              <h1 class="text-left mb-0 font-32 font-weight-900 py-3">
                Anytime, anywhere experiences
              </h1>
              <p class="text-left mb-0 font-14 pb-4">
                Experience Dreamscape Learn online, either from your computer or
                using a PC-powered VR Headset. Interact immersively using your
                mouse and keyboard, the recommended joystick or with the hand
                controllers included with your headset.
              </p>
              <button
                type="button"
                class="next-button d-block"
                @click="onButtonClick()"
              >
                <span
                  v-dataanchor
                  tagEvent="link"
                  tagAction="click"
                  tagType="internal link"
                  tagSection="online experiences"
                  tagText="next"
                  class="font-weight-bold pr-12"
                  >Next</span
                >
                <img
                  class=""
                  src="../assets/icons/arrow-left-solid.svg"
                  alt="arrow left"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "./Button.vue";
import router from "../router/index";
import {
  dataLayerAnchorTag,
  dataLayerButtonTag,
  dataLayerSelectTag,
  dataLayerInputTag,
} from "data-layer-test-oo-gtm";

export default {
  directives: {
    dataanchor: dataLayerAnchorTag,
    databutton: dataLayerButtonTag,
    "data-select": dataLayerSelectTag,
    "data-input": dataLayerInputTag,
  },
  components: {
    CustomButton,
  },
  methods: {
    onButtonClick() {
      router.push({ name: "Dashboard" });
    },
  },
};
</script>

<style lang="scss" scoped>
.landing {
  .info-online {
    max-width: 421px;
  }
  position: relative;
  height: 100vh;
  overflow-x: hidden;

  .hero-image-online {
    position: relative;
    left: 16%;
    width: 100%;
    size: cover;
  }
  .hero-image-online:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(
        93deg,
        rgba(21, 21, 21, 1) 16%,
        rgba(0, 0, 0, 0) 40%
      ),
      linear-gradient(180deg, rgba(21, 21, 21, 1) 2%, rgba(0, 0, 0, 0) 10%); /* W3C */
  }
  p {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .details {
    position: absolute;
    z-index: 2;
    padding-left: 12%;

    h1 {
      font-weight: 900 !important;
    }
  }

  .background-online {
    display: block;
    height: 100vh;
    width: 100vw;
  }
}

.mobile-online {
  display: none;
}

@media only screen and (max-width: 768px) {
  .landing {
    display: none;
  }
  .mobile-online {
    display: block;
  }
}
</style>
