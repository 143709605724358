<template>
  <div>
    <div class="landing-the-pods">
      <div
        class="text-white details h-100 pl-lg-0 d-flex justify-content-center w-100"
      >
        <div class="container d-flex flex-wrap h-100">
          <div class="col-12 col-md-6 my-auto pl-lg-0">
            <h1 class="text-left mb-0 font-weight-900 font-48">
              Dreamscape Research Lab
            </h1>
            <p class="text-left mb-0 font-16">
              On campus, you can experience the haptic-enriched, fully-immersive
              VR version from the Dreamscape Pod. Put on your hand trackers,
              pull on your headset, and take hold of your joystick to freely
              explore and interact with extraordinary creatures and worlds. And
              remember, our headsets accommodate most glasses.
            </p>
            <button
              type="button"
              class="next-button d-block"
              @click="onButtonClick()"
            >
              <span
                v-dataanchor
                tagEvent="link"
                tagAction="click"
                tagType="internal link"
                tagSection="dreamscape research lab"
                tagText="next"
                class="font-weight-bold pr-12"
                >Next</span
              >
              <img
                class=""
                src="../assets/icons/arrow-left-solid.svg"
                alt="arrow left"
              />
            </button>
          </div>
        </div>
      </div>
      <div class="row hero-image-pods">
        <img
          class="background-pods"
          src="../assets/images/ThePods.png"
          alt=""
        />
      </div>
    </div>
    <div class="mobile-pods">
      <div>
        <div class="text-white details h-100">
          <div class="row h-100">
            <div class="col-12">
              <div class="gradient imageGradient">
                <img
                  class="img-fluid mobile-2x-pods"
                  src="../assets/images/ThePodsMobile@2x.png"
                  alt=""
                />
              </div>
            </div>
            <div class="col-12 px-5">
              <h1 class="text-left mb-0 font-32 font-weight-900 py-3">
                The Pods
              </h1>
              <p class="text-left mb-0 font-14 pb-4">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum
              </p>
              <button
                type="button"
                class="next-button d-block"
                @click="onButtonClick()"
              >
                <span
                  v-dataanchor
                  tagEvent="link"
                  tagAction="click"
                  tagType="internal link"
                  tagSection="dreamscape research lab"
                  tagText="next"
                  class="font-weight-bold pr-12"
                  >Next</span
                >
                <img
                  class=""
                  src="../assets/icons/arrow-left-solid.svg"
                  alt="arrow left"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  dataLayerAnchorTag,
  dataLayerButtonTag,
  dataLayerSelectTag,
  dataLayerInputTag,
} from "data-layer-test-oo-gtm";

export default {
  directives: {
    dataanchor: dataLayerAnchorTag,
    databutton: dataLayerButtonTag,
    "data-select": dataLayerSelectTag,
    "data-input": dataLayerInputTag,
  },
  components: {},
  methods: {
    onButtonClick() {
      this.$store.state.view = "online";
    },
  },
};
</script>

<style lang="scss">
.landing-the-pods {
  position: relative;
  height: 100vh;
  overflow-x: hidden;

  .hero-image-pods {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
  }
  .hero-image-pods:after {
    content: "";
    position: absolute;
    left: 32%;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(
        93deg,
        rgba(21, 21, 21, 1) 10%,
        rgba(0, 0, 0, 0) 22%
      ),
      linear-gradient(180deg, rgba(21, 21, 21, 1) 10%, rgba(0, 0, 0, 0) 20%); /* W3C */
  }
  p {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .details {
    position: absolute;
    z-index: 2;
    padding-left: 12%;

    h1 {
      font-weight: 900 !important;
    }
  }

  .background-pods {
    position: absolute;
    display: block;
    height: 100vh;
    width: 65vw;
    right: 0;
  }
}

.mobile-pods {
  display: none;
}

.gradient {
  position: relative;
  display: inline-block;

  img {
    display: block;
  }
}
.gradient:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: inline-block;
  background: linear-gradient(
      90deg,
      rgba(21, 21, 21, 1) 1%,
      rgba(0, 0, 0, 0) 15%
    ),
    linear-gradient(180deg, rgba(21, 21, 21, 1) 1%, rgba(0, 0, 0, 0) 25%),
    linear-gradient(0deg, rgba(21, 21, 21, 1) 60%, rgba(0, 0, 0, 0) 65%); /* W3C */
}

@media only screen and (max-width: 768px) {
  .landing-the-pods {
    display: none;
  }
  .mobile-pods {
    display: block;
  }
  .mobile-2x-pods {
    size: cover;
    width: 100vw;
    max-height: 300px !important;
  }
  .imageGradient {
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100vh;
      display: inline-block;
      background: linear-gradient(90deg, #151515 12%, rgba(0, 0, 0, 0) 30%),
        linear-gradient(180deg, #151515 1%, rgba(0, 0, 0, 0) 25%),
        linear-gradient(0deg, #151515 62%, rgba(0, 0, 0, 0) 70%),
        linear-gradient(270deg, #151515 5%, rgba(0, 0, 0, 0) 15%);
    }
  }
}
</style>
