<template>
  <div class="container">
    <div class="row mb-3 align-items-center bgShdow">
      <div class="col-md-6 col-12 mxHeight500">
        <div
          class="text-left col-lg-10 h-100 justify-content-center d-flex flex-column"
        >
          <h4 class="font-weight-900 font-24">
            Are you doing this experience as a part of your ASU employment?
          </h4>
          <p class="font-md-16 font-14 mb-4 mt-3 pb-1">
            This won't affect your experience, we just need to make sure you
            sign the right form
          </p>
          <button class="p-3 d-none d-lg-block border-layout roundBtn">
            Select an option
            <img
              class="pointer pr-1 rotate-180"
              :src="getIcon('leftArrow_wavierQuestion.svg')"
              alt="chevron-left-solid"
            />
          </button>
        </div>
      </div>
      <div id="right-section" class="col-12 col-md-6 mxHeight500">
        <div
          class="d-lg-flex flex-column align-items-center waiver-select h-100 justify-content-center px-lg-5 pl-29 pr-29 pt-32 pt-lg-0 pb-4"
        >
          <button
            class="m-0 py-3 px-lg-auto border-layout"
            @click="onButtonClick(false)"
          >
            Yes
          </button>
          <button
            class="mt-3 m-0 py-3 px-lg-auto border-layout"
            @click="onButtonClick(true)"
          >
            No
          </button>
          <p
            class="font-12 text-white text-left mt-4 mt-lg-70 mb-0 opacity60"
            id="textASUOption"
          >
            You have voluntarily elected to participate in a virtual reality
            experience and related activities (the “Experience”) provided by, or
            on behalf of, Dreamscape Immersive, Inc., a Delaware corporation and
            its affiliates (collectively, the “Companies”). As a condition to
            such participation, or the participation of any minor between the
            ages of ten (10) and thirteen (13) under your legal custody,
            supervision or control (the “Minor”), if applicable, you are
            required to sign this Liability Release, Waiver of Liability and
            Indemnification Agreement (this “Release”).
          </p>
        </div>
      </div>
      <div class="col-12 d-lg-none mt-2">
        <button class="p-3 maxWidth230 border-layout roundBtn mb-4">
          Select an option
          <img
            class="pointer pr-1 rotate-180"
            :src="getIcon('leftArrow_wavierQuestion.svg')"
            alt="chevron-left-solid"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { API } from "aws-amplify";
export default {
  methods: {
    agreeWaiver() {},
    async onButtonClick(combined) {
      this.$store.state.loader = true;
      let urlData = {
        body: {
          return_url: window.location.origin + window.location.pathname,
          combined: combined,
        },
      };
      await API.post("proxy", `/${this.$store.state.selected_site_id}/waiver/prepare`, urlData).then(async (res) => {
        if (res.envelopeId) {
          // docc sign
          let envelopeId = res.envelopeId;
          this.$store.commit("setEnvelopeId", res.envelopeId);
          window.location.href = res.redirectUrl;
          await API.get("proxy", `/${this.$store.state.selected_site_id}/waiver/envelope/${envelopeId}`).then(
            async (res) => {
              // redirect to URL and then redirecting back PUT envelope
              let elID = res.envelopeId;
              let myEnvelop = {
                body: {
                  envelopeId: elID,
                },
              };
              await API.put("proxy", `/${this.$store.state.selected_site_id}/waiver`, myEnvelop);
            }
          );
        } else if (res.agreementId) {
          // adobe docc sign
          let agreementId = res.agreementId;
          this.$store.commit("setAgreementId", agreementId);
          window.location.href = res.redirectUrl;
        }
      });
    },
    cliclNo() {
      if (this.$store.state.component == "waiver") {
        this.$store.state.component = "landing";
        this.$store.state.view = "avatar";
      }
    },
    getIcon(img) {
      return require("@/assets/icons/" + img);
    },
  },
};
</script>

<style scoped>
.opacity60 {
  opacity: 0.6;
}
.bgShdow {
  background: transparent linear-gradient(270deg, #15151500 0%, #151515 100%) 0%
    0% no-repeat padding-box;
}
.mxHeight500 {
  max-height: 500px;
}
.maxWidth230 {
  max-width: 230px;
}
@media only screen and (min-width: 992px) {
  #textASUOption {
    margin-top: 70px !important;
  }
  .mxHeight500 {
    height: 100%;
  }
  .waiver-select {
    background: #272727;
    border-top: 0px solid !important;
    border-left: 1px solid #ffc627 !important;
  }
  button {
    max-width: 280px !important;
  }
}
.roundBtn {
  border: 1px solid #484848 !important;
  color: #a8a8a8 !important;
  font-size: 16px !important;
  border-radius: 30px !important;
  background-color: #151515;
  cursor: pointer !important;
}
.roundBtn:hover {
  background-color: #151515 !important;
}
.row {
  height: 100vh;
}
.centered {
  position: relative;
  top: 25%;
}
.waiver-select {
  background: #272727;
  border-left: 0px solid;
  border-top: 1px solid #ffc627;
}
button {
  border-color: #ffc627;
  font-weight: bold;
  max-width: 100%;
  width: 100%;
}
button:hover {
  background: #ffc627;
  color: black;
}
</style>
