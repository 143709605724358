<template>
  <div>
    <div class="landing">
      <div
        class="text-white details h-100 pl-0 d-flex justify-content-center w-100"
      >
        <div class="container d-flex flex-wrap h-100">
          <div class="col-12 col-md-7 my-auto pl-0">
            <h1 class="text-left mb-0 font-weight-900 font-48">
              About the Alien Zoo experience
            </h1>
            <p class="text-left mb-0 font-16 mr-0 alien-zoo-text">
              An intergalactic wildlife refuge protecting the endangered species
              of the universe. As a first representative from Earth, your
              mission is to maintain these extraordinary organisms and
              ecosystems. Your research team will develop and apply scientific
              knowledge and quantitative skills to solve the refuge’s biggest
              challenges.
            </p>
            <div class="d-flex">
              <button
                type="button"
                class="next-button"
                @click="onButtonClick()"
              >
                <span
                  v-dataanchor
                  tagEvent="link"
                  tagAction="click"
                  tagType="internal link"
                  tagSection="about the alien zoo experience"
                  tagText="next"
                  class="font-weight-bold pr-12"
                  >Next</span
                >
                <img
                  class=""
                  src="../assets/icons/arrow-left-solid.svg"
                  alt="arrow left"
                />
              </button>
              <div class="px-5 py-3 d-flex justify-content-center">
                <button
                  v-if="$store.state.waivers.length > 0"
                  @click="skipSteps()"
                  class=""
                >
                  <span
                    v-dataanchor
                    tagEvent="link"
                    tagAction="click"
                    tagType="internal link"
                    tagSection="about the alien zoo experience"
                    tagText="skip"
                    >Skip</span
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row hero-image">
        <img class="background" src="../assets/images/alien-new.png" alt="" />
      </div>
    </div>
    <div class="mobile-alien">
      <div>
        <div class="text-white details h-100">
          <div class="row h-100">
            <div class="col-12 mobile-gradient">
              <img
                class="img-fluid mobile-2x-alien"
                src="../assets/images/alien-new.png"
                alt=""
              />
            </div>
            <div class="col-12 px-5">
              <h1 class="text-left mb-0 font-32 font-weight-900 py-3">
                About the Alien Zoo experience
              </h1>
              <p class="text-left mb-0 font-14 pb-4">
                Welcome to the Alien Zoo – an intergalactic wildlife refuge that
                protects the endangered species of the universe. The IWF, in
                partnership with Dreamscape Learn (or ASU), invite you to be the
                first representatives from planet Earth to help maintain and
                study these extraordinary organisms and ecosystems. Under the
                guidance of its artificially intelligent creators, your research
                team will develop and apply scientific knowledge and
                quantitative skills to solve the refuge’s most challenging
                problems, such as preventing disease and preserving diversity.
                What you learn on your journey will surely make you a valuable
                asset to your own species.
              </p>
              <button
                type="button"
                class="next-button"
                @click="onButtonClick()"
              >
                <span
                  v-dataanchor
                  tagEvent="link"
                  tagAction="click"
                  tagType="internal link"
                  tagSection="about the alien zoo experience"
                  tagText="next"
                  class="font-weight-bold pr-12"
                  >Next</span
                >
                <img
                  class=""
                  src="../assets/icons/arrow-left-solid.svg"
                  alt="arrow left"
                />
              </button>
              <div class="px-5 pb-3 d-flex justify-content-center pt-0 mt-32">
                <button
                  v-databutton
                  v-if="$store.state.waivers.length > 0"
                  @click="skipSteps()"
                  class=""
                >
                  Skip
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "./Button.vue";
import {
  dataLayerAnchorTag,
  dataLayerButtonTag,
  dataLayerSelectTag,
  dataLayerInputTag,
} from "data-layer-test-oo-gtm";

export default {
  directives: {
    dataanchor: dataLayerAnchorTag,
    databutton: dataLayerButtonTag,
    "data-select": dataLayerSelectTag,
    "data-input": dataLayerInputTag,
  },
  data() {
    return {
      mobile: true,
    };
  },
  computed: {
    windowWidth() {
      return window.innerWidth;
    },
  },
  components: {
    CustomButton,
  },
  methods: {
    onButtonClick() {
      if (this.$store.state.view == "alien") {
        this.$store.state.component = "landing";
        this.$store.state.view = "avatar";
      }
    },
    skipSteps() {
      this.$router.push({ name: "Dashboard" });
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.landing {
  position: relative;
  height: 100vh;
  overflow-x: hidden;
  .alien-zoo-text {
    max-width: 459px;
  }

  .hero-image {
    position: relative;
    left: 8%;
    width: 100%;
    size: cover;
  }
  .hero-image:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(
        93deg,
        rgba(21, 21, 21, 1) 20%,
        rgba(0, 0, 0, 0) 40%
      ),
      linear-gradient(180deg, rgba(21, 21, 21, 1) 2%, rgba(0, 0, 0, 0) 20%); /* W3C */
  }
  p {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .details {
    position: absolute;
    z-index: 2;
    padding-left: 12%;
  }
  .background {
    display: block;
    height: 100vh;
    width: 100vw;
    margin-left: 1px;
  }
}

.mobile-gradient {
  position: relative;
  display: inline-block;

  img {
    display: block;
    margin-left: -6%;
  }
}
.mobile-gradient:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: inline-block;
  background: linear-gradient(
      90deg,
      rgba(21, 21, 21, 1) 12%,
      rgba(0, 0, 0, 0) 35%
    ),
    linear-gradient(180deg, rgba(21, 21, 21, 1) 1%, rgba(0, 0, 0, 0) 25%),
    linear-gradient(0deg, rgba(21, 21, 21, 1) 60%, rgba(0, 0, 0, 0) 65%),
    linear-gradient(270deg, rgba(21, 21, 21, 1) 5%, rgba(0, 0, 0, 0) 15%);
}

.mobile-alien {
  display: none;
}

@media only screen and (max-width: 768px) {
  .landing {
    display: none;
  }
  .mobile-alien {
    display: block;
  }
  .mobile-2x-alien {
    size: cover;
    width: 100vw;
    height: 300px !important;
  }
}
</style>
