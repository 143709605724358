<template>
  <div
    class="modal fade p-0"
    id="checkin-modal"
    data-backdrop="exampleModalLabel"
    data-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
    style="border-radius: 12px !important"
  >
    {{ pageInfo() }}
    <div
      class="modal-dialog modal-md modal-dialog-centered mx-0 mx-md-auto mb-0 mb-md-auto"
      :class="{
        'modal-dialog-centered-mobile': isModalCenteredMobile(
          $store.state.view
        ),
      }"
      style="border-radius: 12px !important"
    >
      <div
        class="modal-content modal-content-vip"
        :class="{
          'modal-content-scanqr': isModalScanqr($store.state.view),
          'modal-content-vip': isModalVip($store.state.view),
        }"
        style="
          border-radius: 12px !important;
          box-shadow: 0px 3px 14px #ffffff60;
          border: 1px solid #93751f;
        "
      >
        <div class="modal-body p-0" style="border-radius: 18px !important">
          <loading :active.sync="loader" :is-full-page="false"></loading>
          <button
            type="button"
            class="close m-4 d-none"
            :class="{ 'd-md-flex': !isModalCentered($store.state.view) }"
            data-dismiss="modal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div
            class="check-in container text-light p-0 m-0"
            :class="{ 'check-in-mobile': isModalCentered($store.state.view) }"
            style="border-radius: 12px !important"
          >
            <div class="row m-0">
              <div
                class="col-12 left-row"
                :class="{
                  'col-lg-6 p-5': !isModalCentered($store.state.view),
                  'left-row-mobile px-5 pt-5 pb-1': isModalCentered(
                    $store.state.view
                  ),
                }"
                style="border-radius: 12px !important"
              >
                <div v-if="$store.state.view == 'scanQR'" style="height: 85%">
                  <div
                    class="d-none d-md-block"
                    style="
                      background-color: #151515 !important;
                      border-radius: 12px !important;
                    "
                  >
                    <div
                      class="title-bar col-12"
                      style="border: none !important"
                    >
                      <h4 class="title-heading kallisto-bold"></h4>
                      <button
                        type="button"
                        class="title-close-btn"
                        data-dismiss="modal"
                      >
                        <img
                          :src="getIcon('x-button.svg')"
                          alt="x-button"
                          class="x-button"
                        />
                      </button>
                    </div>
                    <div
                      class="my-3 d-block qrcode-stream-mobile"
                      style="background-color: #151515 !important"
                    >
                      <template v-if="!enablebtn && enableCamera">
                        <qrcode-stream
                          class="d-none d-xl-block"
                          @decode="onDecode"
                          style="
                            border: 1px solid #151515;
                            border-radius: 12px !important;
                            min-height: 377px !important;
                          "
                        >
                          <img
                            src="../assets/images/scanner_lines_mobile.png"
                            alt="scanner_lines_mobile"
                            class="scanner_lines-image"
                            style="width: 70% !important"
                          />
                        </qrcode-stream>
                        <qrcode-stream
                          class="d-xl-none d-block px-5"
                          :camera="camera"
                          @init="onInit"
                          @decode="onDecode"
                          style="
                            border: 1px solid #151515;
                            border-radius: 12px !important;
                            min-height: 236px !important;
                          "
                        >
                          <img
                            src="../assets/images/scanner_lines_mobile.png"
                            alt="scanner_lines_mobile"
                            class="scanner_lines-image"
                            style="width: 48% !important"
                          />
                        </qrcode-stream>
                        <span
                          class="kallisto-bold font-20 pt-32 pb-16"
                          style="display: inline-block; color: #ffffff"
                        >
                          Scan QR Code
                        </span>
                      </template>
                      <template v-else> </template>
                    </div>
                  </div>
                  <div
                    class="d-block d-md-none"
                    style="
                      background-color: #151515 !important;
                      border-radius: 12px !important;
                    "
                  >
                    <div
                      class="title-bar col-12"
                      style="border: none !important"
                    >
                      <h4 class="title-heading kallisto-bold"></h4>
                      <button
                        type="button"
                        class="title-close-btn"
                        data-dismiss="modal"
                      >
                        <img
                          :src="getIcon('x-button.svg')"
                          alt="x-button"
                          class="x-button"
                        />
                      </button>
                    </div>
                    <div
                      class="mt-3 d-block qrcode-stream-mobile"
                      style="background-color: #151515 !important"
                    >
                      <template v-if="!enablebtn && enableCamera">
                        <qrcode-stream
                          class="d-none d-xl-block"
                          @decode="onDecode"
                        ></qrcode-stream>
                        <qrcode-stream
                          class="d-xl-none d-block px-5"
                          :camera="camera"
                          @init="onInit"
                          @decode="onDecode"
                          style="
                            border: 1px solid #151515;
                            border-radius: 12px !important;
                            min-height: 236px !important;
                          "
                        >
                          <img
                            src="../assets/images/scanner_lines_mobile.png"
                            alt="scanner_lines_mobile"
                            class="scanner_lines-image"
                            style="width: 48% !important"
                          />
                        </qrcode-stream>
                        <span
                          class="kallisto-bold font-20 pb-32"
                          style="
                            padding-top: 2rem;
                            display: inline-block;
                            color: #ffffff;
                          "
                        >
                          Scan QR Code
                        </span>
                      </template>
                      <template v-else> </template>
                    </div>
                  </div>
                </div>
                <template v-if="$store.state.view == 'verify'">
                  <div class="d-none d-md-block">
                    <div class="title-bar col-12">
                      <h4 class="title-heading kallisto-bold">Verify</h4>
                      <button
                        type="button"
                        class="title-close-btn"
                        data-dismiss="modal"
                      >
                        <img
                          :src="getIcon('x-button.svg')"
                          alt="x-button"
                          class="x-button"
                        />
                      </button>
                    </div>
                    <img :src="$store.state.selected_avatar_url" height="300" />

                    <div
                      class="text-left pl-20"
                      v-if="$store.state.ticket_details.ticket"
                    >
                      <p>
                        <strong>Name:</strong>
                        {{ $store.state.ticket_details.ticket.first_name }}
                      </p>
                      <p>
                        <strong>Display Name:</strong>
                        {{ $store.state.ticket_details.ticket.first_name }}
                        {{ $store.state.ticket_details.ticket.last_name }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-block d-md-none"
                    style="
                      border-radius: 12px !important;
                      border-bottom: 0.8px solid #93751f !important;
                      background-color: #151515 !important;
                    "
                  >
                    <div class="title-bar col-12">
                      <h4 class="title-heading kallisto-bold">Verify</h4>
                      <button
                        type="button"
                        class="title-close-btn"
                        data-dismiss="modal"
                      >
                        <img
                          :src="getIcon('x-button.svg')"
                          alt="x-button"
                          class="x-button"
                        />
                      </button>
                    </div>
                    <div
                      class="mt-3 d-block"
                      style="
                        border-bottom: 0.5px solid #93751f !important;
                        background-color: #151515 !important;
                      "
                    >
                      <img
                        :src="$store.state.selected_avatar_url"
                        height="300"
                      />
                      <div
                        class="text-left ml-3 font-weight-bold font-14"
                        v-if="$store.state.ticket_details.ticket"
                      >
                        <p>
                          <strong>Name:</strong>
                          {{ $store.state.ticket_details.ticket.first_name }}
                        </p>
                        <p>
                          <strong>Display Name:</strong>
                          {{ $store.state.ticket_details.ticket.first_name }}
                          {{ $store.state.ticket_details.ticket.last_name }}
                        </p>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else-if="$store.state.view == 'complete'">
                  <div class="d-none d-md-block">
                    <img
                      :src="getIcon('check-solid.svg')"
                      alt="checked"
                      class="checkin-complete mt-5"
                    />

                    <p class="font-22 mt-3">User was successfully added!</p>
                  </div>
                  <div
                    class="d-block d-md-none"
                    style="
                      border-radius: 12px !important;
                      background-color: #151515 !important;
                    "
                  >
                    <div class="title-bar col-12">
                      <h4 class="title-heading kallisto-bold">
                        Check in complete
                      </h4>
                      <button
                        type="button"
                        class="title-close-btn"
                        data-dismiss="modal"
                      >
                        <img
                          :src="getIcon('x-button.svg')"
                          alt="x-button"
                          class="x-button"
                        />
                      </button>
                    </div>
                    <div
                      class="mt-3 d-block"
                      style="
                        border-bottom: 0.5px solid #93751f !important;
                        background-color: #151515 !important;
                      "
                    >
                      <img
                        :src="$store.state.selected_avatar_url"
                        height="300"
                      />
                      <template
                        v-if="$store.state.ticket_details.ticket"
                        class="font-14 font-weight-bold mx-3"
                      >
                        <p v-if="$store.state.ticket_details.ticket">
                          <strong>Name:</strong>
                          {{ $store.state.ticket_details.ticket.first_name }}
                          {{ $store.state.ticket_details.ticket.last_name }}
                        </p>
                        <p v-if="$store.state.ticket_details.ticket">
                          <strong>Display Name:</strong>
                          {{ $store.state.ticket_details.ticket.first_name }}
                          {{ $store.state.ticket_details.ticket.last_name }}
                        </p>
                      </template>
                      <div
                        v-else
                        class="text-left mt-3 font-14 font-weight-bold mx-3"
                      >
                        <div class="">
                          Name:
                          <p
                            class="font-weight-normal font-12"
                            v-if="$store.state.addedUser.first_name"
                          >
                            {{ $store.state.addedUser.first_name
                            }}{{ $store.state.addedUser.last_name }}
                          </p>
                        </div>
                        <div class="" v-if="$store.state.addedUser.email">
                          E-mail:
                          <p class="font-weight-normal font-12">
                            {{ $store.state.addedUser.email }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else-if="$store.state.view == 'completeVIPCheckIn'">
                  <div class="my-5 mx-3" v-if="showErrorMsg.status">
                    <span class="text-danger font-16 font-weight-bold">
                      {{ showErrorMsg.text }}</span
                    >
                  </div>
                  <div
                    v-else
                    class="d-flex justify-content-center flex-column pt-3"
                  >
                    <div
                      class="d-flex justify-content-center align-items-center flex-column"
                    >
                      <img
                        :src="getIcon('checkin-vip.svg')"
                        alt="checkin-vip"
                        class="checkin-vip d-none d-md-block"
                        style="max-width: 48px; max-height: 48px"
                      />
                      <span class="font-16 font-md-18 os-semibold"
                        >Check-in complete!</span
                      >
                    </div>
                    <div
                      class="d-flex justify-content-center flex-column py-3 my-2 font-14 pl-100 pl-md-160"
                      style="background: #242424"
                    >
                      <div class="d-flex">
                        Show: &nbsp;
                        <span class="font-weight-bold">
                          {{ $store.state.selected_session.title_name }}
                        </span>
                      </div>
                      <div class="d-flex">
                        Start date: &nbsp;
                        <span class="font-weight-bold">
                          {{
                            getTrimDate(
                              $store.state.selected_session.start_timestamp
                            )
                          }}
                        </span>
                      </div>
                      <div class="d-flex">
                        Start time: &nbsp;
                        <span class="font-weight-bold">
                          {{
                            getTrimTime(
                              $store.state.selected_session.start_timestamp
                            )
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="$store.state.view == 'profile'">
                  <div class="d-none d-md-block">
                    <div class="title-bar col-12">
                      <span class="col-2"></span>
                      <h4 class="title-heading kallisto-bold col-8 p-0 m-0">
                        Add user
                      </h4>
                      <button
                        type="button"
                        class="title-close-btn col-2 p-0 d-flex justify-content-end pr-20"
                        data-dismiss="modal"
                      >
                        <img
                          :src="getIcon('x-button.svg')"
                          alt="x-button"
                          class="x-button"
                        />
                      </button>
                    </div>
                    <div
                      class="mt-24 d-block ml-20 mr-20"
                      style="
                        border-bottom: 0.5px solid #ffffff4d !important;
                        background-color: #151515 !important;
                      "
                    >
                      <div
                        class="d-block text-left font-weight-bold font-16"
                        style="color: #d0d0d0"
                      >
                        Select avatar
                      </div>
                      <div
                        class="d-flex justify-content-center align-items-center"
                        style=""
                      >
                        <div
                          style="
                            background: #ffc62766;
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                          "
                          class="justify-content-center align-items-center d-flex pointer"
                          @click="handleSlideClick(-1)"
                        >
                          <img
                            class="my-2"
                            height="15"
                            :src="getIcon('chevron-left-solid.svg')"
                            alt="chevron-left-solid"
                          />
                        </div>
                        <div style="min-width: 200px !important">
                          <carousel
                            class=""
                            v-if="$store.state.refresh"
                            :per-page="1"
                            :mouse-drag="false"
                            :paginationEnabled="false"
                            :navigationEnabled="false"
                            :navigateTo="$store.state.choosedAvatarIndex"
                            style="
                              width: 200px !important;
                              display: inline-block !important;
                            "
                          >
                            <slide
                              v-for="(avatar, c) in $store.state.avatars"
                              :key="c"
                              style="width: 120px !important"
                            >
                              <img
                                :src="avatar.avatar_url"
                                style="
                                  display: inline-block !important;
                                  margin-left: auto !important;
                                  margin-right: auto !important;
                                "
                                height="250"
                                width="120"
                              />
                            </slide>
                          </carousel>
                          <img
                            v-else-if="$store.state.avatars[0]"
                            :src="$store.state.avatars[0].avatar_url"
                            style="
                              display: inline-block !important;
                              margin-left: auto !important;
                              margin-right: auto !important;
                            "
                            height="250"
                            width="120"
                          />
                        </div>
                        <div
                          style="
                            background: #ffc62766;
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                          "
                          class="justify-content-center align-items-center d-flex pointer"
                          @click="handleSlideClick(1)"
                        >
                          <img
                            class="rotate-180 my-2"
                            height="15"
                            :src="getIcon('chevron-left-solid.svg')"
                            alt="chevron-left-solid"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="text-left pl-20 pr-20">
                      <div class="row m-0 font-14 font-weight-bold">
                        <div
                          class="col-6 pl-0 pr-12 pb-20 pt-16 font-16"
                          style="
                            border-bottom: 0.5px solid #ffffff4d !important;
                            background-color: #151515 !important;
                          "
                        >
                          First Name *
                          <input
                            type="text"
                            class="p-2 font-14 font-weight-normal mt-12 add-user-input"
                            placeholder="Enter first name"
                            v-model="first_name"
                          />
                        </div>
                        <div
                          class="col-6 pr-0 pl-12 pb-20 pt-16 font-16"
                          style="
                            border-bottom: 0.5px solid #ffffff4d !important;
                            background-color: #151515 !important;
                          "
                        >
                          Last Name *
                          <input
                            type="text"
                            class="p-2 font-14 font-weight-normal mt-12 add-user-input"
                            placeholder="Enter last name"
                            v-model="last_name"
                          />
                        </div>
                        <div
                          class="col-12 px-0 pb-20 pt-16 font-16"
                          style="background-color: #151515 !important"
                        >
                          E-mail *<br />
                          <input
                            type="email"
                            class="p-2 font-14 font-weight-normal mt-12 add-user-input"
                            placeholder="Enter email"
                            v-model="email"
                          />
                        </div>
                        <div
                          class="col-12"
                          v-if="
                            (first_name == '' ||
                              last_name == '' ||
                              email == '') &&
                            requiredFields
                          "
                          style="padding: 0.3rem 1rem"
                        >
                          <p class="m-0">* Required Fields</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-block d-md-none"
                    style="
                      border-radius: 12px !important;
                      background-color: #151515 !important;
                      max-height: 87vh;
                      overflow-y: scroll;
                    "
                  >
                    <div class="title-bar col-12">
                      <span class="col-2"></span>
                      <h4 class="title-heading kallisto-bold col-8 p-0 m-0">
                        Add user
                      </h4>
                      <button
                        type="button"
                        class="title-close-btn col-2 p-0 d-flex justify-content-end pr-20"
                        data-dismiss="modal"
                      >
                        <img
                          :src="getIcon('x-button.svg')"
                          alt="x-button"
                          class="x-button"
                        />
                      </button>
                    </div>
                    <div
                      class="mt-24 d-block ml-20 mr-20"
                      style="
                        border-bottom: 0.5px solid #ffffff4d !important;
                        background-color: #151515 !important;
                      "
                    >
                      <div
                        class="d-block text-left font-weight-bold font-16"
                        style="color: #d0d0d0"
                      >
                        Select avatar
                      </div>
                      <div
                        class="d-flex justify-content-center align-items-center"
                        style=""
                      >
                        <div
                          style="
                            background: #ffc62766;
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                          "
                          class="justify-content-center align-items-center d-flex pointer"
                          @click="handleSlideClick(-1)"
                        >
                          <img
                            class="my-2"
                            height="15"
                            :src="getIcon('chevron-left-solid.svg')"
                            alt="chevron-left-solid"
                          />
                        </div>
                        <div style="min-width: 200px !important">
                          <carousel
                            class=""
                            v-if="$store.state.refresh"
                            :per-page="1"
                            :mouse-drag="false"
                            :paginationEnabled="false"
                            :navigationEnabled="false"
                            :navigateTo="$store.state.choosedAvatarIndex"
                            style="
                              width: 200px !important;
                              display: inline-block !important;
                            "
                          >
                            <slide
                              v-for="(avatar, c) in $store.state.avatars"
                              :key="c"
                              style="width: 120px !important"
                            >
                              <img
                                :src="avatar.avatar_url"
                                style="
                                  display: inline-block !important;
                                  margin-left: auto !important;
                                  margin-right: auto !important;
                                "
                                height="250"
                                width="120"
                              />
                            </slide>
                          </carousel>
                          <img
                            v-else-if="$store.state.avatars[0]"
                            :src="$store.state.avatars[0].avatar_url"
                            style="
                              display: inline-block !important;
                              margin-left: auto !important;
                              margin-right: auto !important;
                            "
                            height="250"
                            width="120"
                          />
                        </div>
                        <div
                          style="
                            background: #ffc62766;
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                          "
                          class="justify-content-center align-items-center d-flex pointer"
                          @click="handleSlideClick(1)"
                        >
                          <img
                            class="rotate-180 my-2"
                            height="15"
                            :src="getIcon('chevron-left-solid.svg')"
                            alt="chevron-left-solid"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="text-left pl-20 pr-20">
                      <div class="row m-0 font-weight-bold">
                        <div
                          class="col-12 px-0 pb-20 pt-16 font-16"
                          style="
                            border-bottom: 0.5px solid #ffffff4d !important;
                            background-color: #151515 !important;
                          "
                        >
                          First Name *
                          <input
                            type="text"
                            class="p-2 font-14 font-weight-normal mt-12 add-user-input"
                            placeholder="Enter first name"
                            v-model="first_name"
                          />
                        </div>
                        <div
                          class="col-12 px-0 pb-20 pt-16 font-16"
                          style="
                            border-bottom: 0.5px solid #ffffff4d !important;
                            background-color: #151515 !important;
                          "
                        >
                          Last Name *
                          <input
                            type="text"
                            class="p-2 font-14 font-weight-normal mt-12 add-user-input"
                            placeholder="Enter last name"
                            v-model="last_name"
                          />
                        </div>
                        <div
                          class="col-12 px-0 pb-20 pt-16 font-16"
                          style="background-color: #151515 !important"
                        >
                          E-mail *<br />
                          <input
                            type="email"
                            class="p-2 font-14 font-weight-normal mt-12 add-user-input"
                            placeholder="Enter email"
                            v-model="email"
                          />
                        </div>
                        <div
                          class="col-12 px-0 pb-20"
                          v-if="
                            (first_name == '' ||
                              last_name == '' ||
                              email == '') &&
                            requiredFields
                          "
                          style="padding: 0.3rem 1rem"
                        >
                          <p class="m-0">* Required Fields</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="$store.state.view == 'sign'">
                  <h4>Sign in form</h4>
                  <div class="text-left">
                    <p class="sign-in-form">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo duo dolores et ea rebum. Stet clita kasd
                      gubergren, no sea takimata sanctus est Lorem ipsum dolor
                      sit amet. Lorem ipsum dolor sit amet, consetetur
                      sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                      ut labore et dolore magna aliquyam erat, sed diam
                      voluptua. At vero eos et accusam et justo duo dolores et
                      ea rebum. Stet clita kasd gubergren, no sea takimata
                      sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor
                      sit amet, consetetur sadipscing elitr, sed diam nonumy
                      eirmod tempor invidunt ut labore et dolore magna aliquyam
                      erat, sed diam voluptua. At vero eos et accusam et justo
                      duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                      takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                      ipsum dolor sit amet, consetetur sadipscing elitr, sed
                      diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo duo dolores et ea rebum. Stet clita kasd
                      gubergren, no sea takimata sanctus est Lorem ipsum dolor
                      sit amet. Lorem ipsum dolor sit amet, consetetur
                      sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                      ut labore et dolore magna aliquyam erat, sed diam
                      voluptua. At vero eos et accusam et justo duo dolores et
                      ea rebum. Stet clita kasd gubergren, no sea takimata
                      sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor
                      sit amet, consetetur sadipscing elitr, sed diam nonumy
                      eirmod tempor invidunt ut labore et dolore magna aliquyam
                      erat, sed diam voluptua. At vero eos et accusam et justo
                      duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                      takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                      ipsum dolor sit amet, consetetur sadipscing elitr, sed
                      diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo duo dolores et ea rebum. Stet clita kasd
                      gubergren, no sea takimata sanctus est Lorem ipsum dolor
                      sit amet. Lorem ipsum dolor sit amet, consetetur
                      sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                      ut labore et dolore magna aliquyam erat, sed diam
                      voluptua. At vero eos et accusam et justo duo dolores et
                      ea rebum. Stet clita kasd gubergren, no sea takimata
                      sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor
                      sit amet, consetetur sadipscing elitr, sed diam nonumy
                      eirmod tempor invidunt ut labore et dolore magna aliquyam
                      erat, sed diam voluptua. At vero eos et accusam et justo
                      duo dolores et ea rebum. Stet.
                    </p>
                    <p class="d-flex align-items-center">
                      <input type="checkbox" class="col-1" />
                      I am above 13 years of age
                    </p>
                    Sign name
                    <textarea
                      id="signTextArea"
                      name="signTextArea"
                      rows="3"
                      cols="30"
                    >
                    </textarea>
                    <p>
                      <strong>Name:</strong>
                      {{ $store.state.ticket_details.ticket.first_name }}
                    </p>
                    <p>
                      <strong>Display Name:</strong>
                      {{ $store.state.ticket_details.ticket.first_name }}
                      {{ $store.state.ticket_details.ticket.last_name }}
                    </p>
                  </div>
                </template>
                <template v-if="$store.state.view == 'confirm'">
                  <div
                    class="d-none d-md-block"
                    style="
                      border-radius: 12px !important;
                      background-color: #151515 !important;
                    "
                  >
                    <div class="title-bar col-12">
                      <span class="col-2"></span>
                      <h4 class="title-heading kallisto-bold col-8 p-0 m-0">
                        Verify identity
                      </h4>
                      <button
                        type="button"
                        class="title-close-btn col-2 p-0 d-flex justify-content-end pr-20"
                        data-dismiss="modal"
                      >
                        <img
                          :src="getIcon('x-button.svg')"
                          alt="x-button"
                          class="x-button"
                        />
                      </button>
                    </div>
                    <div
                      class="mt-3 d-block ml-20 mr-20"
                      style="background-color: #151515 !important"
                    >
                      <img
                        v-if="$store.state.ticket_details.tickets"
                        :src="$store.state.ticket_details.tickets.avatar_url"
                        height="300"
                      />
                      <img
                        v-else-if="$store.state.selected_avatar_url"
                        :src="$store.state.selected_avatar_url"
                        style="
                          display: block !important;
                          margin-left: auto !important;
                          margin-right: auto !important;
                        "
                        height="250"
                        width="120"
                      />
                      <div class="text-left">
                        <div class="font-14 font-weight-bold">
                          Name:
                          <p class="font-weight-normal font-12">
                            {{ first_name }} {{ last_name }}
                          </p>
                        </div>
                        <div class="font-14 font-weight-bold">
                          E-mail:
                          <p class="font-weight-normal font-12">
                            {{ email }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-block d-md-none">
                    <div class="title-bar col-12">
                      <span class="col-2"></span>
                      <h4 class="title-heading kallisto-bold col-8 p-0 m-0">
                        Verify identity
                      </h4>
                      <button
                        type="button"
                        class="title-close-btn col-2 p-0 d-flex justify-content-end pr-20"
                        data-dismiss="modal"
                      >
                        <img
                          :src="getIcon('x-button.svg')"
                          alt="x-button"
                          class="x-button"
                        />
                      </button>
                    </div>
                    <div
                      class="mt-24 d-block ml-20 mr-20"
                      style="background-color: #151515 !important"
                    >
                      <img
                        v-if="$store.state.ticket_details.tickets"
                        :src="$store.state.ticket_details.tickets.avatar_url"
                        height="300"
                      />
                      <img
                        v-else-if="$store.state.selected_avatar_url"
                        :src="$store.state.selected_avatar_url"
                        style="
                          display: block !important;
                          margin-left: auto !important;
                          margin-right: auto !important;
                        "
                        height="250"
                        width="120"
                      />
                      <div class="text-left mt-3">
                        <div class="font-14 font-weight-bold">
                          Name:
                          <p class="font-weight-normal font-12">
                            {{ first_name }} {{ last_name }}
                          </p>
                        </div>
                        <div class="font-14 font-weight-bold">
                          E-mail:
                          <p class="font-weight-normal font-12">
                            {{ email }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="$store.state.view == 'waiver'">
                  <div class="d-none d-md-block">
                    <div
                      class="mt-3 mx-5 d-block text-left"
                      style="background-color: #151515 !important"
                    >
                      <p class="font-weight-bold font-22 mt-5 mb-12 os-bold">
                        Are you doing this experience as a part of your ASU
                        employment?
                      </p>
                      <p class="font-18">
                        This won't affect your experience, we just need to make
                        sure you sign the correct form.
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-block d-md-none my-4 pt-1"
                    style="background-color: #151515 !important"
                  >
                    <div
                      class="mx-5 d-block text-left"
                      style="background-color: #151515 !important"
                    >
                      <p class="font-weight-bold font-16 mt-3 mb-8 os-semibold">
                        Are you doing this experience as a part of your ASU
                        employment?
                      </p>
                      <p class="font-14">
                        This won't affect your experience, we just need to make
                        sure you sign the right form.
                      </p>
                    </div>
                  </div>
                </template>
                <template v-if="$store.state.view == 'autoVIPCheckIn'">
                  <div class="d-none d-md-block px-4 pt-4">
                    <h4
                      class="font-weight-regular font-18 text-left px-4 pt-4 os-semibold"
                    >
                      Are you sure you want to check-in VIP Guests for the
                      remaining seats?
                    </h4>
                    <p class="font-weight-light font-16 text-left px-4">
                      Note: This action will automatically fill all the
                      unreserved spots.
                    </p>
                  </div>
                  <div class="d-block d-md-none px-3 pt-3">
                    <h4
                      class="font-weight-regular font-16 text-left px-3 pt-3 os-semibold"
                    >
                      Are you sure you want to check-in VIP Guests for the
                      remaining seats?
                    </h4>
                    <p class="font-weight-light font-14 text-left px-3">
                      Note: This action will automatically fill all the
                      unreserved spots.
                    </p>
                  </div>
                </template>
                <template v-if="$store.state.view == 'loadingVIPCheckIn'">
                  <div class="px-3 pt-3">
                    <h4
                      class="font-weight-regular font-16 text-center px-3 pt-3 os-semibold"
                    >
                      Adding VIP Guests
                    </h4>
                    <div
                      class="d-flex justify-content-center mr-5 mr-md-100 ml-5 ml-md-100 mb-32 mt-32"
                    >
                      <lottie-player
                        autoplay
                        loop
                        mode="normal"
                        class=""
                        :src="vip_loading"
                        style="width: 100%; height: 100%"
                      ></lottie-player>
                    </div>
                  </div>
                </template>
              </div>
              <div
                class="col-12 right-row text-left"
                :class="{
                  'col-lg-6 p-lg-auto p-4 pl-5': !isModalCentered(
                    $store.state.view
                  ),
                  'pt-0 pb-3': isModalCentered($store.state.view),
                }"
              >
                <h3
                  v-if="header"
                  class="d-none"
                  :class="{ 'd-md-flex': !isModalCentered($store.state.view) }"
                >
                  {{ header }}
                </h3>
                <div
                  class="mid-section d-none"
                  :class="{ 'd-md-flex': !isModalCentered($store.state.view) }"
                >
                  <span v-if="showErrorMsg.status" class="text-danger font-16">
                    {{ showErrorMsg.text }}</span
                  >
                  <h2 v-if="title" class="d-none d-md-flex">{{ title }}</h2>
                </div>
                <div v-if="enablebtn && $store.state.view == 'scanQR'">
                  <p v-if="enablebtn">Scanning QRcode is successful.</p>
                  <button @click="enablebtn = !enablebtn">
                    click here to scan again.
                  </button>
                </div>
                <div
                  v-if="$store.state.view == 'waiver'"
                  class="d-none d-md-block apply-button-container text-center mb-4"
                >
                  <button
                    class="vip-btn vip-no-button"
                    @click="onButtonClick(true)"
                  >
                    <span>No</span>
                  </button>
                  <button
                    class="vip-btn vip-yes-button"
                    @click="onButtonClick(false)"
                  >
                    <span>Yes</span>
                  </button>
                </div>
                <div
                  v-if="$store.state.view == 'waiver'"
                  class="d-block d-md-none apply-button-container text-center mb-4"
                >
                  <button
                    class="vip-btn vip-no-button"
                    @click="onButtonClick(true)"
                  >
                    <span>No</span>
                  </button>
                  <button
                    class="vip-btn vip-yes-button"
                    @click="onButtonClick(false)"
                  >
                    <span>Yes</span>
                  </button>
                </div>
                <div
                  v-else-if="$store.state.view == 'completeVIPCheckIn'"
                  class="d-flex justify-content-center mb-24"
                >
                  <button
                    class="vip-btn vip-yes-button"
                    @click="nextStep($store.state.view)"
                  >
                    Finish
                  </button>
                </div>
                <div
                  v-else-if="$store.state.view == 'autoVIPCheckIn'"
                  class="d-flex justify-content-center mb-24 mb-md-32"
                >
                  <button
                    type="button"
                    class="vip-btn vip-no-button"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                  <button
                    type="button"
                    class="vip-btn vip-yes-button os-bold"
                    @click="nextStep($store.state.view)"
                  >
                    Yes
                  </button>
                </div>
                <div
                  v-else-if="$store.state.view == 'loadingVIPCheckIn'"
                  class="d-flex justify-content-center mb-24 mb-md-32"
                >
                  <button
                    type="button"
                    class="vip-btn vip-yes-button"
                    data-dismiss="modal"
                    style="margin-bottom: 0px !important"
                  >
                    Cancel
                  </button>
                </div>

                <div
                  v-else-if="enablebtn || $store.state.view == 'profile'"
                  class="apply-button-container"
                >
                  <CustomButton
                    @click.native="nextStep($store.state.view)"
                    class="mt-25 d-none d-md-block apply-button-mobile font-16 font-weight-bold py-1 px-0 my-4"
                    :buttonText="buttonText"
                  />
                  <CustomButton
                    @click.native="nextStep($store.state.view)"
                    class="d-block d-md-none apply-button-mobile font-16 font-weight-bold py-1 px-0 my-4"
                    :buttonText="buttonText"
                  />
                </div>
                <div v-if="$store.state.view == 'scanQR'"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import CustomButton from "../components/Button.vue";
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";
import loading_bar from "../assets/icons/loading_bar.json";
import Loading from "vue-loading-overlay";
import { Carousel, Slide } from "vue-carousel";
import "vue-loading-overlay/dist/vue-loading.css";
import { Auth, API } from "aws-amplify";
import moment from "moment";

export default {
  components: {
    CustomButton,
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
    Loading,
    Carousel,
    Slide,
  },
  data() {
    return {
      vip_loading: JSON.stringify(loading_bar),
      camera: "front",
      noRearCamera: false,
      noFrontCamera: false,
      header: "",
      title: "",
      description: "",
      buttonText: "",
      enablebtn: false,
      enableCamera: true,
      loader: false,
      first_name: "",
      last_name: "",
      email: "",
      country_code: "",
      requiredFields: false,
      userResponse: {},
      showErrorMsg: {
        status: false,
        text: "",
      },
      asu_expierence: false,
      currentAvatar: null,
    };
  },
  methods: {
    switchCamera() {
      switch (this.camera) {
        case "front":
          this.camera = "rear";
          break;
        case "rear":
          this.camera = "front";
          break;
      }
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        const triedFrontCamera = this.camera === "front";
        const triedRearCamera = this.camera === "rear";

        const cameraMissingError = error.name === "OverconstrainedError";

        if (triedRearCamera && cameraMissingError) {
          this.noRearCamera = true;
        }

        if (triedFrontCamera && cameraMissingError) {
          this.noFrontCamera = true;
        }

        console.error(error);
      }
    },
    async waiverSign(adhocUserDetails) {
      this.$store.commit("SET_MYWAIVER", adhocUserDetails);
      await API.post(
        "proxy",
        `/${this.$store.state.selected_site_id}/waiver/prepare/adhoc`,
        adhocUserDetails
      )
        .then(async (res) => {
          if (res.envelopeId) {
            this.$store.commit("setEnvelopeId", res.envelopeId);
            window.location.href = res.redirectUrl;
            setTimeout(() => {
              this.$store.state.view = "profile";
              this.loader = false;
            }, 2000);
          } else if (res.agreementId) {
            this.$store.commit("setAgreementId", res.agreementId);
            window.location.href = res.redirectUrl;
            setTimeout(() => {
              this.$store.state.view = "profile";
              this.loader = false;
            }, 2000);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toasted.show(
            `Error signing the document, please try again after some time`,
            {
              position: "top-right",
              duration: 5000,
            }
          );
        });
    },
    async autoVIPCheckIn(adhocSessionDetails) {
      await API.post(
        "proxy",
        `/${this.$store.state.selected_site_id}/dsl/customer/adhoc/checkin/bulk`,
        adhocSessionDetails
      )
        .then(async (res) => {
          let isUnSuccessful = res.find((response) => {
            return response.find(
              (sessionResponse) => sessionResponse.success != true
            );
          });
          if (isUnSuccessful) {
            throw new Error("Some of the VIP Check-Ins were Unsuccessful");
          } else {
            setTimeout(() => {
              this.$store.state.view = "completeVIPCheckIn";
              this.loader = false;
            }, 2000);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$store.state.view = "completeVIPCheckIn";
          this.showErrorMsg.status = true;
          if (err.response && err.response.data && err.response.data.message) {
            this.showErrorMsg.text = err.response.data.message;
          } else {
            this.showErrorMsg.text = err;
          }
          this.loader = false;
          console.log(this.showErrorMsg);
        });
    },
    handleSlideClick(value) {
      this.SelectedAvatar(this.$store.state.choosedAvatarIndex + value);
    },
    SelectedAvatar(index) {
      if (index < this.$store.state.avatars.length && index > 0) {
        this.currentAvatar = this.$store.state.avatars[index];
        this.$store.state.choosedAvatarIndex = index;
        this.$nextTick(() => {
          this.$store.state.selected_avatar_id = this.currentAvatar.avatar_id;
          this.$store.state.selected_avatar_url = this.currentAvatar.avatar_url;
        });
      }
    },
    onButtonClick(exp) {
      this.asu_expierence = exp;
      this.nextStep(this.$store.state.view);
    },
    getTrimTime(timestamp) {
      return moment(timestamp).format("hh:mm A");
    },
    getTrimDate(timestamp) {
      let temp = timestamp.split("T");
      return moment(temp[0]).format("ddd, MMM D, YYYY");
    },
    pageInfo() {
      if (this.$store.state.view == "scanQR") {
        this.header = "Check-in User";
        this.title = "Scan QR code";
        this.description =
          "QR Code must be well-lit and in accordance with device settings. On mobile devices, set to maximum brightness.";
        this.buttonText = "Send QR code";
      } else if (this.$store.state.view == "verify") {
        this.header = "Check-in User";
        this.title = "Verify Identity";
        this.description =
          "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod";
        this.buttonText = "Confirm Identity";
      } else if (this.$store.state.view == "complete") {
        this.header = "Check-in User";
        this.title = "Check in complete";
        this.description =
          "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod";
        this.buttonText = "Finish";
      } else if (this.$store.state.view == "completeVIPCheckIn") {
        this.header = "Check-in VIP Guests";
        this.title = "Check-in Complete";
        this.description =
          "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod";
        this.buttonText = "Finish";
      } else if (this.$store.state.view == "profile") {
        this.header = "Add user";
        this.title = "Complete your profile";
        this.description =
          "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod";
        this.buttonText = "Confirm profile";
      } else if (this.$store.state.view == "sign") {
        this.header = "Add user";
        this.title = "Sign the form to continue";
        this.description =
          "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod";
        this.buttonText = "Sign the form";
      } else if (this.$store.state.view == "confirm") {
        this.header = "Check-in User";
        this.title = "Verify Identity";
        this.description =
          "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod";
        this.buttonText = "Confirm Identity";
      } else if (this.$store.state.view == "autoVIPCheckIn") {
        this.header = "Check-in VIP Guests";
        this.title = "Automatically Check In VIP Guests";
        this.description =
          "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod";
        this.buttonText = "Check In";
      }
    },
    checkInMore() {
      // check which button the clicked
      if (this.$store.state.clickedBtn == "checkInIser") {
        this.enablebtn = false;
        this.$store.state.view = "scanQR";
      } else {
        this.$store.state.view = "profile";
      }
    },
    async nextStep(view) {
      this.showErrorMsg.status = false;
      if (view == "scanQR") {
        this.$store.state.view = "verify";
      } else if (view == "verify") {
        this.loader = true;
        if (this.$store.state.ticket_details.ticket) {
          let user_details = {
            body: {
              closed_captioning: false,
              language_code: "en", //this.$store.state.ticket_details.tickets.audio_lang_code
              ticket_id: this.$store.state.ticket_details.ticket.ticket_id,
              site_id: "ASU00001",
            },
          };
          await API.post("asulink", `/customer/checkin/complete`, user_details)
            .then(async (response) => {
              this.$store.commit("SET_STUDENT_CHECKIN", response);
              this.loader = false;
              this.$store.state.view = "complete";
            })
            .catch((e) => {
              this.showErrorMsg.status = true;
              this.showErrorMsg.text = e.response.data.message;
              this.loader = false;
            });
        } else {
          this.loader = false;
        }
      } else if (view == "profile") {
        this.requiredFields = true;
        if (this.first_name != "" && this.last_name != "" && this.email != "") {
          this.enablebtn = true;
          this.loader = true;
          let user_details = {
            body: {
              country_code: "en",
              email: this.email,
              first_name: this.first_name,
              last_name: this.last_name,
            },
          };
          try {
            await this.$store
              .dispatch("sendUserDetails", user_details)
              .then(async () => {
                this.$store.state.view = "confirm";
                this.loader = false;
              });
          } catch (e) {
            console.log(e);
            this.loader = false;
          }
          this.requiredFields = false;
        }
      } else if (view == "sign") {
        this.loader = true;
        let user_details = {
          body: {
            country_code: "en",
            email: this.email,
            first_name: this.first_name,
            last_name: this.last_name,
          },
        };
        try {
          await this.$store
            .dispatch("sendUserDetails", user_details)
            .then(async () => {
              this.$store.state.view = "confirm";
              this.loader = false;
            });
        } catch (e) {
          console.log(e);
          this.loader = false;
        }
      } else if (view == "confirm") {
        this.$store.state.view = "waiver";
      } else if (view == "waiver") {
        this.loader = true;
        let adhocUserDetails = {
          body: {
            return_url: window.location.origin + window.location.pathname,
            combined: this.asu_expierence,
            customer_id: this.$store.state.addedUser.customer_id,
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            site_id: this.$store.state.selected_site_id,
          },
        };
        // /waiver
        this.waiverSign(adhocUserDetails);
      } else if (view == "autoVIPCheckIn") {
        this.$store.state.view = "loadingVIPCheckIn";
        this.enablebtn = true;
        let adhocUserDetails = {
          body: [
            {
              session_id: this.$store.state.selected_session_id,
              seats_available: this.$store.state.seats_available,
            },
          ],
        };
        this.autoVIPCheckIn(adhocUserDetails);
      } else if (view == "loadingVIPCheckIn") {
        $("#checkin-modal").modal("hide");
      } else if (view == "complete" || view == "completeVIPCheckIn") {
        this.enablebtn = !this.enablebtn;

        $("#checkin-modal").modal("hide");
        // refresg parent components
        this.$store.state.loader = true;
        this.$store.state.viewFromMenu = "";
        this.api = false;
        this.$nextTick(() => {
          this.$store.state.viewFromMenu = "Pod Schedule";
        });
        let myData = {
          selected_site_id: this.$store.state.selected_site_id,
          podSessionDate: this.$store.state.podSessionDate,
        };
        this.$store.dispatch("setSchedulerSessions", myData);
        this.first_name = "";
        this.last_name = "";
        this.email = "";
      }
    },
    async onDecode(decode) {
      await API.get("asulink", `/customer/ticket/id/ASU00001/${decode}`)
        .then((response) => {
          let now = this;
          this.$store.state.avatars.find((avatarObj) => {
            if (
              avatarObj != undefined &&
              avatarObj.avatar_id === response.ticket.avatar_id
            ) {
              now.$store.state.selected_avatar_url = avatarObj.avatar_url;
            }
          });
          this.$store.commit("SET_ORDER_DETAILS", response);
          this.showErrorMsg.status = false;
          this.showErrorMsg.text = "";
          this.enablebtn = true;
          this.$store.state.view = "verify";
        })
        .catch((e) => {
          this.showErrorMsg.status = true;
          this.showErrorMsg.text = e.response.data.message;
        });
    },
    getIcon(img) {
      return require("@/assets/icons/" + img);
    },
    isModalCentered(view) {
      if (view == "autoVIPCheckIn") return true;
      if (view == "completeVIPCheckIn") return true;
      return true;
    },
    isModalCenteredMobile(view) {
      if (view == "waiver") return true;
      if (view == "autoVIPCheckIn") return true;
      if (view == "completeVIPCheckIn") return true;
      if (view == "scanQR") return true;
      return false;
    },
    isModalVip(view) {
      if (view == "autoVIPCheckIn") return true;
      if (view == "completeVIPCheckIn") return true;
      return false;
    },
    isModalScanqr(view) {
      if (view == "scanQR") return true;
      return false;
    },
  },
  async mounted() {
    if (
      (this.$store.state.envelopeId &&
        window.location.search.includes("event=signing_complete")) ||
      (this.$store.state.agreementId &&
        window.location.search.includes("event=signing_complete") &&
        window.location.search.includes("signing_service=ADOBE_SIGN"))
    ) {
      this.$store.state.view = "complete";
      this.enablebtn = true;
    }
    const vm = this;
    $("#checkin-modal").on("hide.bs.modal", function () {
      vm.enablebtn = false;
      vm.$store.state.view = "scanQR";
      vm.showErrorMsg.status = false;
      vm.enableCamera = false;
    });
    $("#checkin-modal").on("shown.bs.modal", function () {
      vm.enableCamera = true;
    });
  },
};
</script>

<style>
.x-button:hover {
  background: #2c2c2c;
}
.x-button {
  height: 26px;
  background: transparent;
  padding: 6px 7px;
  border-radius: 50%;
}
</style>
<style scoped lang="scss">
@media only screen and (max-width: 767px) {
  .modal {
    width: 100% !important;
  }
  .modal-dialog-mobile {
    margin: 0 !important;
    height: 105% !important;
    border: 0.8px solid #93751f !important;
    border-bottom: none !important;
    border-radius: 12px 12px 0px 0px !important;
  }
  .modal-dialog-centered-mobile {
    align-items: center !important;
    bottom: auto !important;
    position: relative !important;
    margin-bottom: auto !important;
  }
  .modal-content-scanqr {
    border: 1px solid #93751f !important;
    border-radius: 12px 12px 12px 12px !important;
    min-height: 400px;
  }
  .modal-content-vip {
    min-height: 0px !important;
    height: auto !important;
    border: 0.5px solid #93751f !important;
    border-radius: 18px !important;
  }
  .modal-content {
    border-bottom: none;
    border-radius: 12px 12px 0px 0px;
    max-width: 450px !important;
    background-color: #151515 !important;
    height: inherit;
  }

  .left-row {
    padding: 0 !important;
    min-height: auto !important;
  }
  .apply-button-mobile {
    background-color: #ffc627 !important;
    border-radius: 8px !important;
    color: #151515 !important;
    width: 262px !important;
    height: 38px !important;
  }
  .apply-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
  }
  .right-row {
    padding: 0 !important;
  }
  .qrcode-stream-mobile {
    width: 100% !important;
    height: 100% !important;
  }
  .scanQR-mobile {
    max-width: 715px !important;
    max-height: 345px !important;
    height: 50% !important;
    top: 0% !important;
    border: none !important;
    border-radius: 0px !important;
  }
  .scanQR-mobile .check-in {
    min-height: auto !important;
  }
}
input {
  border: 1px solid #fff;
  border-radius: 4px;
  width: 100%;
  color: #fff;
}
.right-row {
  h3 {
    text-align: left;
    font-size: 24px;
    font-weight: 700;
    padding-top: 40.5px;
  }

  .mid-section {
    padding-top: 32px;
    @media screen and (min-width: 992px) {
      padding-top: 150px;
    }
  }
  p {
    font-size: 14px;
  }
}
.left-row {
  background: #484848 !important;
  min-height: 602px;
}
.modal-header {
  border-bottom: none;
}

.modal-content {
  background-color: #151515;
}
.check-in {
  min-height: 515px;
  max-width: 732px;
}

.cyp-para {
  padding: 0px 200px;
  font-size: 14px;
}

.placeholder {
  width: 236px;
  height: 283px;
  background: grey;
}

.title {
  font-size: 38px;
}

.exp {
  font-size: 33px;
}

.pod-sched-header {
  font-size: 29px;
}
.checkin-complete {
  background: #ffc627;
  border-radius: 60px;
  opacity: 0.7;
  padding: 12px;
  height: 48px;
  width: 48px;
}
.sign-in-form {
  overflow: scroll;
  height: 70vh;
}
#signTextArea {
  border: 1px solid #fff;
  border-radius: 12px;
  color: #fff !important;
}
.checkin-btn {
  padding: 17px;
  border-radius: 30px;
}
.vip-btn {
  font-size: 16px !important;
  border-radius: 8px !important;
  background-color: #151515 !important;
  cursor: pointer !important;
  font-weight: bold !important;
  margin: 0.5rem !important;
  padding: 0rem 1rem !important;
  width: 136px !important;
  height: 42px !important;
  @media screen and (max-width: 992px) {
    font-size: 14px !important;
    width: 106px !important;
    height: 35px !important;
  }
}
.vip-no-button {
  border: 0.5px solid #ffffff !important;
  color: #ffffff !important;
}
.vip-yes-button {
  border: 0.5px solid #ffc627 !important;
  color: #151515 !important;
  background-color: #ffc627 !important;
}
.check-in-mobile {
  min-height: fit-content !important;
  max-width: 100% !important;
}
.left-row-mobile {
  background: #151515 !important;
  min-height: fit-content !important;
}
.scanQR-mobile {
  max-width: 513px;
}
.modal-content-scanqr {
  border: 1px solid #93751f !important;
  border-radius: 12px 12px 12px 12px !important;
}
.title-bar {
  height: fit-content !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  background-color: #151515 !important;
  width: 100% !important;
  padding: 22px 0rem 20px 0rem !important;
  border-bottom: 0.8px solid #93751f !important;
  border-radius: 18px 18px 0px 0px !important;
  @media screen and (max-width: 992px) {
    padding: 14px 0rem 12px 0rem !important;
  }
}
.title-heading {
  text-align: center !important;
  margin: 0 auto !important;
  font-size: 16px !important;
  color: #ffc627 !important;
}
.title-close-btn {
  float: right !important;
  background: none !important;
  border: none !important;
  cursor: pointer !important;
  margin-right: 20px !important;
}
.right-row {
  padding: 0 !important;
}
.left-row {
  padding: 0 !important;
  min-height: auto !important;
}
.apply-button-mobile {
  background-color: #ffc627 !important;
  border-radius: 8px !important;
  color: #151515 !important;
  width: 180px;
  height: 42px;
}
.apply-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}
.modal-content-vip {
  min-height: 0px !important;
  height: auto !important;
  border: 0.5px solid #93751f !important;
  border-radius: 18px !important;
}
.add-user-input {
  border: none;
  background-color: #2d2d2d;
  text-align: left;
  padding-left: 0.8rem !important;
}
.add-user-input:focus {
  outline: none;
  background-color: #2d2d2d;
}

.VueCarousel-navigation-button {
  background: #ffc62766 !important;
}

.VueCarousel-navigation-next {
  width: 100px !important;
}
</style>
