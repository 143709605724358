<template>
  <div>
    <div class="d-none d-md-flex">
      <div v-click-outside="hide">
        <span
          class="p-2"
          style="cursor: pointer"
          v-if="cancelSessionStatus && available(session)"
          @click="openCancelBtn = !openCancelBtn"
          ><img src="../assets/icons/dots.svg" alt="close icon"
        /></span>
        <button
          v-if="openCancelBtn"
          @click="CancelSession(session)"
          class="cancel-session px-4 py-3"
        >
          Cancel session
        </button>
      </div>
    </div>
    <div class="d-flex d-md-none">
      <button
        style="cursor: pointer"
        v-if="cancelSessionStatus && available(session)"
        @click="CancelSession(session)"
        class="cancel-session-mobile fontRoboto font-weight-bold font-14 justify-content-center pt-18 pb-18"
      >
        Cancel session
      </button>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import $ from "jquery";
import moment from "moment";
import { API } from "aws-amplify";
export default {
  data() {
    return {
      openCancelBtn: false,
      cancelSessionStatus: true,
    };
  },
  props: {
    session: Object,
  },
  async mounted() {
    await API.get(
      "asulink",
      `/scheduler/session/${this.session.session_id}/orders`
    ).then((orders) => {
      if (orders.length > 0) {
        orders.forEach((order) => {
          if (order.checked_in) {
            this.cancelSessionStatus = false;
          }
        });
      }
    });
  },
  methods: {
    available(session) {
      var today = moment().tz("America/Phoenix").format();
      if (moment(session.start_timestamp).isBefore(moment(today))) {
        return false;
      }
      return true;
    },
    CancelSession(session) {
      this.$store.state.cancelSession = session;
      this.$store.state.CancelReservation = null;
      this.$nextTick(() => {
        $("#cancel-modal").modal();
      });
    },
    hide() {
      this.openCancelBtn = false;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style>
.cancel-session {
  position: absolute;
  left: 50%;
  top: 66%;
  padding: 2px 5px;
  border: none !important;
  background: #151515;
  color: #ffffff;
  z-index: 999;
  border-radius: 7% !important;
  box-shadow: 3px 3px 5px 0px hsl(0deg 0% 50% / 60%);
}
.cancel-session:hover {
  color: #ffc627;
}
.cancel-session-mobile {
  color: #ffc627 !important;
  border: none !important;
  border-radius: 0px !important;
}
</style>