<template>
  <div>
    <section id="landing">
      <div class="bg">
        <div class="container text-white">
          <div class="row">
            <div class="col-12 col-lg-6 my-md-auto left p-lg-0 pt-5">
              <h1 class="text-left mb-0 font-md-48 font-32 font-weight-900">
                Choose your avatar
              </h1>
              <p class="text-left mb-0 font-14 font-md-16">
                Select the avatar that brings out your adventurous side and you
                will be transformed when you arrive in the Alien Zoo.
              </p>
              <button
                type="button"
                class="next-button d-block"
                @click="onButtonClick()"
              >
                <span
                  v-dataanchor
                  tagEvent="link"
                  tagAction="click"
                  tagType="internal link"
                  tagSection="choose your avatar"
                  tagText="next"
                  class="font-weight-bold pr-12"
                  >Next</span
                >
                <img
                  class=""
                  src="../assets/icons/arrow-left-solid.svg"
                  alt="arrow left"
                />
              </button>
            </div>
            <div class="col-12 col-lg-6 my-auto right p-0">
              <chooseAvatar @currentAvatarSelected="currentAvatarSelected" />
              <div
                class="
                  text-white text-left
                  d-md-flex
                  offset-md-4
                  d-none d-lg-block
                "
              >
                <div>
                  <p class="mb-1 font-16 p-0 d-none d-lg-block">Display name</p>
                  <input
                    type="text"
                    class="text-white displayNameTextBox d-none d-lg-block"
                    disabled
                    :value="
                      $store.state.profile.first_name +
                      ' ' +
                      $store.state.profile.last_name
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import CustomButton from "./Button.vue";
import chooseAvatar from "./chooseAvatar.vue";
import { API, Auth } from "aws-amplify";
import {
  dataLayerAnchorTag,
  dataLayerButtonTag,
  dataLayerSelectTag,
  dataLayerInputTag,
} from "data-layer-test-oo-gtm";

export default {
  directives: {
    dataanchor: dataLayerAnchorTag,
    databutton: dataLayerButtonTag,
    "data-select": dataLayerSelectTag,
    "data-input": dataLayerInputTag,
  },
  components: {
    CustomButton,
    chooseAvatar,
  },
  data() {
    return {
      currentAvatar: null,
    };
  },

  methods: {
    onButtonClick() {
      if (
        this.$store.state.view == "avatar" &&
        this.$store.state.waivers.length == 0
      ) {
        this.$store.state.component = "waiver";
      } else if (this.$store.state.view == "avatar") {
        this.$store.state.component = "landing";
        this.$store.state.view = "pods";
        this.$store.state.selected_avatar_id = this.currentAvatar.avatar_id;
        this.$store.state.selected_avatar_url = this.currentAvatar.avatar_url;
        this.$store.state.avatars.find((avatarObj, index) => {
          if (
            avatarObj != undefined &&
            avatarObj.avatar_id === this.currentAvatar.avatar_id
          ) {
            this.$store.state.choosedAvatarIndex = index;
          }
        });
        this.$store.state.expirenceTIckets.forEach((element) => {
          let tempdata = {
            body: {
              avatar_id: this.$store.state.selected_avatar_id,
            },
          };
          API.post("asulink", `/account/avatar/${element.title_id}`, tempdata);
        });
      }
    },
    currentAvatarSelected(avatar) {
      this.currentAvatar = avatar;
    },
  },
  mounted() {
    this.currentAvatar = this.$store.state.avatars[0];
  },
};
</script>
<style scoped lang="scss">
#landing {
  .carousel-3d-container {
    height: 400px !important;
  }
  height: 100%;
  .row {
    min-height: 100vh;
  }

  h1 {
    letter-spacing: -0.24px;
  }

  p {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

.button-next {
  display: block;
}

@media only screen and (max-width: 992px) {
  .left {
    order: 2;
    margin-top: -80px !important;
  }
  .right {
    order: 1;
  }

  .button-next {
    display: inline;
  }
}
.displayNameTextBox {
  padding: 12px 16px;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
}
</style>
