<template>
  <div class="">
    <div class="border-layout mt-2 mt-lg-0 p-4">
      <div class="d-flex justify-content-between mb-4">
        <h3 class="font-weight-bold font-18 mb-0">
          Your reservations ({{ currentCounter }} / {{ maxCounter }})
        </h3>
        <div></div>
      </div>
      <div class="row align-items-center">
        <div class="col-4">
          <qrcode-vue
            :value="compData.ticket_transaction.ticket_id"
            :background="'#000000'"
            :foreground="'#ffffff'"
            :size="size"
            level="H"
          />
          <div>
            <a
              v-dataanchor
              tagEvent="modal"
              tagAction="open"
              tagType="click"
              tagSection="your reservation"
              tagRegion="main content"
              tagText="click to expand"
              tagName="onclick"
              href="javascript:void(0)"
              class="text-brand-gold underline-text font-10"
              type="button"
              data-toggle="modal"
              data-target="#expand-modal"
              @click="activeOrderID(compData.ticket_transaction.ticket_id)"
              >Click to expand</a
            >
          </div>
        </div>
        <div class="col-8">
          <p class="m-0 font-weight-bold font-16">Order ID</p>
          <p class="text-brand-gold font-weight-bold font-14">
            {{ compData.ticket_transaction.ticket_id }}
          </p>
        </div>
      </div>
      <hr class="my-4" />
      <div class="reservation-container">
        <h3 class="font-weight-bold mb-0">
          {{ compData.session.title_shortname }}
        </h3>
        <div class="reservation-details mb-3 font-18">
          <span class="reservation-time">
            {{ getTrimTime(compData.session.start_timestamp) }}</span
          >
          <span class="reservation-date">
            | {{ getTrimDate(compData.session.start_timestamp) }}</span
          >
        </div>
        <div class="reservation-location">
          <p class="font-16">
            Location :
            <a
              v-dataanchor
              tagEvent="link"
              tagAction="click"
              tagType="external link"
              tagSection="your reservation"
              tagRegion="main content"
              :tagText="compData.session.site_id"
              tagName="onclick"
              href="https://goo.gl/maps/WeifUm8sjyV24X9c6"
              target="_blank"
              class="text-brand-gold underline-text font-weight-bold"
              >{{ compData.session.site_id }}</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import QrcodeVue from "qrcode.vue";

import {
  dataLayerAnchorTag,
  dataLayerButtonTag,
  dataLayerSelectTag,
  dataLayerInputTag,
} from "data-layer-test-oo-gtm";

export default {
  directives: {
    dataanchor: dataLayerAnchorTag,
    databutton: dataLayerButtonTag,
    "data-select": dataLayerSelectTag,
    "data-input": dataLayerInputTag,
  },
  components: {
    QrcodeVue,
  },
  data() {
    return {
      size: 80,
      activeId: 123,
    };
  },
  props: {
    currentCounter: Number,
    maxCounter: Number,
    compData: Object,
  },
  methods: {
    getTrimTime(timestamp) {
      return moment(timestamp).format("hh:mm A");
    },
    getTrimDate(timestamp) {
      return moment(timestamp).format("ddd, MMM D, YYYY");
    },
    activeOrderID(activeOrder) {
      this.$emit("activeOrder", activeOrder);
    },
  },
};
</script>
