var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"modal",attrs:{"id":"choose-your-experience","data-backdrop":"exampleModalLabel","data-keyboard":"false","tabindex":"-1","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-body"},[_c('section',{staticClass:"py-3",attrs:{"id":"infoa"}},[_c('a',{directives:[{name:"dataanchor",rawName:"v-dataanchor"}],staticClass:"close",attrs:{"tagEvent":"modal","tagAction":"close","tagType":"click","tagSection":"choose your experience","tagText":"close cross","type":"button","data-dismiss":"modal","href":"javascript:void(0)"},on:{"click":function($event){_vm.remoteExpErr = null}}},[_c('img',{attrs:{"src":require("../assets/icons/times-solid.svg"),"alt":"times solid"}})]),_c('div',{staticClass:"container text-white"},[_vm._m(0),_c('div',{class:_vm.enableTwoD() ? 'row' : ''},[_c('a',{directives:[{name:"dataanchor",rawName:"v-dataanchor"}],staticClass:"col-12 col-lg-6 align-self-center",attrs:{"tagEvent":"modal","tagAction":"open","tagType":"click","tagSection":"choose your experience","tagText":"pod experience","href":"javascript:void(0)"}},[_c('img',{staticClass:"pod-image",staticStyle:{"cursor":"pointer"},attrs:{"src":require("../assets/images/pod-experience.png"),"alt":"pod image","data-toggle":"modal","data-target":"#podSession","data-dismiss":"modal"}}),_c('h3',{staticClass:"text-center exp font-18 font-md-27",attrs:{"data-toggle":"modal","data-target":"#podSession","data-dismiss":"modal"}},[_vm._v(" Pod Experience ")])]),(
                    this.$store.state.scheduler_pods &&
                    this.$store.state.scheduler_pods[0] &&
                    this.$store.state.scheduler_pods[0]['2d'] &&
                    _vm.enableTwoD()
                  )?_c('a',{directives:[{name:"dataanchor",rawName:"v-dataanchor"}],staticClass:"col-12 col-lg-6 justify-content-center",attrs:{"tagEvent":"link","tagAction":"click","tagType":"internal link","tagSection":"choose your experience","tagText":"anytime, anywhere","href":"javascript:void(0)"}},[_c('img',{staticClass:"pod-image",staticStyle:{"cursor":"pointer"},attrs:{"src":require("../assets/images/anywhere-anytime.png"),"alt":"pod image"},on:{"click":function($event){return _vm.remoteInit()}}}),_c('h3',{staticClass:"text-center exp font-18 font-md-27",on:{"click":function($event){return _vm.remoteInit()}}},[_vm._v(" Anytime, anywhere ")])]):_vm._e()]),(_vm.remoteExpErr && _vm.enableTwoD())?_c('p',{staticClass:"text-danger d-inline-block mt-3 mb-0"},[_vm._v(" "+_vm._s(_vm.remoteExpErr)+" ")]):_vm._e()])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center"},[_c('h2',{staticClass:"text-white title font-24 font-md-38"},[_vm._v(" Choose your experience ")]),_c('p',{staticClass:"text-white description"},[_vm._v(" Dreamscape Learn can be completed as a multi-sensory experience on the ASU Tempe campus in the Dreamscape Studio pods. Alternatively, the experience can be explored anytime, anywhere using your computer or PC-powered VR Headset. ")])])
}]

export { render, staticRenderFns }