<template>
  <footer>
    <section class="bg-brand-gold pt-16 pb-16 pt-lg-24 pb-lg-24">
      <div class="container" style="max-width: 1366px">
        <div class="row px-3">
          <div class="col-md-12 offset-lg-2 col-lg-7 p-0">
            <a
              rel="noopener"
              class="d-block font-18 text-dark text-left text-lg-right"
              href="https://www.asu.edu/rankings"
              target="_blank"
              style="font-weight: 900"
              >ASU is No.#1 in the U.S. for innovation</a
            >
          </div>
          <div class="col-lg-2 d-none d-lg-block position-relative">
            <a
              href="https://www.asu.edu/rankings"
              rel="noopener"
              target="_blank"
            >
              <img
                src="https://www.asu.edu/asuthemes/4.8/assets/ASU-number-1-innovation-best-colleges.png"
                alt="Best Colleges U.S. News Most Innovative 2020"
                class="ranking-image"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="pt-24 pb-24 bg-light">
      <div class="container-lg">
        <div class="row no-gutters text-left text-lg-center">
          <div class="col">
            <ul class="nav flex-column flex-md-row">
              <li
                v-for="(item, index) in secondaryMenuItems"
                :key="index"
                class="nav-item py-2 py-lg-0 px-lg-3 pr-md-2"
              >
                <a
                  rel="noopener"
                  target="_blank"
                  :href="item.link"
                  :title="item.text.content"
                  class="nav-link text-dark sm-menu-item pt-0"
                  >{{ item.text.content }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
export default {
  name: "SectionFooter",
  data() {
    return {
      secondaryMenuItems: [
        {
          link: "https://www.asu.edu/copyright/",
          text: {
            content: "Copyright and Trademark",
            color: "black",
            weight: "normal",
          },
        },
        {
          link: "https://www.asu.edu/accessibility/",
          text: {
            content: "Accessibility",
            color: "black",
            weight: "normal",
          },
        },
        {
          link: "https://www.asu.edu/privacy/",
          text: {
            content: "Privacy",
            color: "black",
            weight: "normal",
          },
        },
        {
          link: "https://www.asu.edu/tou/",
          text: {
            content: "Terms of Use",
            color: "black",
            weight: "normal",
          },
        },
        {
          link: "https://www.asu.edu/asujobs",
          text: {
            content: "Jobs",
            color: "black",
            weight: "normal",
          },
        },
        {
          link: "https://www.asu.edu/emergency/",
          text: {
            content: "Emergency",
            color: "black",
            weight: "normal",
          },
        },
        {
          link: "https://www.asu.edu/contactasu/",
          text: {
            content: "Contact ASU",
            color: "black",
            weight: "normal",
          },
        },
      ],
    };
  },
};
</script>

<style scoped>
.ranking-image {
  height: 120px;
  position: absolute;
  top: -2.8rem;
}
a.sm-menu-item {
  display: inline-block;
  padding-bottom: 2px;
  padding-left: 2px;
  padding-right: 2px;
}
a.sm-menu-item:hover {
  border-bottom: 1px dotted black;
}
</style>
