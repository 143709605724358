<template>
  <div id="tree" class="pl-lg-2">
    <div id="accordion" class="accordion">
      <div class="tree">
        <div
          class="accordian-title card-header d-flex justify-content-between align-items-center"
          data-toggle="collapse"
        >
          <div class="tree-first-last" style="width: 100%">
            <span
              class="mt-2 d-none d-md-block"
              style="float: left; position: relative; left: -16.1%"
            >
              <a
                href="javascript:void(0)"
                data-toggle="tooltip"
                title="In progress"
              >
                <img
                  :src="getIcon('ellipsis-h-solid.svg')"
                  class="d-md-block status-icon"
                  alt="ellipsis-solid"
                />
              </a>
              <div class="info-icon-hover-content">
                <p class="m-0 font10"></p>
              </div>
            </span>
            <div>
              <div class="f-title"></div>
              <div class="f-desc"></div>
            </div>
          </div>
        </div>

        <div>
          <div v-for="(expierence, i) in $store.state.titles" :key="i">
            <div
              class="position-absolute"
              v-if="getExpierence(expierence.title_id)"
            ></div>
            <div v-if="!(i == $store.state.titles.length - 1)">
              <div class="pl-md-5" style="border-left: 1px #cccccc solid"></div>
              <div class="pb-3 div-test d-flex align-items-center">
                <template>
                  <span class="ie-flex d-none d-lg-flex">
                    <a
                      href="javascript:void(0)"
                      data-toggle="tooltip"
                      title="In progress"
                    >
                      <img
                        :src="getIcon('open.svg')"
                        class="p-0 d-md-block status-icon"
                        alt="openIcon"
                      />
                    </a>
                  </span>

                  <div class="w-100" style="margin-right: -4%">
                    <div
                      class="p-4 mx-0 mb-3 row f-row moduleBreakDownBg"
                      style
                    >
                      <div class="w-100"></div>
                      <div class="p-2 col-md-8 col-sm-12">
                        <div class="course-title d-flex">
                          <img
                            :src="getIcon('open.svg')"
                            class="p-0 d-lg-none status-icon"
                            alt="openIcon"
                          />
                          <h4
                            class="pr-3 mb-0 text-center font18 font-weight-900 text-lg-left w-100 pr-lg-0"
                          >
                            {{ expierence.shortname }}
                          </h4>
                        </div>
                        <div
                          class="px-0 py-2 course-properties flex-list col-12"
                        >
                          <div
                            class="flex-wrap px-4 mt-4 mb-4 font12 d-flex mb-lg-0 px-lg-0"
                          >
                            <div
                              class="p-0 text-center text-capitalize col-3 col-md-2"
                            >
                              open
                            </div>
                            <div
                              class="p-0 text-center br-1 bl-1 col-4 col-md-3"
                            >
                              15mins
                            </div>
                            <div
                              class="p-0 text-center col-5 col-md-4 pl-lg-2 p-xl-0"
                            >
                              <a
                                v-dataanchor
                                tagEvent="modal"
                                tagAction="open"
                                tagType="click"
                                tagText="view details"
                                :tagSection="expierence.shortname"
                                tagComponent="bio 181"
                                data-toggle="modal"
                                data-target="#openModal"
                                @click="expierenceDetail(expierence)"
                                href="javascript:void(0)"
                                class="Highlighted_url underline-text"
                                >View details</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="course-button d-flex justify-content-center align-self-center col-md-4"
                      ></div>
                    </div>
                  </div>
                </template>
              </div>
            </div>

            <!-- last tree child -->
            <child-last
              v-else
              :expierence="expierence"
              v-on:expierenceDetail="expierenceDetail"
            ></child-last>
          </div>
        </div>
      </div>
    </div>
    <warnings />
  </div>
</template>
<script>
import childLast from "./lastChild";
import getExpierence from "../mixins/getSessionMixin";
import {
  dataLayerAnchorTag,
  dataLayerButtonTag,
  dataLayerSelectTag,
  dataLayerInputTag,
} from "data-layer-test-oo-gtm";
export default {
  directives: {
    dataanchor: dataLayerAnchorTag,
    databutton: dataLayerButtonTag,
    "data-select": dataLayerSelectTag,
    "data-input": dataLayerInputTag,
  },
  name: "tree",
  mixins: [getExpierence],
  components: {
    childLast,
  },
  props: {
    data: Object,
  },
  methods: {
    getIcon(img) {
      return require("@/assets/icons/" + img);
    },
    convertToDate(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    },
    expierenceDetail(expierence) {
      this.$store.state.selected_title_id = expierence.title_id;
      this.$store.state.selected_title = expierence.shortname;
      this.$store.state.podSessionDate = this.convertToDate(new Date());
      this.$store.dispatch("setExpierenceAvailability", {
        site_id: this.$store.state.selected_site_id,
        title_id: expierence.title_id,
        start_date: this.$store.state.podSessionDate,
        end_date: "",
      });
    },
  },
};
</script>
<style scoped lang="scss">
a {
  color: #ffc627;
}
button,
.button {
  border: 1px solid #ffc627 !important;
  color: #ffc627 !important;
  font-size: 16px !important;
  border-radius: 30px !important;
  background-color: #151515;
  padding: 5px 15px;
}
</style>

<style lang="scss">
.transperentBtn {
  background-color: Transparent !important;
  background: transparent;
  background-repeat: no-repeat;
  font-weight: 900;
  @media only screen and (min-width: 992px) {
    min-width: 180px;
  }
}
#tree {
  button,
  .button {
    border: 1px solid;
    border-radius: 8px;
  }
}
.status-icon {
  background: white;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  padding: 4px;
}
.bl-1 {
  border-left: 1px solid #ccc;
}
.br-1 {
  border-right: 1px solid #ccc;
}
.tree .card-header {
  border-top: 0px solid rgba(0, 0, 0, 0.125) !important;
}
/* line css on IE and safari */
@media screen and (-ms-high-contrast: active),
  (-ms-high-contrast: none),
  (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) {
  .ie-flex {
    display: flex !important;
  }
}

@media screen and (min-width: 768px) {
  .suiteModallink {
    padding-left: 17.8% !important;
    border-left: #cccccc solid 1px;
  }
}
.suiteModallink {
  padding-left: 15px;
}

.div-test-last::before,
.loop-element::before {
  display: block;
  height: 50%;
  top: 0px;
  content: " ";
  border-left: 1px #cccccc solid;
  position: absolute;
}

.first::before {
  top: 50% !important;
}

.tree-first-last::before {
  border-left: 1px solid #cccccc;
  left: -2.9%;
  content: "";
  bottom: 50px;
  height: 80%;
  top: 29px;
  width: 1px;
  position: absolute;
}

.tree-first-last::after {
  border-top: 0px solid #cccccc;
  content: "";
  height: 20px;
  top: 107px;
  width: 77px;
}

.tree .tree-first-last {
  list-style-type: none;
  margin: 0;
  padding: 10px 5px 0 5px;
  position: relative;
  padding-left: 10%;
  padding-bottom: 2%;
}

#tree {
  padding-bottom: 64px;
}

.outline-tree:last-child {
  .tree-head::before {
    border-left: 1px solid #cccccc;
    left: -0.9%;
    content: "";
    height: 0px;
    top: 0px;
    position: relative;
  }
}

.div-test {
  border-left: 1px solid #cccccc;
  span {
    overflow: hidden;
    text-align: center;
  }
  span {
    display: contents;
    align-self: center;
  }
  span::before,
  span::after {
    background-color: #cccccc;
    display: inline-block;
    content: "";
    height: 1px;
    width: 50px;
    align-self: center;
  }
}

.div-test-last {
  position: relative;
  span {
    display: contents;
    align-self: center;
  }
  span:before,
  span:after {
    background-color: #cccccc;
    content: "";
    height: 1px;
    width: 45px;
    align-self: center;
  }
}

.subsection {
  span:before,
  span:after {
    background-color: #cccccc;
    content: "";
    height: 1px;
    width: 30px;
    align-self: center;
  }
}

.child-split::before {
  border-left: 1px solid #cccccc;
  left: 24%;
  content: "";
  bottom: 50px;
  height: 100%;
  top: 0px;
  width: 1px;
  position: absolute;
}

.child-split-courses::before {
  border-left: 1px solid #cccccc;
  left: 24%;
  content: "";
  bottom: 50px;
  height: 50%;
  top: 0px;
  width: 1px;
  position: absolute;
}

.child-split::after,
.child-split-courses::after {
  border-bottom: 1px solid #cccccc;
  content: "";
  width: 50%;
  top: 50%;
  position: absolute;
}

.div-test-middle::before {
  display: block;
  height: 100%;
  align-items: center;
  top: 0%;
  content: " ";
  border-left: 1px #cccccc solid;
  position: absolute;
}

.div-test-middle {
  position: relative;
  span {
    display: contents;
    align-self: center;
  }
  span:before,
  span:after {
    background-color: #cccccc;
    content: "";
    height: 1px;
    width: 30px;
    align-self: center;
  }
}
.tree-section-card {
  margin-left: 3% !important;
  margin-right: -3%;
}
@media screen and (max-width: 767px) {
  .primary-overlay {
    padding: 32px 17px !important;
  }
  .course-button {
    @media screen and (max-width: 991px) {
      width: 100%;
      margin: 0px;
      display: flex;
      justify-content: center;
    }
    margin-left: 15px;
  }
  .user-div {
    max-width: 100%;
    .erp {
      font-size: 24px;
      padding: 0px 30px 24px;
    }
    .user-name {
      text-align: left;
      padding: 0 15px;
    }

    .Feature {
      font-size: 20px;
    }
  }
  .choose-number-courses {
    border-left: 0px !important;
    padding-left: 0px !important;
  }

  .div-test-last::before,
  .loop-element::before {
    height: 0%;
    top: 0px;
  }

  .tree-section-card {
    padding-left: 35px !important;
    margin-left: 2%;
    margin-right: 4.5%;
  }

  .first::before {
    top: 0% !important;
  }

  .collapsed {
    .tree-first-last::before {
      height: 0%;
      top: 0px;
      width: 0px;
    }
  }

  .tree-first-last::before {
    height: 0%;
    top: 0px;
    width: 0px;
  }

  .tree-first-last::after {
    height: 0px;
    top: 0px;
    width: 0px;
  }

  .tree-first-last {
    padding-left: 0px !important;
    h1 {
      padding-left: 0px !important;
    }
  }

  .accordian-title {
    padding-left: 0px !important;
    padding-right: 0px;
  }

  .div-test {
    border-left: 0px !important;
    span::before,
    span::after {
      height: 0px;
      width: 0px;
    }
  }

  .div-test-last {
    .lastchild-card {
      width: 106%;
      margin-right: -6.3% !important;
      @media screen and (max-width: 992px) {
      }
    }
    span:before,
    span:after {
      height: 0px;
      width: 0px;
    }
  }

  @supports (-ms-ime-align: auto) {
    .ie-flex {
      display: flex;
    }
    .div-test-last {
      span:before,
      span:after {
        width: 0px;
      }
    }
  }

  .subsection {
    span:before,
    span:after {
      height: 0px;
      width: 0px;
    }
  }

  .child-split::before {
    height: 0%;
    top: 0px;
    width: 0px;
  }

  .child-split-courses::before {
    height: 0%;
    width: 0px;
  }

  .child-split::after,
  .child-split-courses::after {
    width: 0%;
    top: 0%;
  }

  .div-test-middle::before {
    height: 0%;
  }

  .div-test-middle {
    span:before,
    span:after {
      height: 0px;
      width: 0px;
    }
  }
}
.moduleBreakDownBg {
  background: #242424;
  @media screen and (min-width: 992px) {
    width: 95%;
  }
  width: 100%;
  padding-top: 32px;
  border-top: 8px solid darkgray;
}
</style>
