import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Dashboard from "../views/dashboard.vue";
import Appointments from "../components/Appointments.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/portal/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/portal",
    name: "Portal",
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (portal.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Portal.vue"),
  },
  {
    path: "/portal/appointments",
    name: "Appointments",
    component: Appointments,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
