<template>
  <div
    class="modal fade px-0"
    id="createATemplate"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
  >
    <div
      class="modal-dialog modal-dialog modal-md br-18 b-gold mx-0 mx-md-auto"
      style="
        border: 0.5px solid #0b5786 !important;
        box-shadow: 0px 3px 14px 0px rgba(184, 236, 255, 0.15);
      "
      role="document"
    >
      <div class="modal-content animate-bottom br-18">
        <div
          class="modal-header p-0 d-flex align-items-center"
          style="border-bottom: 0.5px solid #707070 !important; opacity: 0.598"
        >
          <span class="col-2"></span>
          <h5
            class="font-md-18 font-16 px-0 pb-12p pb-md-20 pt-md-22 pt-14p text-white col-8 text-center kallisto-bold"
            id="exampleModalLongTitle"
          >
            Create templates
          </h5>
          <button
            type="button"
            class="close m-0 col-2 pr-20 d-flex justify-content-end"
            data-dismiss="modal"
            @click="emptyModalData()"
          >
            <img
              :src="getIcon('x-button.svg')"
              alt="x-button"
              class="x-button"
            />
          </button>
        </div>
        <div class="modal-body text-left os-regular px-0 pt-24">
          <div class="row px-6 mx-0 mt-0">
            <div class="col-lg-12 d-flex px-0 pt-0 pb-10p">
              <label class="font-18 font-md-16 mb-0"> Template name </label>
            </div>
            <div class="col-lg-12 px-0 py-0 mb-2 border-bottom-white">
              <div class="form-group">
                <input
                  v-model="templateName"
                  type="text"
                  name="name"
                  id="name-c"
                  class="form-control text-white text-left font-md-14"
                  placeholder="Enter a template name"
                  aria-label="name field"
                />
              </div>
              <span
                v-if="this.errors[0].createTemplateNameError"
                class="text-danger"
                >{{ this.errors[0].createTemplateNameError }}</span
              >
            </div>
          </div>
          <div class="row px-6 mx-0 mt-0" id="selectShowType">
            <div class="col-lg-12 d-flex px-0 pt-4 pb-20">
              <label class="font-18 font-md-16 font-weight-bold mb-0"
                >Show type
              </label>
            </div>
            <div class="col-md-12 px-0 pt-0 border-bottom-white">
              <div id="selectShowType">
                <select
                  name="LeaveType"
                  v-model="availability"
                  aria-placeholder="Select"
                  class="form-control choose-an-act text-left font-weight-bold font-16 font-md-14"
                >
                  <option value="" selected disabled>Select show type</option>
                  <option class="ml-3 text-left" value="public">Public</option>
                  <option class="ml-3 text-left" value="private">
                    Private
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row mb-46 mt-4 px-6 mx-0">
            <div class="col-12 pb-2 pl-0 pr-0 d-flex justify-content-between">
              <label class="font-18 font-md-16 font-weight-bold mb-2"
                >Time Slots</label
              >
              <div>
                <button
                  class="timeSlotsActionRemoveStyle px-4 py-1"
                  @click="timeSlotsActionRemove()"
                >
                  <img
                    :src="getIcon('filter-collapse-minus-mobile.svg')"
                    alt="filter-collapse-minus-mobile"
                    class="filter-collapse-minus-mobile"
                  />
                </button>

                <span class="timeSlotsStyle py-1 px-3">{{
                  timeSlotsCount
                }}</span>
                <button
                  class="timeSlotsActionAddStyle px-4 py-1"
                  @click="timeSlotsActionAdd()"
                >
                  <img
                    :src="getIcon('filter-collapse-plus-mobile.svg')"
                    alt="filter-collapse-plus-mobile"
                    class="filter-collapse-plus-mobile"
                  />
                </button>
              </div>
            </div>
            <div class="col-12 px-0 py-0 pb-4 mb-2">
              <div class="row time-slot-row">
                <div
                  v-for="(ts, i) in TimeSlots"
                  :key="i"
                  class="col-4 d-flex align-items-top time-slot-col"
                >
                  <input
                    v-model="ts.timeSlot"
                    type="text"
                    class="form-control time-slots text-light font-md-14"
                    placeholder="HH:MM"
                    v-mask="timeMask"
                    aria-label="time slot"
                  />
                </div>
              </div>
              <span
                v-if="this.errors[0].createTemplateTimeSlotsError"
                class="text-danger mt-5 d-inline-block"
                >{{ this.errors[0].createTemplateTimeSlotsError }}</span
              >
              <span
                v-if="this.errors[0].createTemplateTimeSlotsLengthError"
                class="text-danger mt-5 d-inline-block"
                >{{ this.errors[0].createTemplateTimeSlotsLengthError }}</span
              >
            </div>
          </div>

          <div class="d-flex justify-content-center mb-32">
            <button type="button" class="apply-button" @click="addATemplate()">
              <span class="font-weight-bold apply">Create</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  components: {},
  data: () => ({
    //Create Template Modal Data *********************
    createStartDate: "",
    createStartTime: "",
    value: "",
    createName: "",

    availability: "public",
    TimeSlots: [
      {
        timeSlot: "",
      },
    ],
    templateName: "",
    isPublic: "",

    errors: [
      {
        createTemplateNameError: "",
        createTemplateAvailabilityError: "",
        createTemplateTimeSlotsError: "",
        createTemplateTimeSlotsLengthError: "",
      },
    ],
  }),

  methods: {
    timeSlotsActionAdd() {
      this.TimeSlots.push({
        timeSlot: "",
      });
    },
    timeSlotsActionRemove() {
      if (this.TimeSlots.length > 1) {
        this.TimeSlots.pop();
      }
    },

    getIcon(img) {
      return require("@/assets/icons/" + img);
    },
    timeSlotsLengthError() {
      var errorMessage = "";
      this.TimeSlots.forEach((ts) => {
        if (ts.timeSlot != "") {
          if (ts.timeSlot.length != 5) {
            errorMessage = "The time slot format has to be HH:MM";
          }
        }
      });
      this.errors[0].createTemplateTimeSlotsLengthError = errorMessage;
    },
    timeMask(value) {
      const hours = [/[0-2]/, value.charAt(0) === "2" ? /[0-3]/ : /[0-9]/];
      const minutes = [/[0-5]/, /[0-9]/];
      return value.length > 2 ? [...hours, ":", ...minutes] : hours;
    },
    onChangeAvailabilty(event) {
      this.availability = event.target.value;
    },
    async addATemplate() {
      await this.timeSlotsLengthError();

      if (this.templateName === "") {
        this.errors[0].createTemplateNameError =
          "Template name cannot be empty.";
      } else {
        this.errors[0].createTemplateNameError = "";
      }

      var timeSlotsToPost = [];

      this.TimeSlots.forEach((ts) => {
        if (ts.timeSlot != "") {
          var timeSplit = ts.timeSlot.toString().split(":");
          var timeSlotToPost = timeSplit[0] + timeSplit[1];
          timeSlotsToPost.push(parseInt(timeSlotToPost));
        }
      });
      if (timeSlotsToPost.length == 0) {
        this.errors[0].createTemplateTimeSlotsError =
          "Please fill in at least one time slot.";
      } else {
        this.errors[0].createTemplateTimeSlotsError = "";
      }
      if (
        this.errors[0].createTemplateTimeSlotsError != "" ||
        this.errors[0].createTemplateAvailabilityError != "" ||
        this.errors[0].createTemplateNameError != "" ||
        this.errors[0].createTemplateTimeSlotsLengthError != ""
      ) {
        return -1;
      } else {
        $("#createATemplate").modal("hide");
        var data = {
          body: {
            schedule_name: this.templateName,
            public: this.availability == "private" ? false : true, // showtype = 1600 (public false would yield 1620)
            local_times: timeSlotsToPost,
          },
        };

        await this.$store
          .dispatch("createTemplate", data)
          .then(async (response) => {
            this.emptyModalData();
            if (response.status >= 200 && response.status <= 299) {
              $("#template-create-sucess").modal();
              this.emptyModalData();
            } else {
              this.$toasted.show(`Error : ${response.data.message}`, {
                position: "top-right",
                duration: 5000,
              });
              this.emptyModalData();
            }
            await this.$store.dispatch("setListOfTemplates").then(async () => {
              var data = [];
              this.$store.state.templates.forEach((ts) => {
                var template = ts;
                template["checked"] = false;
                data.push(template);
              });
              this.$store.state.batchTemplateMultiSelect = data;
            });
          });
      }
    },
    emptyModalData() {
      this.templateName = "";
      this.availability = "public";
      this.TimeSlots.forEach((ts) => {
        ts.timeSlot = "";
      });
      this.TimeSlots = [
        {
          timeSlot: "",
        },
      ];
    },
  },
  computed: {
    timeSlotsCount() {
      return this.TimeSlots.length;
    },
  },
};
</script>

<style lang="scss">
.timeSlotsActionAddStyle {
  border-radius: 0px 4px 4px 0px;
  background: rgba(14, 79, 116, 0.4);
}
.timeSlotsActionRemoveStyle {
  border-radius: 4px 0px 0px 4px;
  background: rgba(14, 79, 116, 0.4);
}
.modal-dialog {
  margin-top: 90px !important;
}
.form-control {
  width: 100% !important;
}

.choose-an-act {
  height: 53px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  text-align: center;
}

select:focus {
  background-color: #151515;
}
select {
  background-color: #151515;
}
.border-bottom-white {
  border-bottom: 1px solid #505050;
}

.timeSlotsStyle {
  background-color: rgba(14, 79, 116, 0.2);
}
#createATemplate {
  .modal-content {
    background-color: #151515;
  }
  input {
    height: 53px;
    color: white;
    border: none;
    background-color: #2d2d2d !important;
  }
  #selectShowType {
    select {
      height: 53px;
      color: white;
      border: none;
      background-color: #2d2d2d !important;
    }
  }

  .time-slot-row {
    max-height: 200px;
    overflow-y: auto;
  }
  .time-slot-row::-webkit-scrollbar {
    display: none;
  }

  .availability-ctm {
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    max-width: 113px;
    height: 41px;
    background-color: #151515 !important;
  }
  .time-slots {
    max-width: 128px;
    max-height: 41px;
  }
  .modal-footer {
    border-top: none;
  }
  .apply-button {
    border: 1px solid #ffc627 !important;
    font-size: 16px !important;
    border-radius: 8px !important;
    width: 180px;
    height: 42px;
    color: #000000 !important;
    background: #ffc627;
  }
  @media only screen and (max-width: 767px) {
    input {
      max-width: 450px;
      height: 40px;
      border: none;
      background-color: #2d2d2d !important;
    }
    .modal-footer {
      border-top: none;
    }
    .apply-button {
      border-radius: 6px !important;
      color: #000000 !important;
      background: #ffc627;
      font-size: 16px !important;
      height: 38px !important;
      width: 262px !important;
    }
  }
}

.switch-button-group {
  background: #2d2d2d;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #fff;
  position: relative;
  padding-right: 50%;
  position: relative;
  height: 40px;

  &:before {
    content: "Group";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
  }

  &-checkbox {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;

    &:checked + .switch-button-label:before {
      transform: translateX(100%);
      transition: transform 300ms linear;
    }

    & + .switch-button-label {
      height: 40px;
      position: relative;
      padding: 10px 0;
      display: block;
      user-select: none;
      pointer-events: none;

      &:before {
        content: "";
        background: #5e5e5e;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 5px;
        transform: translateX(0);
        transition: transform 300ms;
      }

      .switch-button-label-span {
        position: relative;
      }
    }
  }
}
.switch-button {
  background: #2d2d2d;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #fff;
  position: relative;
  padding-right: 50%;
  position: relative;
  height: 40px;

  &:before {
    content: "Private";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
  }

  &-checkbox {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;

    &:checked + .switch-button-label:before {
      transform: translateX(100%);
      transition: transform 300ms linear;
    }

    & + .switch-button-label {
      height: 40px;
      position: relative;
      padding: 10px 0;
      display: block;
      user-select: none;
      pointer-events: none;

      &:before {
        content: "";
        background: #5e5e5e;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 5px;
        transform: translateX(0);
        transition: transform 300ms;
      }

      .switch-button-label-span {
        position: relative;
      }
    }
  }
}
@media only screen and (min-width: 768px) {
  #createATemplate {
    .modal-dialog {
      min-width: 522px !important;
    }
  }
}

.form-control {
  width: 100% !important;
}

.choose-an-act {
  height: 53px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  text-align: center;
}

</style>
