import { render, staticRenderFns } from "./treeCourse.vue?vue&type=template&id=3cd808b7&scoped=true&"
import script from "./treeCourse.vue?vue&type=script&lang=js&"
export * from "./treeCourse.vue?vue&type=script&lang=js&"
import style0 from "./treeCourse.vue?vue&type=style&index=0&id=3cd808b7&prod&scoped=true&lang=scss&"
import style1 from "./treeCourse.vue?vue&type=style&index=1&id=3cd808b7&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cd808b7",
  null
  
)

export default component.exports