<template>
  <div
    class="modal fade"
    id="cancel-modal"
    data-backdrop="exampleModalLabel"
    data-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-lg"
      :class="{ 'modal-dialog-centered': isMobile }"
      style="max-width: 514px !important; max-height: 282px !important"
    >
      <div class="modal-content" style="border-radius: 18px">
        <div
          class="modal-body p-4"
          style="
            border: 1px solid #93751f;
            border-radius: 18px;
            box-shadow: 0px 3px 14px #ffffff60;
            border: 1px solid #93751f;
          "
        >
          <div v-if="$store.state.CancelReservation" class="p-2">
            <h1
              class="font-weight-bold font-20 text-left cancel-session-header"
              style="color: #ffc627"
            >
              Cancel reservation
            </h1>
            <div class="cancel-reservation-mobile" style="font-weight: 400">
              <p class="" style="font-size: 14px; font-weight: bold">
                <!-- Would you like the cancel the reservations? -->
                You are about to cancel this reservation for:
                <span
                  class="d-block"
                  style="font-size: 14px; font-weight: normal"
                >
                  '{{ $store.state.CancelReservation.first_name }}
                  {{ $store.state.CancelReservation.last_name }}'
                </span>
                <!-- A session you want to cancel already has a user registered. Do you
                still want to cancel this session and all current reservations? -->
              </p>
            </div>
            <div class="d-flex justify-content-center">
              <button class="btn cancel" data-dismiss="modal">Cancel</button>
              <button
                class="btn accept"
                @click="CancelReservation($store.state.CancelReservation)"
              >
                Accept <span v-if="api">...</span>
              </button>
            </div>
          </div>
          <div v-else class="p-2">
            <h1
              class="font-weight-bold font-27 text-left cancel-session-header"
              style="color: #ffc627"
            >
              Cancel session
            </h1>
            <div
              class="p-5 my-2 font-24 cancel-session-mobile"
              style="font-weight: 400"
            >
              <!-- Cancelling this session will remove all registered users. Are you
              sure you would like to continue? -->
              <p class="d-none">
                Are you sure you would like to cancel this reservation?
              </p>
              <p class="d-flex">
                Are you sure you want to cancel this session?
              </p>
              <span>
                <span
                  v-if="
                    $store.state.cancelSession &&
                    $store.state.cancelSession.title_name
                  "
                  >{{ $store.state.cancelSession.title_name }}</span
                >
                <span class="d-none"> ,</span>
                <span class="d-inline"> | </span>
                <span
                  v-if="
                    $store.state.cancelSession &&
                    $store.state.cancelSession.start_timestamp
                  "
                  >{{
                    getTrimTime($store.state.cancelSession.start_timestamp)
                  }}</span
                >
                |
                <span
                  v-if="
                    $store.state.cancelSession &&
                    $store.state.cancelSession.start_timestamp
                  "
                  >{{
                    getTrimDate($store.state.cancelSession.start_timestamp)
                  }}</span
                >
              </span>
            </div>
            <div class="d-flex justify-content-center">
              <button class="btn cancel" data-dismiss="modal">Cancel</button>
              <button
                class="btn accept"
                @click="CancelSession($store.state.cancelSession)"
              >
                Accept <span v-if="api">...</span>
              </button>
            </div>
            <p v-if="api">Wait for a while, it may take few seconds.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { API } from "aws-amplify";
import moment from "moment";

export default {
  components: {},
  data() {
    return {
      api: false,
      orders: [],
    };
  },
  methods: {
    getTrimDate(timestamp) {
      let temp = timestamp.split("T");
      return moment(temp[0]).format("ddd, MMM D, YYYY");
    },
    getTrimTime(timestamp) {
      return moment(timestamp).format("hh:mm A");
    },
    async CancelSession() {
      this.api = true;

      if (this.$store.state.cancelSession) {
        await API.get(
          "asulink",
          `/scheduler/session/${this.$store.state.cancelSession.session_id}/orders`
        ).then(async (orders) => {
          this.orders = orders;

          var fn = async function cancelSingleReservation(order) {
            return new Promise((resolve) => {
              API.del(
                "proxy",
                `/${this.$store.state.selectedSiteForScheduler.site_id}/dsl/scheduler/session/${order.session_id}/order/${order.ticket_id}`
              ).then(() => {
                resolve(order);
              });
            });
          };

          var actions = orders.map(fn); // run the function over all items

          var results = Promise.all(actions); // pass array of promises

          results.then(async () => {
            await API.del(
              "asulink",
              `/scheduler/session/${this.$store.state.cancelSession.session_id}`
            )
              .then(() => {
                $("#cancel-modal").modal("hide");
                this.$store.state.viewFromMenu = "";
                this.api = false;
                this.$nextTick(() => {
                  this.$store.state.viewFromMenu = "Pod Schedule";
                });

                let myData = {
                  selected_site_id: this.$store.state.selected_site_id,
                  podSessionDate: this.$store.state.podSessionDate,
                };
                this.$store.state.loader = true;
                this.$store.dispatch("setSchedulerSessions", myData);
              })
              .catch((err) => {
                this.$store.state.error = err.response.data;
                $("#error-checkin").modal();
              });
          });
        });
      }
    },
    CancelReservation(data) {
      this.api = true;
      API.del(
        "proxy",
        `/${this.$store.state.selectedSiteForScheduler.site_id}/dsl/scheduler/session/${data.session_id}/order/${data.ticket_id}`
      )
        .then(() => {
          $("#cancel-modal").modal("hide");

          this.$store.state.viewFromMenu = "";
          this.api = false;
          this.$nextTick(() => {
            this.$store.state.viewFromMenu = "Pod Schedule";
          });

          let myData = {
            selected_site_id: this.$store.state.selected_site_id,
            podSessionDate: this.$store.state.podSessionDate,
          };
          this.$store.dispatch("setSchedulerSessions", myData);
        })
        .catch((err) => {
          window.console.log("Error occured", err);
          this.api = false;
        });
    },
  },
  computed: {
    isMobile() {
      return window.innerWidth < 768;
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen and (max-width: 767px) {
  .btn {
    font-size: 14px !important;
    border-radius: 8px !important;
    background-color: #151515 !important;
    cursor: pointer !important;
    font-weight: bold !important;
    margin: 0.5rem !important;
    padding: 0rem 1rem !important;
    width: 108px !important;
    height: 35px !important;
  }
  .accept {
    border: 0.5px solid #ffc627 !important;
    color: #151515 !important;
    background-color: #ffc627 !important;
  }
  .cancel {
    border: 0.5px solid #ffffff !important;
    color: #ffffff !important;
  }
  .cancel-session-mobile {
    padding: 1rem 0rem !important;
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
  }
  .cancel-session-mobile p {
    margin: 0 !important;
    font-weight: medium !important;
    font-size: 14px !important;
    color: #ffffff !important;
  }
  .cancel-session-mobile span {
    font-weight: medium !important;
    font-size: 12px !important;
    color: #ffffff !important;
  }
  .cancel-reservation-mobile {
    padding: 1rem 0rem !important;
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
  }
  .cancel-reservation-mobile p {
    margin: 0 !important;
    font-weight: medium !important;
    font-size: 14px !important;
    color: #ffffff !important;
  }
  .cancel-reservation-mobile span {
    font-weight: normal !important;
    font-size: 14px !important;
    color: #ffffff !important;
  }
  .cancel-session-header {
    font-family: "kallisto-bold" !important;
    font-size: 16px !important;
  }
}
.btn {
  font-size: 16px;
  border-radius: 8px !important;
  background-color: #151515 !important;
  cursor: pointer !important;
  font-weight: bold !important;
  margin: 0.5rem !important;
  padding: 0rem 1rem !important;
  width: 136px;
  height: 42px;
}
.accept {
  border: 0.5px solid #ffc627 !important;
  color: #151515 !important;
  background-color: #ffc627 !important;
}
.cancel {
  border: 0.5px solid #ffffff !important;
  color: #ffffff !important;
}
.cancel-session-mobile {
  padding: 1rem 0rem !important;
  display: flex !important;
  align-items: center !important;
  flex-direction: column !important;
}
.cancel-session-mobile p {
  margin: 0 !important;
  font-weight: medium !important;
  font-size: 22px;
  color: #ffffff !important;
}
.cancel-session-mobile span {
  font-weight: medium !important;
  font-size: 18px;
  color: #ffffff !important;
}
.cancel-reservation-mobile {
  padding: 1rem 0rem !important;
  display: flex !important;
  align-items: center !important;
  flex-direction: column !important;
}
.cancel-reservation-mobile p {
  margin: 0 !important;
  font-weight: normal !important;
  font-size: 22px;
  color: #ffffff !important;
}
.cancel-reservation-mobile span {
  font-weight: normal !important;
  font-size: 18px;
  color: #ffffff !important;
}
.cancel-session-header {
  font-family: "kallisto-bold" !important;
  font-size: 20px;
}
.cancel:hover {
  color: #ffffff !important;
}
</style>
