<template>
  <div
    class="modal fade"
    id="course-settings"
    data-backdrop="exampleModalLabel"
    data-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <section id="info" class="py-3">
            <button type="button" class="close" data-dismiss="modal">
              <img src="../../assets/icons/times-solid.svg" alt="times solid" />
            </button>
            <div class="container text-white">
              <div class="row">
                <h2 class="text-white text-left title pb-32 m-0">
                  Course settings
                </h2>
              </div>
              <hr class="mb-29 mt-0" />
              <div class="row">
                <div class="col">
                  <h3 class="font-29 text-left">Frog cat</h3>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p class="font-24 text-left">Temperature</p>
                </div>
                <div class="col">
                  <div
                    class="badge badge-secondary font-18 justify-content-right float-right"
                  >
                    30.1 C
                  </div>
                </div>
                <div class="col">
                  <div class="slidecontainer">
                    <input
                      type="range"
                      min="1"
                      max="100"
                      value="50"
                      class="slider"
                      id="myRange"
                      aria-label="my range"
                    />
                  </div>
                </div>
              </div>
              <div class="row"></div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style scoped lang="scss">
button {
  border: none !important;
}
.modal-dialog {
  padding-top: 60px;
  max-width: 730px;
}
.modal-header {
  border-bottom: none;
}
hr {
  height: 1px;
  background-color: #ffc627;
}
.modal-content {
  background-color: #151515;
}

.slider-end-dots {
  width: 5px;
  height: 10px;
  background: #515151 0% 0% no-repeat padding-box;
  opacity: 1;
}
#info {
  /* height: 515px; */
  max-width: 732px;
  background: #151515 0% 0% no-repeat padding-box;
}

.description {
  max-width: 323px;
  font-size: 14px;
}

.cyp-para {
  padding: 0px 200px;
  font-size: 14px;
}

.placeholder {
  width: 236px;
  height: 283px;
}

.title {
  font-size: 38px;
}

.exp {
  font-size: 33px;
}

.pod-sched-header {
  font-size: 29px;
}

.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 1px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 19px;
  height: 19px;
  border: 0;
  background: url("../../assets/icons/range-slider-img.svg");
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 19px;
  height: 19px;
  border: 0;
  background: url("../../assets/icons/range-slider-img.svg");
  background-repeat: no-repeat;
  cursor: pointer;
}
</style>
