<template>
  <div
    class="modal"
    id="podSession"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    data-backdrop="exampleModalLabel"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header px-lg-5">
          <div class="d-flex justify-content-between w-100">
            <span
              v-dataanchor
              tagEvent="link"
              tagAction="click"
              tagType="internal link"
              tagSection="choose a pod session for"
              tagText="back"
              class="py-2"
              data-dismiss="modal"
            >
              <i class="modal-close pr-2">
                <img
                  class="pointer"
                  :src="getIcon('arrow-left-solid-white.svg')"
                  alt="chevron-left-solid"
                />
              </i>
              Back
            </span>
            <span
              v-dataanchor
              tagEvent="modal"
              tagAction="close"
              tagType="click"
              tagSection="choose your experience"
              tagText="close cross"
              class=""
              data-dismiss="modal"
            >
              <i class="modal-close pr-2">
                <img
                  class="pointer w-100 h-100"
                  :src="getIcon('times-solid.svg')"
                  alt="close-image"
                />
              </i>
            </span>
          </div>
          <div
            class="d-flex px-0 flex-wrap justify-content-between align-items-center col-12"
          >
            <h3 class="font-24 font-weight-bold text-left mb-lg-0 mb-3">
              Choose a pod session for
            </h3>
            <select
              v-dataanchor
              tagEvent="select"
              tagAction="open"
              tagType="dropdown"
              tagSection="choose a pod session for"
              aria-label="choose pod"
              :tagText="$store.state.selected_title"
              name="choose pod"
              id="ChoosePod"
              class="text-white px-2 font-weight-bold font-24 py-3 float-right"
            >
              <option value="foodwebact1">
                {{ $store.state.selected_title }}
              </option>
            </select>
          </div>
        </div>
        <div class="modal-body text-left os-regular px-lg-4 px-0">
          <loading :active.sync="loader" :is-full-page="false"></loading>
          <div class="px-4">
            <p class="font-weight-bold">Select a date</p>
            <div class="d-flex mb-4 justify-content-between">
              <div
                v-dataanchor
                tagEvent="modal"
                tagAction="open/close"
                tagType="click"
                tagSection="choose a pod session for"
                tagText="select a date"
                class="d-flex align-items-center"
              >
                <img
                  class="pointer"
                  :src="getIcon('calendar-alt-solid.svg')"
                  alt="calendar-alt-solid"
                  height="20px"
                />
                <datepicker
                  v-model="calendarDate"
                  :format="customFormatter"
                  :highlighted="getCurrentDate()"
                  class="session-date-selector"
                  @closed="selectedDate(calendarDate)"
                  :disabled-dates="disabledDates"
                >
                </datepicker>
              </div>
              <div
                v-if="
                  $store.state.expierenceAvailabilities[
                    $store.state.selected_title_id
                  ]
                "
                class="date-carousel d-flex px-2"
              >
                <img
                  :src="getIcon('chevron-left-solid.svg')"
                  alt="chevron-left-solid"
                  @click="changeCurrentDate(-1)"
                  class="pointer"
                />
                <div class="px-3 d-flex align-items-center">
                  <span
                    class="podSessionDate fontRoboto mx-3 pointer font-weight-bold"
                    >{{ checkToday($store.state.podSessionDate) }}</span
                  >
                </div>
                <img
                  class="rotate-180 pointer"
                  :src="getIcon('chevron-left-solid.svg')"
                  alt="chevron-left-solid"
                  @click="changeCurrentDate(1)"
                />
              </div>
            </div>

            <div class="d-flex justify-content-between pt-2">
              <p class="font-weight-bold">Select a session</p>
              <p class="px-1">
                ADA selection<input
                  v-dataanchor
                  tagEvent="select"
                  tagAction="click"
                  tagType="checkbox"
                  tagSection="select a session"
                  tagText="ada selection"
                  class="m-1"
                  aria-label="ada selection"
                  type="checkbox"
                  v-model="$store.state.adaSelection"
                  @click="
                    filterADA(
                      $store.state.expierenceAvailabilities[
                        $store.state.selected_title_id
                      ]
                    )
                  "
                />
              </p>
            </div>
          </div>
          <div>
            <div
              v-if="
                $store.state.expierenceAvailabilities[
                  $store.state.selected_title_id
                ] &&
                Object.keys(
                  $store.state.expierenceAvailabilities[
                    $store.state.selected_title_id
                  ]
                ).length == 0
              "
              class="p-5 text-center"
            >
              <p class="mb-0 font-38 font-weight-bold">No sessions Available</p>
              <p class="font-16">
                Choose a different act or select a different date
              </p>
            </div>
            <div
              v-for="(sess, j) in $store.state.expierenceAvailabilities[
                $store.state.selected_title_id
              ]"
              :key="j"
              class="d-flex flex-wrap m-0 pr-lg-4"
              style=""
            >
              <div
                v-if="getFilteredSessins(sess).length == 0"
                class="p-5 text-center col-12"
              >
                <p class="mb-0 font-38 font-weight-bold">
                  No sessions Available
                </p>
                <p class="font-16">
                  Choose a different act or select a different date
                </p>
              </div>
              <div
                v-else
                v-for="(session, i) in getFilteredSessins(sess)"
                :key="i"
                class="px-2 col-6 col-lg-3"
              >
                <div
                  v-if="!$store.state.adaSelection"
                  class="col-12 border-layout my-3 mx-2 p-3"
                >
                  <div class="text-center">
                    <p class="font-weight-bold" v-if="session.datetime">
                      {{ getTrimTime(session.datetime) }}
                    </p>
                    <div
                      class="d-flex justify-content-center"
                      v-if="session.quantity > 0"
                    >
                      <div
                        class="yellow-checkbox"
                        v-for="(btn, j) in session.quantity"
                        :key="j"
                      ></div>
                    </div>
                    <div v-else>
                      <div style="height: 64px"></div>
                    </div>
                    <button
                      v-dataanchor
                      tagEvent="link"
                      tagAction="click"
                      tagType="click"
                      :tagSection="getTrimTime(session.datetime)"
                      tagText="reserve spot"
                      v-if="session.quantity > 0"
                      class="border-layout my-3 fontRoboto font-weight-bold"
                      @click="reserveSpot(session)"
                      data-toggle="modal"
                      data-target="#confirm-my-visit"
                      data-dismiss="modal"
                    >
                      Reserve Spot
                    </button>
                    <span v-else class="my-3"> No seats available</span>
                  </div>
                </div>
                <div
                  v-if="$store.state.adaSelection && session.ada_quantity > 0"
                  class="col-12 border-layout my-3 mx-2 p-3"
                >
                  <div class="text-center">
                    <p class="font-weight-bold" v-if="session.datetime">
                      {{ getTrimTime(session.datetime) }}
                    </p>
                    <div
                      class="d-flex justify-content-center"
                      v-if="session.quantity > 0"
                    >
                      <div
                        class="yellow-checkbox"
                        v-for="(btn, j) in session.quantity"
                        :key="j"
                      ></div>
                    </div>
                    <div v-else>
                      <div style="height: 64px"></div>
                    </div>
                    <button
                      v-dataanchor
                      tagEvent="link"
                      tagAction="click"
                      tagType="click"
                      :tagSection="getTrimTime(session.datetime)"
                      tagText="reserve spot"
                      v-if="session.quantity > 0"
                      class="border-layout my-3 fontRoboto font-weight-bold"
                      @click="reserveSpot(session)"
                      data-toggle="modal"
                      data-target="#confirm-my-visit"
                      data-dismiss="modal"
                    >
                      Reserve Spot
                    </button>
                    <span v-else class="my-3"> No seats available</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="this.$store.state.showCalModal"
      id="shortCalendar"
      class="modal fade"
      role="dialog"
      style="z-index: 1600"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <cal />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import {
  dataLayerAnchorTag,
  dataLayerButtonTag,
  dataLayerSelectTag,
  dataLayerInputTag,
} from "data-layer-test-oo-gtm";
import cal from "./calendar";
export default {
  directives: {
    dataanchor: dataLayerAnchorTag,
    databutton: dataLayerButtonTag,
    "data-select": dataLayerSelectTag,
    "data-input": dataLayerInputTag,
  },
  components: {
    cal,
    Loading,
    Datepicker,
  },
  data() {
    return {
      checked: false,
      loader: false,
      calendarDate: null,
      disabledDates: {
        to: new Date(Date.now() - 8640000),
        days: [6, 0], // Disable Saturday's and Sunday's
      },
    };
  },
  methods: {
    getCurrentDate() {
      return {
        dates: [
          // your date
          new Date(Date.now()),
        ],
      };
    },
    getFilteredSessins(sess) {
      if (this.$store.state.adaSelection) {
        return sess.filter((sess) => sess.ada_quantity > 0);
      }
      return sess;
    },
    filterADA() {},
    getTrimTime(timestamp) {
      return moment(timestamp).format("hh:mm A");
    },
    getImage(img) {
      return require("@/assets/images/" + img);
    },
    getIcon(img) {
      return require("@/assets/icons/" + img);
    },
    customFormatter(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    reserveSpot(session) {
      this.$store.state.reserved_spot = session;
      this.$store.state.refresh = false;
      setTimeout(() => {
        this.$store.state.refresh = true;
      }, 2000);
    },
    checkToday(date) {
      if (date == this.convertToDate(new Date())) {
        return "Today";
      }
      return date;
    },
    getnextday(numOfDays) {
      let dateStr = this.$store.state.podSessionDate;
      return this.convertToDate(
        new Date(
          new Date(dateStr).setDate(new Date(dateStr).getDate() + numOfDays)
        )
      );
    },
    selectedDate(date) {
      if (date) {
        this.$nextTick(() => {
          this.loader = true;
          this.$store
            .dispatch("setExpierenceAvailability", {
              site_id: this.$store.state.selected_site_id,
              title_id: this.$store.state.selected_title_id,
              start_date: this.customFormatter(date),
            })
            .then(() => {
              this.loader = false;
              this.$store.state.podSessionDate = this.customFormatter(date);
            })
            .catch((err) => {
              this.loader = false;
              this.$store.state.expierenceAvailabilities = {};
              console.log(err);
            });
        });
      }
    },
    convertToDate(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    },
    changeCurrentDate(day) {
      this.loader = true;
      let temp = moment(this.$store.state.podSessionDate, "YYYY-MM-DD").add(
        day,
        "days"
      );
      this.$store.state.podSessionDate = moment(temp).format("YYYY-MM-DD");
      this.$store
        .dispatch("setExpierenceAvailability", {
          site_id: this.$store.state.selected_site_id,
          title_id: this.$store.state.selected_title_id,
          start_date: this.$store.state.podSessionDate,
        })
        .then(() => {
          this.loader = false;
        });
    },
  },
  mounted() {
    this.$store.state.podSessionDate = this.convertToDate(new Date());
    setTimeout(() => {
      let el = document.querySelectorAll(".vdp-datepicker input");
      el.forEach((selector) => {
        selector.setAttribute("aria-label", "datepicker input");
      });
    }, 3000);
  },
};
</script>

<style lang="scss" scoped>
.modal {
  overflow: auto !important;
}
.session-date-selector {
  position: absolute !important;
  left: 2rem;
  input {
    padding-left: 15px !important;
    cursor: pointer !important;
    background-color: transparent !important;
    border: none !important;
    &:focus {
      outline: none !important;
    }
  }
}
#podSession {
  .modal-body {
    color: white;
    background: #151515;
  }
  .modal-heading {
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: 700;
    color: #000011;
  }
  .modal-header {
    flex-direction: column;
    background: rgb(0, 0, 0.2);
    padding-top: 10px;
    padding-bottom: 10px;
    color: aliceblue;
  }
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 65%;
    }
  }
  .yellow-checkbox {
    background: #ffc627;
    height: 17px;
    width: 17px;
    margin: 2px;
    border-radius: 6px;
  }
}
#shortCalendar {
  .modal-dialog {
    width: 25%;
  }
}
.podSessionDate {
  color: #ffc627;
}
.fontRoboto {
  font-family: "Roboto";
}
.pointer {
  cursor: pointer;
  position: relative;
  z-index: 0;
  left: 4%;
}
#ChoosePod {
  background: url("../assets/icons/chevron-down-solid-calendar.svg") no-repeat
    right #ddd;
  -webkit-appearance: none;
  background-position-x: 204px;
  background-color: black !important;
  padding: 16px 62px 16px 25px !important;
  border: 1px solid #ffffff;
  border-radius: 8px;
  cursor: pointer;
  max-width: 260px;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media (max-width: 991px) {
    width: 100%;
    background-position-x: 95%;
  }
}
button {
  border: 1px solid white !important;
  color: white !important;
  font-size: 16px !important;
  border-radius: 30px !important;
  background-color: #151515;
  padding: 5px 15px;
}
</style>
