<template>
  <div>
    <td
      class="col-2 py-2 px-0 cancel-reservation-btn font-14 font-weight-bold d-none d-md-flex"
      style="cursor: pointer"
      v-if="participant.ticket_id && !participant.checked_in"
      @click="CancelReservation(participant)"
    >
      Cancel
    </td>
    <div
      class="col-2 pt-3 d-flex d-md-none"
      @click="cancelOrderMenu(participant.ticket_id)"
      style="cursor: pointer"
      v-if="!participant.checked_in"
      v-click-outside="hide"
    >
      <img src="../assets/icons/dots.svg" alt="close icon"/>
      <button
        v-if="participant.ticket_id && openCancelBtn"
        @click="CancelReservation(participant)"
        class="cancel-reservation fontRoboto font-14 p-2 m-0"
      >
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import moment from "moment";
export default {
  props: {
    participant: Object,
    session: Object,
  },
  data() {
    return {
      openCancelBtn: false,
    };
  },
  methods: {
    available(session) {
      var today = moment().tz("America/Phoenix").format();
      if (moment(session.start_timestamp).isBefore(moment(today))) {
        return false;
      }
      return true;
    },
    CancelReservation(participant) {
      this.$emit("CancelReservation", participant);
    },
    cancelOrderMenu() {
      this.openCancelBtn = !this.openCancelBtn;
    },
    hide() {
      this.openCancelBtn = false;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style>
.cancel-reservation {
  min-width: auto !important; 
  background-color: #000207 !important; 
  color: #FFFFFF !important;
}
.cancel-reservation:hover {
  color: #FFC627 !important;
}
.cancel-reservation-btn:hover{
  color: #FFC627 !important;
}
</style>