<template>
  <div
    class="modal fade"
    id="sessionsAdded"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content sessions-added-modal">
        <div class="modal-header d-block">
          <div class="row my-1">
            <p
              class="modal-title font-weight-bold font-24 ml-lg-31 text-center text-lg-left"
            >
              Added Correctly
            </p>
            <button type="button" class="close" data-dismiss="modal">
              <span aria-hidden="true" class="text-white">&times;</span>
            </button>
          </div>
          <div class="row">
            <p class="ml-lg-31">
              The following session were scheduled properly
            </p>
          </div>
        </div>
        <div class="modal-body px-0 text-left pt-0">
          <div class="template-v-for">
            <div
              v-for="(session, i) in this.modalData.new"
              :key="i"
              class="batch-list-div pt-3"
            >
              <div
                class="row added-session-row d-flex align-items-center pt-24 pb-24"
              >
                <div class="col-6 py-0">
                  <p
                    class="font-18 font-weight-bold align-self-center ml-15 ml-lg-31 mb-0"
                  >
                    {{ session.title_id }}
                  </p>
                </div>
                <div class="col-6 py-0">
                  <p class="font-14 align-self-center m-0 font-weight-bold">
                    {{ convertTime(session.start_timestamp) }} |
                    {{ convertDate(session.start_timestamp) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API } from "aws-amplify";
import moment from "moment";
import $ from "jquery";

export default {
  components: {},
  data() {
    return {
      //Batch Apply Template Modal Data *********************
      chosenBatchTemplate: "",
      batchTemplateStartDate: "",
      batchTemplateEndDate: "",
      batchTemplateName: "",
      //   batchTemplateMultiSelect: [],
      templates: this.$store.state.templates,
      selectedTitleId: "",
      seatCapacity: "",

      //*********************
      modalData: {},
    };
  },
  methods: {
    convertTime(time) {
      var str = time.toString();
      var newTime = str.substring(
        str.lastIndexOf("T") + 1,
        str.lastIndexOf("+")
      );
      // Check correct time format and split into components
      newTime = newTime
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [newTime];

      if (newTime.length > 1) {
        // If newTime format correct
        newTime = newTime.slice(1); // Remove full string match value
        newTime[5] = +newTime[0] < 12 ? "am" : "pm"; // Set AM/PM
        newTime[0] = +newTime[0] % 12 || 12; // Adjust hours
      }
      var returnTime = newTime.slice(":");
      return returnTime[0] + returnTime[1] + returnTime[2] + returnTime[5];
    },
    convertDate(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    onChangeAct(event) {
      this.selectedTitleId = event.target.value;
    },
    getIcon(img) {
      return require("@/assets/icons/" + img);
    },
    customFormatter(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    async batchApplyTemplate() {
      var start = new Date(
        moment(this.batchTemplateStartDate).format("YYYY-MM-DD")
      );
      var end = new Date(
        moment(this.batchTemplateEndDate).format("YYYY-MM-DD")
      );
      var gearupId = this.$store.state.calendarSelectedGearup.gearup_id;
      var capacity = this.$store.state.calendarSelectedGearup.max_users;
      var titleId = "";
      let getStatus = this.$store.state.titles.find(
        (status) => status.shortname === this.selectedTitleId
      );
      if (getStatus != undefined) {
        if (getStatus.shortname == this.selectedTitleId) {
          titleId = getStatus.title_id;
          capacity = getStatus.max_player_count;
        }
      }
      $("#batchApplyTemplate").modal("hide");

      this.$store.state.batchTemplateMultiSelect.forEach((template) => {
        if (template.checked == true) {
          var loop = new Date(start);

          while (loop <= end) {
            var batchTemplateBody = {
              body: {
                site_id: this.$store.state.selected_site_id,
                title_id: titleId,
                schedule_date: `${loop
                  .toISOString()
                  .substr(0, loop.toISOString().indexOf("T"))}`,
                capacity: capacity,
                gearup_id: gearupId,
              },
            };
            API.post(
              "asulink",
              `/scheduler/schedule/${template.schedule_id}/apply`,
              batchTemplateBody
            ).catch((err) => {
              window.console.log("Error occured", err);
            });

            var newDate = loop.setDate(loop.getDate() + 1);
            loop = new Date(newDate);
          }
          this.chosenTemplate(template);
        }
      });

      this.batchTemplateStartDate = "";
      this.batchTemplateEndDate = "";
      this.selectedTitleId = "";
    },
    async refreshCalendar() {
      await this.$store.dispatch("setSessions").then(() => {});
    },
    async executeBatchApply() {
      await this.batchApplyTemplate().then(async () => {
        await this.refreshCalendar().then(() => {
          $("#batch-apply-sucess").modal();
        });
      });
    },
    chosenTemplate(template) {
      this.$store.state.batchTemplateMultiSelect.forEach((mst, i) => {
        if (template.schedule_name == mst.schedule_name) {
          template.checked = !template.checked;
          this.$set(this.$store.state.batchTemplateMultiSelect, i, template);
        }
      });
    },
    onChangeTemplate(event) {
      this.chosenBatchTemplate = event.target.value;
    },
  },
};
</script>

<style lang="scss">
.modal {
  input {
    background-color: #151515;
  }
  .modal-content {
    background-color: #151515;
  }
  .modal-header {
    border-bottom: 0.5px solid #ffc627;
  }
}

.sessions-added-modal {
  background-color: #151515;
  color: white;

  .added-session-row {
    border-bottom: 1px solid #707070;
  }

  .apply-button {
    border: 1px solid #ffc627 !important;
    color: #ffc627 !important;
    font-size: 16px !important;
    border-radius: 30px !important;
    background-color: #151515;
    width: 156px;
    height: 47px;
  }
  .modal-footer {
    border-top: 1px solid #ffc627;
  }
  input:focus {
    background-color: #151515;
  }
  select:focus {
    background-color: #151515;
  }
  select {
    background-color: #151515;
    color: #ffc627 !important;
    option {
      color: #ffc627;
    }
  }
  .batch-apply-timeSlots {
    display: block;
  }
  .batch-apply-timeSlots-popover {
    display: none;
  }
}

.choose-an-act {
  width: 100%;
  height: 53px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  color: #ffc627;
}

.to-from-dates {
  width: 140px;
  height: 53px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  margin-left: 16px;
  color: white;
}

.datepicker {
  color: black;
}

.template-v-for {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  border-top: 0.5px solid #707070;
  border-bottom: 0.5px solid #707070;
}

.vdp-datepicker {
  input {
    width: 140px;
    height: 53px;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    color: white;
    text-align: center;
    cursor: pointer;
  }
  .prev {
    background-color: grey !important;
    left: 12%;
  }

  .next {
    background-color: grey !important;
    right: 12%;
  }
}

.vdp-datepicker__calendar {
  color: white;
  background-color: #151515 !important;
  font-size: 12px !important;
}

.batch-list {
  border-bottom: 0.5px solid #707070;
}

.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: pink;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #4a4a4a;

    .popover-inner {
      background: $color;
      color: white !important;
      padding: 24px;
      border-radius: 0px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}

@media only screen and (max-width: 992px) {
  .batch-template-modal {
    .batch-apply-timeSlots {
      display: none;
    }
    .batch-apply-timeSlots-popover {
      display: block;
    }
  }
}
</style>
