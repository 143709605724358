<template>
  <div class="div-test-last d-flex alig-items-center">
    <span class="ie-flex d-none d-lg-flex">
      <a href="javascript:void(0)" data-toggle="tooltip" title="In progress">
        <img
          :src="getIcon('open.svg')"
          class="p-0 d-md-block status-icon"
          alt="openIcon"
        />
      </a>
    </span>
    <div class="w-100 lastchild-card" style="margin-right: -4%">
      <div class="p-4 mx-0 mb-3 row f-row moduleBreakDownBg" style>
        <div class="p-2 col-md-8 col-sm-12">
          <div class="course-title d-flex">
            <img
              :src="getIcon('open.svg')"
              class="p-0 d-lg-none status-icon"
              alt="openIc"
            />
            <h4
              class="pr-3 mb-0 text-center font18 font-weight-900 text-lg-left w-100 pr-lg-0"
            >
              {{ expierence.shortname }}
            </h4>
          </div>
          <div class="px-0 py-2 course-properties flex-list col-12">
            <div class="flex-wrap px-4 mt-4 mb-4 font12 d-flex mb-lg-0 px-lg-0">
              <div class="p-0 text-center text-capitalize col-3 col-md-2">
                open
              </div>
              <div class="p-0 text-center br-1 bl-1 col-4 col-md-3">15mins</div>
              <div class="p-0 text-center col-5 col-md-4 pl-lg-2 p-xl-0">
                <a
                  v-dataanchor
                  tagEvent="modal"
                  tagAction="open"
                  tagType="click"
                  tagText="view details"
                  :tagSection="expierence.shortname"
                  tagComponent="bio 181"
                  data-toggle="modal"
                  data-target="#openModal"
                  @click="expierenceDetail(expierence)"
                  href="javascript:void(0)"
                  class="Highlighted_url underline-text"
                  >View details</a
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="course-button d-flex justify-content-center align-self-center col-md-4"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  dataLayerAnchorTag,
  dataLayerButtonTag,
  dataLayerSelectTag,
  dataLayerInputTag,
} from "data-layer-test-oo-gtm";
export default {
  directives: {
    dataanchor: dataLayerAnchorTag,
    databutton: dataLayerButtonTag,
    "data-select": dataLayerSelectTag,
    "data-input": dataLayerInputTag,
  },
  props: {
    expierence: Object,
  },
  methods: {
    getIcon(img) {
      return require("@/assets/icons/" + img);
    },
    expierenceDetail(expierence) {
      this.$emit("expierenceDetail", expierence);
    },
  },
};
</script>

<style scoped lang="scss">
a {
  color: #ffc627;
}
button,
.button {
  border: 1px solid #ffc627 !important;
  color: #ffc627 !important;
  font-size: 16px !important;
  border-radius: 30px !important;
  background-color: #151515;
  padding: 5px 15px;
}
</style>
