<template>
  <div v-if="this.$store.state.role == 'student'" class="container student-ds">
    <userDetails />
    <div class="row text-left mt-32">
      <div class="col-lg-8">
        <div id="module_breakdown" class="">
          <div class="mb-3">
            <span class="btn-grey p-1 font-12 font-weight-bold">
              Module breakdown</span
            >
          </div>
          <div class="f-title"></div>
          <template>
            <tree-course />
          </template>
        </div>
      </div>

      <div class="col-lg-4 px-lg-0">
        <div id="myprogress" class="px-lg-3">
          <p>
            <span class="btn-grey mb-2 p-1 font-12 font-weight-bold"
              >Your scheduled visits</span
            >
          </p>
        </div>
        <div
          class="has-orders px-lg-3"
          v-if="hasOrders && $store.state.orderCarousel"
        >
          <carousel
            :per-page="1"
            :mouse-drag="true"
            :navigationEnabled="true"
            navigationNextLabel=">"
            navigationPrevLabel="<"
            @page-change="CurrentActiveCarousal"
          >
            <slide v-for="(order, i) in $store.state.studentOrders" :key="i">
              <has-orders
                :compData="order"
                :currentCounter="i + 1"
                :maxCounter="$store.state.studentOrders.length"
                @activeOrder="handleModal"
              />
              <div class="text-center pt-4" style="cursor: pointer">
                <p
                  @click="OpenCancelModal(order)"
                  v-if="getDateStatus(order.session.start_timestamp)"
                  class="mb-0"
                >
                  Cancel appointment
                </p>
              </div>
            </slide>
          </carousel>
        </div>
        <div class="has-no-orders" v-else>
          <div class="border-layout mt-2">
            <h3 class="font-weight-bold">Want to use the pods?</h3>
            <p>Choose a date below to schedule your pod experience</p>
            <input type="number" />
            <p>
              <a href="">View all available appointments</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <cancelMpdal :compData="orders[curr]" />
    <div
      class="modal"
      id="expand-modal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content mx-auto">
          <div class="b-modal-header position-relative">
            <button
              type="button"
              class="close expandClose position-absolute bg-white rounded-circle"
              data-dismiss="modal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-left pt-42 pl-35 pr-38 pb-34">
            <div class="qrCode">
              <div>
                <qrcode-vue
                  class="d-flex justify-content-center"
                  :value="activeId"
                  :background="'#000000'"
                  :foreground="'#ffffff'"
                  size="265"
                  level="H"
                />
              </div>
              <div class="mt-40 text-center">
                <p class="font-22 font-weight-bold text-brand-gold mb-0">
                  {{ activeId }}
                </p>
                <p class="font-16 font-weight-bold text-white">Booking ID</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="no-dashboard pt-5">
    <h1>Dashboard goes here</h1>
    <InviteUserModals v-if="this.$store.state.role == 'instructor'" />
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import QrcodeVue from "qrcode.vue";
import cancelMpdal from "@/components/cancelAppointment.vue";
import userDetails from "@/components/userDetails.vue";
import treeCourse from "@/components/treeCourse.vue";
import hasOrders from "@/components/hasOrders.vue";
import moment from "moment";
import InviteUserModals from "../components/instructor/InviteUserModals.vue";
import $ from "jquery";
export default {
  components: {
    userDetails,
    treeCourse,
    hasOrders,
    Carousel,
    Slide,
    InviteUserModals,
    cancelMpdal,
    QrcodeVue,
  },
  data() {
    return {
      curr: 1,
      hasOrders: true,
      activeId: "",
      orders: [],
      data: {
        Plan_Requirements: [
          {
            Name: "BIO 181",
            Id: "bio181",
            Plan_Requirements: [
              {
                Id: "abc123",
                Name: "Food Web1",
                status: "open",
                Plan_Requirements: [
                  {
                    Id: "abcc123",
                    Name: "Food Web act 1",
                    status: "open",
                    hrs: "30mins",
                  },
                  {
                    Id: "abcc1233",
                    Name: "Food Web act 2",
                    status: "open",
                    hrs: "30mins",
                  },
                  {
                    Id: "abcc12333",
                    Name: "Food Web act 3",
                    status: "open",
                    hrs: "30mins",
                  },
                ],
                hrs: "30mins",
              },
              {
                Id: "abc456",
                Name: "Food Web2",
                status: "open",
                Plan_Requirements: [
                  {
                    Id: "abcc456",
                    Name: "Food Web act 1",
                    status: "open",
                    hrs: "30mins",
                  },
                  {
                    Id: "abcc4563",
                    Name: "Food Web act 2",
                    status: "open",
                    hrs: "30mins",
                  },
                ],
                hrs: "10mins",
              },
            ],
          },
          {
            Name: "BIO 182",
            Id: "bio182",
            Plan_Requirements: [
              {
                Id: "def123",
                Name: "Food Web3",
                status: "open",
                Plan_Requirements: [
                  {
                    Id: "abcc4567",
                    Name: "Food Web act 1",
                    status: "open",
                    hrs: "30mins",
                  },
                  {
                    Id: "abcc45673",
                    Name: "Food Web act 2",
                    status: "open",
                    hrs: "30mins",
                  },
                ],
                hrs: "20mins",
              },
              {
                Id: "def456",
                Name: "Food Web4",
                status: "open",
                Plan_Requirements: [
                  {
                    Id: "abcc4568",
                    Name: "Food Web act 1",
                    status: "open",
                    hrs: "30mins",
                  },
                  {
                    Id: "abcc45638",
                    Name: "Food Web act 2",
                    status: "open",
                    hrs: "30mins",
                  },
                ],
                hrs: "150mins",
              },
            ],
          },
        ],
      },
    };
  },
  async mounted() {
    this.orders = this.ordersTest();
  },

  methods: {
    handleModal(data) {
      this.activeId = data;
    },
    OpenCancelModal(order) {
      this.$store.state.selectedOrder = order;
      $("#cancel-appointment").modal();
    },
    ordersTest() {
      let ord = [];
      this.$store.state.studentOrders.forEach((order) => {
        let o = {};
        o.id = order.ticket_transaction.ticket_id;
        o.heading = order.session.title_shortname;
        o.time = order.session.start_timestamp;
        o.date = "3rd Mar";
        o.location = order.ticket_transaction.ticket_site_id;
        ord.push(o);
      });
      return ord;
    },
    CurrentActiveCarousal(curr) {
      this.curr = curr;
    },
    getDateStatus(timestamp) {
      var tomorrow = moment().add(1, "day").tz("America/Phoenix").format();
      if (!moment(timestamp).isBefore(tomorrow)) {
        return moment(timestamp).format("MM-DD-YYYY");
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.student-ds {
  height: 100vh;
  background: #151515;
}
.btn-grey {
  background: #4e4e4e;
  border-radius: 4px;
}
.no-dashboard {
  height: 100vh;
}
.has-orders {
  .VueCarousel-navigation {
    position: absolute;
    width: 80%;
    bottom: 16px;
    left: 0;
    right: 0;
    margin: 0 auto;
    .VueCarousel-navigation-button {
      background-color: white !important;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      line-height: 0;
      color: black !important;
    }
  }
}
.VueCarousel-dot {
  padding-left: 3px !important;
  padding-right: 3px !important;
}
.VueCarousel-dot--active {
  background-color: grey !important;
}
.py-space-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}
.f-title {
  @media (min-width: 992px) {
    h1 {
      border-bottom: 8px solid #ffc627;
      display: inline-block;
    }
  }
}
#expand-modal {
  z-index: 3000;
  .expandClose {
    width: 24px;
    height: 24px;
    right: 0px;
    top: -25px;
    opacity: 1 !important;
  }
}
</style>
