<template>
  <div>
    <div class="login-account text-left">
      <div class="container-fluid logoShadow">
        <div class="d-flex flex-wrap container pt-30 pb-50">
          <img src="../assets/DSLlogo.svg" alt="" />
        </div>
      </div>
      <div class="component mx-auto pt-5">
        <div class="row">
          <form action="" style="width: 300px; margin: auto">
            <h2 class="py-2 font-28 font-md-32 font-weight-900 text-center">
              Welcome to Dreamscape Learn
            </h2>
            <p class="font-17 mb-25 text-center">
              Transforming education through exploration
            </p>
            <div>
              <CustomButton
                class="mx-auto d-block cus-btn mt-0 mb-48"
                buttonText="Get started"
                @click.native="onClickFinish()"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
    <asu-footer></asu-footer>
  </div>
</template>
<script>
import CustomButton from "./Button.vue";
import AsuFooter from "./AsuFooter.vue";
export default {
  components: {
    CustomButton,
    "asu-footer": AsuFooter,
  },
  methods: {
    onClickFinish() {
      this.$router.push({ path: "portal", query: { signin: "true" } });
    },
  },
};
</script>
<style lang="scss" scoped>
.logoShadow {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}
.login-account {
  height: 100vh;
  background-image: url("../assets/images/createYourAccount.png");
  background-repeat: no-repeat;
  background-size: cover;

  h2 {
    font-size: 32px;
    padding-top: 40px;
  }
  .left-col {
    background-color: #5d5d5d;
  }

  input {
    background-color: #151515;
  }

  .right-col {
    background-color: #151515;
  }
  .cus-btn {
    margin-bottom: 80px;
    margin-top: 40px;
  }

  .component {
    max-width: 400px;
    background-color: #151515;
    position: relative;
    top: 16%;
  }
  p {
    width: 296px;
  }
}
</style>
