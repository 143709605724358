<template>
  <div>
    <div
      class="modal fade"
      id="openModal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-slideout modal-md" role="document">
        <div class="modal-content">
          <div class="b-modal-header">
            <nav class="navbar navbar-light modal-nav fixed-top">
              <a
                v-dataanchor
                tagEvent="link"
                tagAction="click"
                tagType="internal link"
                tagText="back"
                :tagSection="returnTitle()"
                class="close"
                data-dismiss="modal"
                href="javascript:void(0)"
              >
                <i class="modal-close pr-2">
                  <img
                    :src="getIcon('chevron-left-solid.svg')"
                    alt="chevron-left-solid"
                  />
                </i>
                Back
              </a>
            </nav>
          </div>
          <div class="modal-body text-left p-0">
            <img
              :src="getImage('box.png')"
              class="w-100 h-auto"
              alt="modal image"
            />
            <div class="mod-btn">
              <p class="moduleAct font-weight-bold py-2 px-3">Module Act</p>
            </div>
            <div class="p-32">
              <h3
                class="modal-heading text-white mb-3"
                v-if="this.$store.state.selected_title"
              >
                {{ this.$store.state.selected_title }}
              </h3>
              <div class="d-flex px-3 border-top border-bottom">
                <p class="my-4 py-2">
                  <img :src="getIcon('open.svg')" alt="openIcon" class="mr-3" />
                  Status: <span class="text-capitalize">Open</span>
                </p>
                <p class="my-4 py-2 pl-4">
                  <img
                    :src="getIcon('clock-solid.svg')"
                    alt="calendar"
                    class="mr-3"
                  />
                  30 mins
                </p>
              </div>
              <p
                class="modal-p font16 mt-4 pt-2 px-3"
                v-if="
                  $store.state.selected_title_id &&
                  modalData[$store.state.selected_title_id] &&
                  modalData[$store.state.selected_title_id].para
                "
              >
                {{ modalData[$store.state.selected_title_id].para }}
              </p>
              <div class="d-flex justify-content-center"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  dataLayerAnchorTag,
  dataLayerButtonTag,
  dataLayerSelectTag,
  dataLayerInputTag,
} from "data-layer-test-oo-gtm";
export default {
  directives: {
    dataanchor: dataLayerAnchorTag,
    databutton: dataLayerButtonTag,
    "data-select": dataLayerSelectTag,
    "data-input": dataLayerInputTag,
  },
  data() {
    return {
      data: {
        moduleAct: " Module Act",
        img: "box.png",
        title: "Food Web: Act 2",
        desc: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy",
      },
      modalData: {
        // get modal data for acts untill you get API
        alienzoolearn0101: {
          para: "Inside the Allurian forest, a generation of baby frogcats suffers from a mysterious illness. Can you apply your knowledge of biology to discover the cause before it's too late?",
          img: "1.png",
        },
        alienzoolearn0102: {
          para: "Journey deeper into the Allurian forest to investigate a disruption in the food web that holds the key to saving the baby frogcats. Can you find the clues to identify the disturbance?",
          img: "2.png",
        },
        alienzoolearn0103: {
          para: "As night falls on the Allurian forest, use the clues you’ve gathered to track down and eliminate the disturbance to the food web. Do you have the skills and courage to restore the natural balance of the ecosystem?",
          img: "3.png",
        },
      },
    };
  },
  methods: {
    getImage(img) {
      return require("@/assets/images/" + img);
    },
    getIcon(img) {
      return require("@/assets/icons/" + img);
    },
    returnTitle() {
      return this.$store.state.selected_title;
    },
  },
};
</script>
<style scoped>
.modalBtn {
  border: 1px solid #ffc627 !important;
  color: #ffc627 !important;
  font-size: 16px !important;
  border-radius: 30px !important;
  background-color: transparent;
  padding: 5px 15px;
}
.modal-dialog-slideout {
  min-height: 100%;
  margin: 0 0 0 auto;
  background: #fff;
}
.modal.fade .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(100%, 0) scale(1);
  transform: translate(100%, 0) scale(1);
}
.modal.fade.show .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  display: flex;
  align-items: stretch;
  -webkit-box-align: stretch;
  height: 100%;
}
/* .modal.fade.show .modal-dialog.modal-dialog-slideout .modal-body {
  overflow-y: auto;
  overflow-x: hidden;
} */
.modal-dialog-slideout .modal-content {
  border: 0;
}
.modal-dialog-slideout .modal-header,
.modal-dialog-slideout .modal-footer {
  height: 69px;
  display: block;
}
.modal-dialog-slideout .modal-header h5 {
  float: left;
}

.modal {
  width: 102% !important;
}
.modal-md {
  width: 50%;
}
@media only screen and (max-width: 991px) {
  .modal-md {
    width: 100%;
  }
  .showinDesktop {
    display: none;
  }
}
@media only screen and (min-width: 991px) {
  .showinMobile {
    display: none;
  }
}

.modal-header {
  background: rgb(0, 0, 0.2);
  padding-top: 10px;
  padding-bottom: 10px;
}

.modal-body {
  color: white;
  background: #151515;
}
.modal-heading {
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 700;
  color: #000011;
}

.mdal-list {
  font-size: 14px;
}
.modal-p {
  font-size: 14px;
}

.close,
.close:hover {
  font-size: 21px;
  line-height: 1;
  color: #ffffff;
  font-weight: 400;
  opacity: 1 !important;
  cursor: pointer;
}
.modal-nav {
  padding-left: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: rgb(0 0 0 / 32%);
}
.modal-dialog {
  max-width: 600px;
}
.mod-btn {
  position: absolute;
  margin-top: -12px;
  left: 32px;
}
.moduleAct {
  background: #fafafa;
  color: #151515;
}
</style>
<style>
#openModal .modal-content {
  border-bottom: 8px solid rgb(250, 195, 58);
}
</style>
