<template>
  <div class="wrapper">
    <nav class="sidebar side-menu" v-click-outside="hide">
      <div class="logo d-flex justify-content-center">
        <img
          :src="getImage('asuLogo.svg')"
          alt="asu logo"
          class="h-100"
          width="75px"
        />
      </div>

      <div class="nav-AU">
        <ul class="list-unstyled menu-elements font-12">
          <navigationList
            v-if="$store.state.role == 'student'"
            type="menu"
            :navList="navList"
            v-on:hide="hide"
          />
          <navigationList
            v-else-if="$store.state.role == 'pod operator'"
            type="menu"
            :navList="navListpod"
            v-on:hide="hide"
          />
          <navigationList
            v-else-if="$store.state.role == 'instructor'"
            type="menu"
            :navList="navListInstructor"
            v-on:hide="hide"
            class="my-auto"
          />
          <navigationList
            v-else-if="$store.state.role == 'admin'"
            type="menu"
            :navList="navListAdmin"
            v-on:hide="hide"
            class="my-auto"
          />
        </ul>
      </div>
      <a
        v-dataanchor
        tagEvent="collapse"
        tagAction="open/close"
        tagType="click"
        tagSection="sidebar"
        tagText="menu"
        id="side-btn"
        class="btn btn-primary btn-customized side-menu-button open-menu align-items-center justify-content-center p-2"
        :class="
          ($store.state.profile.first_name &&
            $store.state.waivers.length > 0) ||
          ($store.state.profile.role && $store.state.profile.role != 'student')
            ? 'd-flex'
            : 'd-none'
        "
        href="#"
        role="button"
      >
        <img :src="getIcon('bars-solid.svg')" alt="bars-solid" height="10px" />
        <span class="pl-2 font14 fontRoboto font-weight-bold text-white"
          >MENU</span
        >
      </a>
    </nav>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import navigationList from "./navList.vue";
import $ from "jquery";
import {
  dataLayerAnchorTag,
  dataLayerButtonTag,
  dataLayerSelectTag,
  dataLayerInputTag,
} from "data-layer-test-oo-gtm";

export default {
  name: "app",
  data() {
    return {
      navList: [
        {
          name: "Dashboard",
          link: "portal/dashboard",
          icon: "home-solid.svg",
          type: "route",
        },
      ],
      navListpod: [
        {
          name: "Dashboard",
          link: "portal",
          icon: "home-solid.svg",
          type: "route",
        },
        {
          name: "Pod Check-in",
          link: "pod-check-in",
          icon: "qr-code.svg",
          type: "modal",
        },
      ],
      navListInstructor: [
        {
          name: "Pod Schedule",
          link: "portal",
          icon: "clock-regular.svg",
          type: "route",
        },
        {
          name: "Dashboard",
          link: "portal",
          icon: "home-solid.svg",
          type: "route",
        },

        // {
        //   name: "Course Settings",
        //   link: "course-settings",
        //   icon: "home-solid.svg",
        //   type: "modal",
        // },
      ],
      navListAdmin: [
        {
          name: "Scheduler",
          link: "portal",
          icon: "calendar-alt-solid.svg",
          type: "route",
        },
        {
          name: "Dashboard",
          link: "portal",
          icon: "home-solid.svg",
          type: "route",
        },
        {
          name: "Pod Schedule",
          link: "portal",
          icon: "clock-regular.svg",
          type: "route",
        },
        // {
        //   name: "Course Settings",
        //   link: "course-settings",
        //   icon: "cog-solid.svg",
        //   type: "modal",
        // },
        {
          name: "Pod Check-in",
          link: "pod-check-in",
          icon: "qr-code.svg",
          type: "modal",
        },
      ],
      // navListAdmin: [
      //   {
      //     name: "Dashboard",
      //     link: "",
      //     icon: "home",
      //     type: "route",
      //   },
      //   {
      //     name: "Canvas",
      //     link: "https://canvas.asu.edu/courses/49201/pages/start-here",
      //     icon: "home",
      //     type: "url",
      //   },
      //   {
      //     name: "ULIS",
      //     link: "https://ulis.my.salesforce.com/",
      //     icon: "home",
      //     type: "url",
      //   },
      //   {
      //     name: "Announcements",
      //     link: "announcementsmodal",
      //     icon: "bullhorn-solid",
      //     type: "modal",
      //   },
      //   {
      //     name: "Profile",
      //     link: "userprofile",
      //     icon: "address-card-solid",
      //     type: "modal",
      //   },
      //   {
      //     name: "Support",
      //     link: "support",
      //     icon: "comments-solid",
      //     type: "route",
      //   },
      // ],
      // navListInstructor: [
      //   {
      //     name: "Dashboard",
      //     link: "",
      //     icon: "home",
      //     type: "route",
      //   },
      //   {
      //     name: "Canvas",
      //     link: "https://canvas.asu.edu/courses/49201/pages/start-here",
      //     icon: "home",
      //     type: "url",
      //   },
      //   {
      //     name: "Announcements",
      //     link: "announcementsmodal",
      //     icon: "bullhorn-solid",
      //     type: "modal",
      //   },
      //   {
      //     name: "Profile",
      //     link: "userprofile",
      //     icon: "address-card-solid",
      //     type: "modal",
      //   },
      //   {
      //     name: "Support",
      //     link: "support",
      //     icon: "comments-solid",
      //     type: "route",
      //   },
      // ],
      // navListStudent: [
      //   {
      //     name: "Dashboard",
      //     link: "",
      //     icon: "home",
      //     type: "route",
      //   },
      //   // {
      //   //   name: "Courses",
      //   //   link: "courses",
      //   //   icon: "columns-solid",
      //   //   type: "scroll"
      //   // },
      //   {
      //     name: "Announcements",
      //     link: "announcementsmodal",
      //     icon: "bullhorn-solid",
      //     type: "modal",
      //   },
      //   {
      //     name: "Profile",
      //     link: "userprofile",
      //     icon: "address-card-solid",
      //     type: "modal",
      //   },
      //   {
      //     name: "Support",
      //     link: "support",
      //     icon: "comments-solid",
      //     type: "route",
      //   },
      //   {
      //     name: "Program Progress View",
      //     link: "programprogressview",
      //     icon: "file-alt-solid",
      //     type: "route",
      //   },
      // ],
    };
  },
  methods: {
    checkLogin() {
      if (!this.$store.state.profile.first_name) {
        return "d-none";
      }
    },
    getImage(img) {
      return require("@/assets/images/" + img);
    },
    getIcon(img) {
      return require("@/assets/icons/" + img);
    },
    hide() {
      $(".sidebar").removeClass("active");
    },
  },
  components: {
    navigationList,
  },
  mounted() {
    $(".dismiss, .overlay").on("click", function () {
      $(".sidebar").removeClass("active");
      $(".overlay").removeClass("active");
    });

    $(".open-menu").on("click", function (e) {
      e.preventDefault();
      $(".sidebar").toggleClass("active");
      $(".overlay").toggleClass("active");
      // close opened sub-menus
      // $(".collapse.show").toggleClass("show");
      $("a[aria-expanded=true]").attr("aria-expanded", "false");
    });

    $("a.scroll-link").on("click", function (e) {
      e.preventDefault();
      scroll_to($(this), 0);
    });

    function scroll_to(clicked_link, nav_height) {
      var element_class = clicked_link.attr("href").replace("#", ".");
      var scroll_to = 0;
      if (element_class != ".top-content") {
        element_class += "-container";
        scroll_to = $(element_class).offset().top - nav_height;
      }
      if ($(window).scrollTop() != scroll_to) {
        $("html, body").stop().animate({ scrollTop: scroll_to }, 1000);
      }
    }

    $(".to-top a").on("click", function (e) {
      e.preventDefault();
      if ($(window).scrollTop() != 0) {
        $("html, body").stop().animate({ scrollTop: 0 }, 1000);
      }
    });

    $("a.btn-customized-dark").on("click", function (e) {
      e.preventDefault();
      $(".sidebar").removeClass("light");
    });

    $("a.btn-customized-light").on("click", function (e) {
      e.preventDefault();
      $(".sidebar").addClass("light");
    });
  },
  directives: {
    ClickOutside,
    dataanchor: dataLayerAnchorTag,
    databutton: dataLayerButtonTag,
    "data-select": dataLayerSelectTag,
    "data-input": dataLayerInputTag,
  },
};
</script>
<style>
.bottom {
  bottom: 0%;
}
.nav-AU {
  display: flex;
  min-height: 80vh;
  flex-direction: column;
  justify-content: center;
}
.howToUsePortal {
  max-width: 150px;
  bottom: 24px;
}
.side-menu {
  background-color: #000000 !important;
  border-right: 1px solid #ffc627;
}
.side-menu-button {
  background-color: #000000 !important;
}
#side-btn:hover {
  background-color: #000000 !important;
  outline: none !important;
  box-shadow: none !important;
}
#side-btn:focus {
  background-color: #000000 !important;
  outline: none !important;
  box-shadow: none !important;
}
</style>
